import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

export default function CustomerBilling() {
  const billingData = useSelector((state) => state.customer.billingData);
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "50vh",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
    billingDate: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
  };

  return (
    <TableContainer {...styles.tableContainer}>
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width={"30%"}>DATE</Th>
            <Th width={"30%"}>METHOD</Th>
            <Th width={"30%"}>AMOUNT</Th>
            <Th width={"10%"}>RECEIPT</Th>
          </Tr>
        </Thead>
        <Tbody>
          {billingData.map((customer, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Text {...styles.billingDate}>{customer?.date}</Text>
                </Td>
                <Td>{customer?.method}</Td>
                <Td>{customer?.amount}</Td>
                <Td>
                  <Button
                    {...styles.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("Development under progress 😉");
                    }}>
                    Download
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
