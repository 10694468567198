import {
  Box,
  Button,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";

export default function CustomerDevices() {
  const location = useLocation();
  const dispatch = useDispatch();
  const { customer } = location.state || {};

  const navigate = useNavigate();
  const devices = useSelector((state) =>
    state?.controllers?.devices?.filter(
      (device) => device?.assigned_to_customer?.organization == customer?.name,
    ),
  );
  const firmware = useSelector((state) => state?.firmware?.firmwareData);

  console.log("devices", firmware);

  const styles = {
    tableContainer: {
      pt: 2,
      overflowY: "auto",
      h: "50vh",
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
  };

  const handleRowClick = (deviceData) => {
    const selectedDevice = devices?.filter(
      (device) => device.serial_number == deviceData.serial_number,
    );
    dispatch(setActiveDevice(selectedDevice[0]));
    dispatch(getFirmwareListAPI());
    navigate("/units/device", { state: { deviceData } });
  };

  return (
    <TableContainer {...styles.tableContainer}>
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width="40%">NAME</Th>
            <Th width="12%">TYPE</Th>
            <Th width="12%">PRESSURE THRESHOLD</Th>
            <Th width="12%">FIRMWARE VERSION</Th>
            <Th width="12%">MANUFACTURER</Th>
            <Th width="12%">ACTIONS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {devices?.map((device, index) => {
            return (
              <Tr
                key={index}
                onClick={() => handleRowClick(device)}
                style={{ cursor: "pointer" }}>
                <Td>
                  <Flex>
                    <Center>
                      <Box {...styles.indecatorBox}>
                        <GoDotFill
                          color={device?.status == "online" ? "green" : "red"}
                        />
                      </Box>
                      <Box>
                        <Text {...styles.deviceName}>{device?.name}</Text>
                        <Text {...styles.deviceModel}>{device?.model}</Text>
                      </Box>
                    </Center>
                  </Flex>
                </Td>
                <Td>
                  <Text>{device?.device_type?.name}</Text>
                </Td>
                <Td>
                  {device?.data_points?.map((val) => {
                    return (
                      <>
                        {val?.minimum} {val?.unit?.unit} to {val?.maximum}{" "}
                        {val?.unit?.unit}{" "}
                      </>
                    );
                  })}
                </Td>
                <Td>
                  {device?.firmware_version}
                  {/* <Text {...styles.latestVersionText}>
                    Latest:{device?.firmwareLatest}
                  </Text> */}
                </Td>
                <Td>{device?.manufacturer.organization}</Td>
                <Td>
                  <Button
                    {...styles.actionButton}
                    onClick={() => {
                      // e.stopPropagation();
                      // alert("Development under progress 😉");
                      handleRowClick(device);
                    }}>
                    Details
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
