import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import { FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import ModelFooterButtons from "./modelFooterButtons";

const EditDeviceAttributesModal = ({ isOpen, onClose, onSave, device }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const units = useSelector((state) => state?.controllers?.units);
  // const [isTypeChanged, setIsTypeChanged] = useState();
  // useEffect(() => {
  //   setIsTypeChanged(device?.device_type?.id);
  // }, [device]);
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    device_name: Yup.string()
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Device name can only contain Letters, Numbers & Spaces",
      )
      .required("Device name is required"),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          // .required("Monitoring Value is required")
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            nameRegex,
            "Monitoring Value can only contain Letters, Numbers & Spaces",
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number().required("Maximum value is required"),
        unit_id: Yup.string().required("Unit is required"),
      }),
    ),
  });

  const handleSubmit = (values) => {
    console.log("handleSubmit values", values);
    const data = {
      device_id: device?.id,
      name: device?.name == values?.device_name ? "" : values?.device_name,
      // model_id: device?.device_model?.id,
      // type_id: parseInt(values.device_type),
      //   name: values.device_model_name ? "" : values.device_model_name,
      // data_points: values.device_attributes.map((attribute) => ({
      //   ...attribute,
      //   data_point_id: parseInt(attribute?.data_point_id),
      // })),
      data_points: values.device_attributes,
    };

    const payload = Object.fromEntries(
      Object.entries(data).filter(([, value]) => value !== ""),
    );
    onSave(payload);
  };

  const styles = {
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  console.log("Modify device", device);

  return (
    <>
      {/* <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Model" /> */}
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modify Device</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                // device_name: device?.name || "",
                // device_type: device?.device_type?.id || "",
                // device_model_name: device?.name || "",
                device_attributes:
                  (device?.data_points &&
                    device.data_points.map((dataPoint) => ({
                      name: dataPoint?.unit?.type || "",
                      unit_id: dataPoint?.unit?.id || "",
                      min: dataPoint?.minimum || "",
                      max: dataPoint?.maximum || "",
                      data_point_id: dataPoint?.id || "",
                    }))) ||
                  [],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}>
              {({ values, errors, handleChange, touched, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {/* <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_name && touched.device_name}>
                    <FormLabel>Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      defaultValue={device?.name}
                      placeholder="Device name"
                      name="device_name"
                    />
                    <FormErrorMessage>{errors.device_name}</FormErrorMessage>
                  </FormControl> */}
                  {/* <FormControl
                    isInvalid={errors?.device_type && touched?.device_type}>
                    <FormLabel>Product</FormLabel>
                    <Select
                      isDisabled={true}
                      {...styles.input}
                      id="device_type"
                      ref={initialRef}
                      name="device_type"
                      placeholder="Select Type"
                      value={values.device_type}
                      onChange={(e) => {
                        handleChange(e);
                        // setIsTypeChanged(e.target.value);
                      }}>
                      {types?.map((val) => (
                        <option key={uuidv4()} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                  </FormControl> */}

                  {/* <FormControl
                    isInvalid={errors?.device_name && touched?.device_name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      // isDisabled={device?.device_type?.id === isTypeChanged}
                      {...styles.input}
                      defaultValue={device?.name}
                      placeholder="Device name"
                      name="device_name"
                      // value={values.device_model_name}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>{errors.device_name}</FormErrorMessage>
                  </FormControl> */}
                  <Text>Device Data Attributes</Text>
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 12,
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}>
                    This encompasses both the collection of data points through
                    the device and the ability for the customer to adjust alert
                    thresholds.
                  </Text>

                  <FieldArray
                    name="device_attributes"
                    style={{
                      marginBottom: 22,
                      padding: 4,
                      marginTop: 4,
                      border: "1px dotted lightgrey",
                      borderRadius: 8,
                    }}>
                    {(arrayHelpers) => (
                      <>
                        {values.device_attributes.map((_, index) => (
                          <Box
                            key={index}
                            style={{
                              marginBottom: 22,
                              padding: 4,
                              marginTop: 6,
                              border: "1px dotted lightgrey",
                              borderRadius: 8,
                            }}>
                            <Flex justifyContent={"end"}>
                              <IconButton
                                size={"xs"}
                                fontSize={"10px"}
                                color={"red"}
                                icon={<CloseIcon />}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Flex>

                            <FormControl
                              marginTop={-4}
                              isInvalid={
                                errors.device_attributes &&
                                errors.device_attributes[index] &&
                                touched.device_attributes &&
                                touched.device_attributes[index]
                              }>
                              <FormLabel fontSize={"13px"}>
                                {`Monitoring Value ${index + 1}`}
                              </FormLabel>
                              <Input
                                {...styles.input}
                                // disabled
                                placeholder={`Monitoring Value`}
                                name={`device_attributes.${index}.name`}
                                // value={values.device_attributes[index].name}
                                defaultValue={
                                  device?.data_points[index]?.unit?.type
                                }
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  errors.device_attributes[index].name}
                              </FormErrorMessage>
                              <FormControl
                                pt={2}
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }>
                                <FormLabel fontSize={"13px"}>Unit</FormLabel>
                                <Select
                                  {...styles.input}
                                  // disabled
                                  placeholder="Select Unit"
                                  name={`device_attributes.${index}.unit_id`}
                                  id={`device_attributes.${index}.unit_id`}
                                  value={
                                    values.device_attributes[index].unit_id
                                  }
                                  // defaultValue={
                                  //   device?.model_data_points[index]?.data_point
                                  //     ?.id
                                  // }
                                  onChange={handleChange}>
                                  {units?.map((val) => (
                                    <option key={uuidv4()} value={val.id}>
                                      {val.name}
                                    </option>
                                  ))}
                                </Select>
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].unit_id}
                                </FormErrorMessage>
                              </FormControl>
                            </FormControl>
                            <Center pt={2}>
                              <FormControl
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }>
                                <FormLabel fontSize={"13px"}>Minimum</FormLabel>
                                <Input
                                  {...styles.input}
                                  // disabled
                                  type="number"
                                  placeholder="Minimum"
                                  name={`device_attributes.${index}.min`}
                                  // value={values.device_attributes[index].min}
                                  defaultValue={
                                    device?.data_points[index]?.minimum
                                  }
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].min}
                                </FormErrorMessage>
                              </FormControl>
                              <Box px={2}></Box>
                              <FormControl
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }>
                                <FormLabel fontSize={"13px"}>Maximum</FormLabel>
                                <Input
                                  {...styles.input}
                                  // disabled
                                  type="number"
                                  placeholder="Maximum"
                                  name={`device_attributes.${index}.max`}
                                  // value={values.device_attributes[index].max}
                                  defaultValue={
                                    device?.data_points[index]?.maximum
                                  }
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].max}
                                </FormErrorMessage>
                              </FormControl>
                            </Center>
                          </Box>
                        ))}
                        <Button
                          // isDisabled={true}
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                              min: "",
                              max: "",
                              unit_id: "",
                            })
                          }
                          size={"xs"}
                          colorScheme="teal">
                          Add Attribute
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditDeviceAttributesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
};

export default EditDeviceAttributesModal;
