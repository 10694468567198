import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import {
  setNewPasswordAPI,
  verifyInvitationIdAPI,
} from "../redux/helpers/authAPI";
import { useDispatch } from "react-redux";
import SuccessMessageModal from "../components/successMessageModal";
import Loader from "../components/loader";

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const [invitation_id, setInvitationId] = useState("");
  const [userVerified, setUserVerified] = useState(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("invitation_id");
    if (id) {
      setInvitationId(id);
      const payload = {
        invitaion_id: id,
      };
      let verifyInvitationIdRes = dispatch(verifyInvitationIdAPI(payload));

      verifyInvitationIdRes.then((data) => {
        if (data.payload?.user_exists) {
          setUserVerified(false);
        } else {
          toast({
            title: "Error",
            description: data.payload.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    }
  }, [location.search]);

  const [showPassword, setShowPassword] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient: "linear(to-br, #7928CA, #0762ef)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
      textAlign: "center",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    input: {
      size: { base: "sm", md: "md" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };

  const resetValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  return (
    <>
      <Loader loading={loading} />
      <Flex {...styles.background}>
        <Flex {...styles.topContainer}>
          <Stack {...styles.stackItems}>
            <Heading {...styles.header}>Set New Password</Heading>

            <Box {...styles.formContainer}>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  rememberMe: false,
                }}
                validationSchema={resetValidationSchema}
                onSubmit={(values) => {
                  setLoading(true);
                  try {
                    const payload = {
                      new_password: values?.password,
                      invitaion_id: invitation_id,
                    };
                    let setNewPasswordRes = dispatch(
                      setNewPasswordAPI(payload),
                    );
                    setNewPasswordRes.then((data) => {
                      if (data.payload?.success) {
                        navigate("/login");
                        setisSuccessModalOpen(true);
                        setLoading(false);
                      } else {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description: data.payload.message,
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <FormControl
                        isInvalid={errors?.password && touched?.password}>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            isDisabled={userVerified}
                          />

                          <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>{errors?.password}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={
                          errors?.confirmPassword && touched?.confirmPassword
                        }>
                        <FormLabel htmlFor="confirmPassword">
                          Confirm Password
                        </FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm your password"
                            isDisabled={userVerified}
                          />

                          <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>
                          {errors?.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>

                      <Button {...styles.loginButton} type="submit">
                        Reset
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </Box>
          </Stack>

          <Box {...styles.footerText}>
            Already have an account?{" "}
            <Link {...styles.linkColor} onClick={() => navigate("/login")}>
              Login
            </Link>
          </Box>
        </Flex>
      </Flex>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Password has been resetted"}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
}
