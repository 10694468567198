import { useToast } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addModelAPI,
  addTypeAPI,
  getModelsAPI,
  getTypesAPI,
} from "../redux/helpers/controllerAPI";
import AddDeviceModal from "./addDeviceModal";
import AddDeviceModelModal from "./addDeviceModelModal";
import AddDeviceTypeModal from "./addDeviceTypeModal";
import SuccessMessageModal from "./successMessageModal";

const ControllerButtons = ({
  selectedDevice,
  forModify,
  modalIsOpen,
  // reloadDataForAccount,
}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  // const devices = useSelector((state) => state.controllers.devices);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setIsModalOpen(modalIsOpen);
    setIsOpenedForModify(forModify);
    setSingleDevice(selectedDevice);
  }, [modalIsOpen, forModify, selectedDevice]);

  const [isModalOpen, setIsModalOpen] = useState(modalIsOpen);
  const [isOpenedForModify, setIsOpenedForModify] = useState(forModify);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSingleDevice("");
    setIsOpenedForModify(false);
    // reloadDataForAccount();
  };

  const [singleDevice, setSingleDevice] = useState(selectedDevice);

  // Manage Device Type
  const [isDevTypeModalOpen, setIsDevTypeModalOpen] = useState(false);

  const handleDevTypeCloseModal = () => {
    setIsDevTypeModalOpen(false);
  };

  const onSaveDevType = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevType payload", payload);

    let addTypeRes = dispatch(addTypeAPI(payload));
    addTypeRes.then((data) => {
      if (data.payload.success) {
        handleDevTypeCloseModal();
        setSuccessMessage("Device Type Added successfully");
        dispatch(getTypesAPI());
        setisSuccessModalOpen(true);
        // reloadDataForAccount();
      } else {
        toast({
          title: "Error",
          description: data?.payload?.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    });
  };

  // End - Manage Device Type

  // Manage Models
  // DevModel == Device Model
  const [isDevModelOpen, setIsDevModelOpen] = useState(false);

  const handleDevModelCloseModal = () => {
    setIsDevModelOpen(false);
  };

  const onSaveDevModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevModel payload", payload);

    let addModelres = dispatch(addModelAPI(payload));
    addModelres.then((data) => {
      console.log("addModelres", data);
      if (data?.payload?.success) {
        setSuccessMessage("Model Added successfully");
        dispatch(getModelsAPI());
        setisSuccessModalOpen(true);
        handleDevModelCloseModal();
        // reloadDataForAccount();
      } else {
        toast({
          title: "Error",
          description: data?.payload?.message || data?.payload?.error,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    });
  };

  const handleDevTypeOpen = () => {
    setIsDevTypeModalOpen(true);
  };

  const handleDevModelOpen = () => {
    setIsDevModelOpen(true);
  };
  // End Manage Model

  // Reload Data for account

  /**
   * 1. reload devices types
   * 2. reload device models
   * 3. reload devices
   */

  // const reloadDataForAccount = () => {
  //   dispatch(getTypesAPI());
  //   dispatch(getDeviceAPI());
  //   dispatch(getModelsAPI());
  // };

  // End Reload Data for account

  return (
    <>
      {/* <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Actions
          </MenuButton>
          <MenuList>
            <MenuItem onClick={setIsModalOpen}>Add Device</MenuItem>
            <MenuItem onClick={setIsDevTypeModalOpen}>Add Device Type</MenuItem>
            <MenuItem>Mark as Draft</MenuItem>
            <MenuItem>Delete</MenuItem>
            <MenuItem>Attend a Workshop</MenuItem>
          </MenuList>
        </Menu> */}

      <AddDeviceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        setIsModalOpen={setIsModalOpen}
        device={singleDevice}
        handleDevTypeOpen={handleDevTypeOpen}
        handleDevModelOpen={handleDevModelOpen}
        isModify={isOpenedForModify}
        // reloadDataForAccount={reloadDataForAccount}
      />

      <AddDeviceModelModal
        isOpen={isDevModelOpen}
        onClose={handleDevModelCloseModal}
        setIsModalOpen={setIsDevModelOpen}
        onSave={onSaveDevModel}
      />

      <AddDeviceTypeModal
        isOpen={isDevTypeModalOpen}
        onClose={handleDevTypeCloseModal}
        setIsModalOpen={setIsDevTypeModalOpen}
        onSave={onSaveDevType}
      />
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
            // reloadDataForAccount();
          }}
        />
      )}
    </>
  );
};

ControllerButtons.propTypes = {
  forModify: PropTypes.bool.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  selectedDevice: PropTypes.object.isRequired,
  // reloadDataForAccount: PropTypes.func,
};

export default ControllerButtons;
