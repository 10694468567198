import { Box, Flex, Heading, IconButton, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../App.css";
import { BellIcon } from "@chakra-ui/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";

function Header() {
  const user = useSelector((state) => state?.auth?.userProfile);
  const styles = {
    title: { fontSize: { base: "larger", md: "x-large" }, fontWeight: "bold" },
    header: {
      paddingLeft: "30px",
      paddingRight: "30px",
      height: "10vh",
      justifyContent: "space-between",
      alignItems: "center",
    },
    notificationButton: {
      colorScheme: "facebook",
      fontSize: { base: "20px", md: "22px" },
      isRound: true,
    },
    userName: {
      paddingRight: 6,
      flexDirection: "row",
      textAlign: "center",
    },
    profileContainer: {
      flexDirection: "column",
      paddingRight: 8,
    },
  };

  const [currentTime, setCurrentTime] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss"),
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format("DD MMMM YYYY - HH:mm:ss"));
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Flex {...styles.header}>
      <Box>
        <Text {...styles.title}>{user?.organization} </Text>
      </Box>
      <Flex {...styles.userName}>
        <Flex {...styles.profileContainer}>
          <Heading size={"sm"}>
            {user?.first_name
              ? user?.first_name + " " + user?.last_name
              : "User"}
          </Heading>
          <div style={{ display: "flex", alignItems: "end" }}>
            <Text fontSize={14} fontWeight={"500"}>
              {" "}
              {user?.role?.name}
            </Text>
            <GoDotFill />
            <Text fontSize={14}> {currentTime}</Text>
          </div>
        </Flex>

        <IconButton icon={<BellIcon />} {...styles.notificationButton} />
      </Flex>
    </Flex>
  );
}

export default Header;
