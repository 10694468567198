import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { SearchIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";

const ControllerSearchFilters = ({
  searchParams,
  handleInputChange,
  handleSearch,
  handleReset,
}) => {
  const styles = {
    topGrid: {
      templateColumns: "repeat(5, 1fr)",
      gap: { base: 1, lg: 6 },
      px: { base: 0, lg: 4 },
      pb: { base: 2, md: 6 },
      alignItems: "end",
    },
    colSpan1: { base: 5, sm: 5, md: 5, lg: 1 },
    colSpan2: { base: 5, sm: 5, md: 1, lg: 1 },
    colSpan3: { base: 6, sm: 6, md: 1, lg: 1 },
    colSpan4: { base: 6, sm: 6, md: 2, lg: 1 },
    headerText: {
      color: "#4A5568",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      letterSpacing: "0.05rem",
      fontWeight: 700,
      py: "0.25rem",
    },
    searchBar: {
      borderRadius: 6,
    },
    selector: {
      size: { base: "xs", md: "sm" },
      borderRadius: 6,
    },
    searchButton: {
      colorScheme: "facebook",
      width: "66%",
      size: { base: "xs", md: "sm" },
    },
    resetButton: {
      colorScheme: "facebook",
      variant: "outline",
      width: "30%",
      size: { base: "xs", md: "sm" },
    },
  };

  const devices = useSelector((state) => state?.controllers?.devices);

  // const uniqueDeviceTypes = new Set(
  //   devices?.map((device) => device?.device_type?.name),
  // );

  // const uniqueModels = new Set(
  //   devices?.map((device) => [
  //     { id: device?.device_model?.id, name: device?.device_model?.name },
  //   ]),
  // );

  const [uniqueModels, setUniqueModels] = useState([]);
  const [uniqueDeviceTypes, setUniqueDeviceTypes] = useState([]);

  useEffect(() => {
    // Extract unique device models
    const uniqueModelsSet = new Set();
    const uniqueModelsArray = [];

    const uniqueTypeSet = new Set();
    const uniqueTypeArray = [];

    devices?.forEach((device) => {
      const { id, name } = device.device_model;
      const modelKey = `${id}`;

      if (!uniqueModelsSet.has(modelKey)) {
        uniqueModelsSet.add(modelKey);
        uniqueModelsArray.push({ id, name });
      }
    });

    devices?.forEach((device) => {
      const { id, name } = device.device_type;
      const typeKey = `${id}`;

      if (!uniqueTypeSet.has(typeKey)) {
        uniqueTypeSet.add(typeKey);
        uniqueTypeArray.push({ id, name });
      }
    });

    setUniqueModels(uniqueModelsArray);
    setUniqueDeviceTypes(uniqueTypeArray);
  }, []);

  console.log("eefer", uniqueDeviceTypes, uniqueModels);

  useEffect(() => {
    handleSearch();
  }, [searchParams]);

  // const types = useSelector((state) => state?.controllers?.types);
  // const models = useSelector((state) => state?.controllers?.models);

  // const [selectedType, setSelectedType] = useState("");

  const onHandleTypeSelected = (value) => {
    // setSelectedType(value);

    handleInputChange("type", value); // value handleInputChange("type", e.target.value)
  };

  return (
    <Grid {...styles.topGrid}>
      <GridItem colSpan={{ base: 5, sm: 5, md: 5, lg: 1 }}>
        <Box>
          <Text {...styles.headerText}>What are you looking for?</Text>
          <InputGroup size={"sm"}>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              {...styles.searchBar}
              type="text"
              placeholder="Search by Name"
              value={searchParams.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
          </InputGroup>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 5, sm: 5, md: 1, lg: 1 }}>
        <Box>
          <Text {...styles.headerText}>Status</Text>{" "}
          <Select
            {...styles.selector}
            placeholder="All"
            value={searchParams.status}
            onChange={(e) => handleInputChange("status", e.target.value)}>
            <option value="online">Online</option>
            <option value="offline">Offline</option>
          </Select>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 6, sm: 6, md: 1, lg: 1 }}>
        <Box>
          <Text {...styles.headerText}>Type</Text>
          <Select
            placeholder="All"
            {...styles.selector}
            value={searchParams.type}
            // onChange={(e) => handleInputChange("type", e.target.value)}
            onChange={(e) => onHandleTypeSelected(e.target.value)}>
            {uniqueDeviceTypes?.map((val, index) => {
              return (
                <option key={index} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </Select>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 6, sm: 6, md: 1, lg: 1 }}>
        <Box>
          <Text {...styles.headerText}>Model</Text>
          <Select
            placeholder="All"
            {...styles.selector}
            value={searchParams.model}
            onChange={(e) => handleInputChange("model", e.target.value)}>
            {uniqueModels.map((val, index) => {
              return (
                <option key={index} value={val.id}>
                  {val.name}
                </option>
              );
            })}
          </Select>
        </Box>
      </GridItem>
      <GridItem colSpan={{ base: 6, sm: 6, md: 2, lg: 1 }}>
        <Flex>
          <Button {...styles.searchButton} onClick={handleSearch}>
            Search
          </Button>
          <Spacer />
          <Button {...styles.resetButton} onClick={handleReset}>
            Reset
          </Button>
        </Flex>
      </GridItem>
    </Grid>
  );
};

ControllerSearchFilters.propTypes = {
  searchParams: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
};

export default ControllerSearchFilters;
