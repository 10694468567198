import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/breadcrumbs";
import ControllerSearchFilters from "../components/controllersSearchFilters";
import ControllersTable from "../components/controllersTable";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
import { setActiveDevice } from "../redux/reducers/controllersReducer";

import DashboardUserAssetCountSection from "../components/dashboardUserAssetCountSection";
import DeleteDeviceModal from "../components/deleteDeviceModal";
import EditDeviceModal from "../components/editDeviceModal";
import Loader from "../components/loader";
import SuccessMessageModal from "../components/successMessageModal";
import {
  deleteDeviceAPI,
  getModelsAPI,
  getTypesAPI,
} from "../redux/helpers/controllerAPI";
import { getOrganizationsAPI } from "../redux/helpers/customerAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";

function Controllers() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const devices = useSelector((state) => state.controllers.devices);
  const [successMessage, setSuccessMessage] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const screen = "controllers";
  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
      mt: 8,
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
    type: "",
    status: "",
    model: "",
  });

  const [filteredDevices, setFilteredDevices] = useState();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [filteredRes, setFilteredRes] = useState();

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    dispatch(getOrganizationsAPI());
  };

  useEffect(() => {
    loadDevicesForAccount();
    dispatch(getDashboardDataAPI());
  }, []);

  const handleSearch = () => {
    const newFilteredDevices = filteredRes?.filter((device) => {
      const nameMatch =
        !searchParams?.name ||
        device?.name
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase()) ||
        device?.serial_number
          ?.toLowerCase()
          .includes(searchParams?.name?.toLowerCase());
      const typeMatch =
        !searchParams?.type ||
        device?.device_type?.id == searchParams?.type?.toLowerCase();
      const statusMatch =
        !searchParams?.status ||
        device?.status?.toLowerCase() === searchParams?.status?.toLowerCase();
      const modelMatch =
        !searchParams?.model ||
        device?.device_model?.id == searchParams?.model?.toLowerCase();

      return nameMatch && typeMatch && statusMatch && modelMatch;
    });

    setFilteredDevices(newFilteredDevices);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const loadDevicesForAccount = () => {
    let getFilteredres = dispatch(getDeviceAPI());
    getFilteredres.then((response) => {
      setFilteredDevices(
        response?.payload?.data?.filter((item) => item.sold === false),
      );
      setFilteredRes(
        response?.payload?.data?.filter((item) => item.sold === false),
      );
    });

    setLoading(false);

    // Data for Summary

    // Total Product Types

    // Get unique device types
    const uniqueDeviceTypes = new Set(
      devices?.map((device) => device?.device_type.id),
    );

    // Count unique device types
    const uniqueDeviceTypesCount = uniqueDeviceTypes.size;

    console.log(`Unique device types count: ${uniqueDeviceTypesCount}`);

    // setProductTypeCnt(uniqueDeviceTypesCount);

    // Total Count of Unique Models

    // Get unique models
    const uniqueModels = new Set(
      devices?.map((device) => device?.device_model.id),
    );

    // Count unique device types
    const uniqueModelsCount = uniqueModels?.size;

    console.log(`Unique model count: ${uniqueModelsCount}`);

    // setModelsCnt(uniqueModelsCount);

    // Total Count of Customers

    // Get unique models
    // const uniqueCustomers = new Set(
    //   devices?.map((device) => device?.assigned_to_seller_organization?.id),
    // );

    // Count unique device types
    const uniqueCustomersCount = uniqueModels?.size;

    console.log(`Unique customer count: ${uniqueCustomersCount}`);

    // setCustomersCnt(uniqueCustomersCount);

    // End: Data for Summary
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
      type: "",
      status: "",
      model: "",
    });

    // setFilteredDevices(devices);
    loadDevicesForAccount();
  };

  const [singleDevice, setSingleDevice] = useState("");

  const handleModalOpen = (device) => {
    setIsEditModalOpen(true);
    device = { ...device, update: true };
    setSingleDevice(device);
  };

  const handleDeleteModalOpen = (device) => {
    setIsDeleteModalOpen(true);
    setSingleDevice(device);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteDeviceAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage("Product Deleted successfully");
          loadDevicesForAccount();
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Unit",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const handleRowClick = (selectedDevice) => {
    const completeDeviceInfo = devices.filter(
      (device) => device.id == selectedDevice?.id,
    );
    dispatch(setActiveDevice(completeDeviceInfo[0]));
    navigate("/units/device", { state: { completeDeviceInfo } });
  };

  // End - Manage Device Type

  // Manage Models
  // DevModel == Device Model
  // End Manage Model

  // Reload Data for account

  /**
   * 1. reload devices types
   * 2. reload device models
   * 3. reload devices
   */

  useEffect(() => {
    setLoading(true);
    Promise.all([reloadDataForAccount()])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const reloadDataForAccount = () => {
    setLoading(true);
    dispatch(getTypesAPI());
    dispatch(getOrganizationsAPI());
    dispatch(getModelsAPI());

    handleReset();
    // let getDevicesRes = dispatch(getDeviceAPI());
    // getDevicesRes.then(() => {
    //   handleReset();
    //   setLoading(false);
    // });
  };

  const devices_count = useSelector(
    (state) => state?.summary?.total_devices_count,
  );
  const active_devices_count = useSelector(
    (state) => state?.summary?.active_devices_count,
  );
  const sold_devices_count = useSelector(
    (state) => state?.summary?.total_devices_sold,
  );

  // Table data for Count of Devices Per Model

  // const DeviceTypeTable = () => {
  //   // Count devices per device_type
  //   const deviceTypeCounts = devices?.reduce((acc, device) => {
  //     const typeName = device.device_type.name;
  //     if (!acc[typeName]) {
  //       acc[typeName] = 0;
  //     }
  //     acc[typeName]++;
  //     return acc;
  //   }, {});

  //   // Convert the counts to an array for easy rendering
  //   const deviceTypeCountsArray = deviceTypeCounts
  //     ? Object.entries(deviceTypeCounts)?.map(([name, count]) => ({
  //         name,
  //         count,
  //       }))
  //     : [];

  //   return (
  //     <Box p={4}>
  //       <Table variant="simple" colorScheme="cyan" size={"sm"}>
  //         <Thead>
  //           <Tr>
  //             <Th>Device Type</Th>
  //             <Th isNumeric>Quantity</Th>
  //           </Tr>
  //         </Thead>
  //         <Tbody>
  //           {deviceTypeCountsArray?.map((deviceType, index) => (
  //             <Tr key={index}>
  //               <Td>{deviceType?.name}</Td>
  //               <Td isNumeric>{deviceType?.count || 0}</Td>
  //             </Tr>
  //           ))}
  //         </Tbody>
  //       </Table>
  //     </Box>
  //   );
  // };

  // const DeviceModelTable = () => {
  //   // Count devices per device_model
  //   const deviceTypeCounts = devices?.reduce((acc, device) => {
  //     const typeName = device.device_model.name;
  //     if (!acc[typeName]) {
  //       acc[typeName] = 0;
  //     }
  //     acc[typeName]++;
  //     return acc;
  //   }, {});

  //   // Convert the counts to an array for easy rendering
  //   const deviceTypeCountsArray = deviceTypeCounts
  //     ? Object.entries(deviceTypeCounts)?.map(([name, count]) => ({
  //         name,
  //         count,
  //       }))
  //     : [];

  //   return (
  //     <Box p={4}>
  //       <Table variant="simple" colorScheme="cyan" size={"sm"}>
  //         <Thead>
  //           <Tr>
  //             <Th>Model</Th>
  //             <Th isNumeric>Quantity</Th>
  //           </Tr>
  //         </Thead>
  //         <Tbody>
  //           {deviceTypeCountsArray?.map((deviceModel, index) => (
  //             <Tr key={index}>
  //               <Td>{deviceModel.name}</Td>
  //               <Td isNumeric>{deviceModel?.count}</Td>
  //             </Tr>
  //           ))}
  //         </Tbody>
  //       </Table>
  //     </Box>
  //   );
  // };
  // End table

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Flex justifyContent="space-between">
          <Box>
            <Breadcrumbs />
          </Box>
        </Flex>
        <Spacer />
        {/* <Box style={{ marginInline: 8 }}>
          <Button
            {...styles.button}
            rightIcon={<AddIcon />}
            onClick={() => navigate("/add-product")}>
            Add Product
          </Button>
        </Box> */}
      </Flex>

      <EditDeviceModal
        isOpen={isEditModalOpen}
        onClose={handleCloseModal}
        device={singleDevice}
        reloadDataForAccount={reloadDataForAccount}
        setisSuccessModalOpen={setisSuccessModalOpen}
        setSuccessMessage={setSuccessMessage}
      />
      {/* <SuccessMessageModal
        isOpen={isSuccessModalOpen}
        message={"Device updated successfully!"}
        onClose={() => {
          setisSuccessModalOpen(false);
          reloadDataForAccount();
        }}
      /> */}
      <DeleteDeviceModal
        isOpen={isDeleteModalOpen}
        onclose={handleCloseDeleteModal}
        device={singleDevice}
        onSave={onSaveDeleteModel}
      />
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
            reloadDataForAccount();
          }}
        />
      )}

      <DashboardUserAssetCountSection
        device_count={devices_count}
        active_devices_count={active_devices_count}
        sold_devices_count={sold_devices_count}
        screen={screen}
      />

      <Box {...styles.topBox}>
        <ControllerSearchFilters
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <ControllersTable
          devices={filteredDevices}
          handleRowClick={handleRowClick}
          handleModalOpen={handleModalOpen}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
      </Box>
    </Box>
  );
}

export default Controllers;
