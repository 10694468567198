import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Image,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Congratulations from "../assets/images/congratulations.jpg";
import SuccessMessageModal from "./successMessageModal";

// import * as Yup from "yup";
// import { clearConfigCache } from "prettier";

const Onboardingmodal = ({ isOpen, onclose }) => {
  // const toast = useToast();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  // const [successMessage, setSuccessMessage] = useState();
  const user = useSelector((state) => state?.auth?.userProfile);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleCloseCustomerModal = () => {
  //   setIsModalOpen(false);
  // };

  // const dispatch = useDispatch();

  // const handleCloseTeamModal = () => {
  //   setIsTeamModalOpen(false);
  // };

  // const refreshTeamMembers = () => {
  //   dispatch(getMemberAPI());
  // };

  // const refreshCustomers = () => {
  //   dispatch(getCustomerAPI());
  // };

  // const onSave = async (payload) => {
  //   console.log("onSave payload", payload);
  //   payload.role_id = payload.privilege;
  //   setLoading(true);
  //   try {
  //     const addCustomerReq = dispatch(addOrganizationAPI(payload));
  //     addCustomerReq
  //       .then((result) => {
  //         console.log(result);

  //         if (result?.payload?.success) {
  //           refreshCustomers();
  //           handleCloseCustomerModal();
  //           setLoading(false);
  //           setSuccessMessage(result?.payload?.message);
  //           toast({
  //             title: "Customer added successfully!",
  //             description: result?.payload?.message,
  //             status: "success",
  //             duration: 6000,
  //             isClosable: true,
  //           });
  //         } else {
  //           setLoading(false);
  //           toast({
  //             title: "Error",
  //             description: result?.payload?.message,
  //             status: "error",
  //             duration: 6000,
  //             isClosable: true,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  // };

  // const onSaveTeam = async (payload) => {
  //   console.log("onSave payload", payload);
  //   setLoading(true);
  //   try {
  //     const addMemberReq = dispatch(addMemberAPI(payload));
  //     addMemberReq
  //       .then((result) => {
  //         console.log(result);

  //         if (result?.payload?.success) {
  //           refreshTeamMembers();
  //           handleCloseTeamModal();
  //           setLoading(false);
  //           toast({
  //             title: "Team Member added successfully!",
  //             description: result?.payload?.message,
  //             status: "success",
  //             duration: 6000,
  //             isClosable: true,
  //           });
  //         } else {
  //           setLoading(false);
  //           toast({
  //             title: "Error",
  //             description: result?.payload?.message,
  //             status: "error",
  //             duration: 6000,
  //             isClosable: true,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         setLoading(false);
  //         console.log(err);
  //       });
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  // };

  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      // bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "2.5%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      p: 0,
    },
  };

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <Modal isOpen={isOpen} onClose={onclose} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <div style={{ alignItems: "center" }}>
            <ModalHeader pb={0}>Complete Your Account Setup</ModalHeader>
            <ModalCloseButton
              size="sm"
              px={8}
              fontSize={12}
              fontWeight={"500"}
              borderColor={"#385898"}
              border={"2px solid"}>
              Skip
            </ModalCloseButton>
          </div>
          <ModalBody className="my-element" mt={0} pt={0}>
            <Grid
              {...styles.row}
              // templateAreas={`"nav main main"`}
              // gridTemplateRows={"50px 1fr 30px"}
              // gridTemplateColumns={"100px 1fr"}
              // h="200px"
              gap="2">
              <GridItem {...styles.sideimage}>
                <Image
                  src={Congratulations}
                  // style={{ transform: scaleX(-1) }}
                />
              </GridItem>
              <GridItem {...styles.main}>
                <Text>
                  <b>Hello </b>
                  {user?.first_name
                    ? user?.first_name + " " + user?.last_name
                    : ""}
                  !
                </Text>
                <Text>
                  Welcome aboard! The App is designed to streamline your
                  organization&apos;s IoT Device workflows. Let&apos;s get
                  started with a quick actions to familiarize with the platform.
                </Text>
              </GridItem>
              <GridItem {...styles.sideimage}>
                <Image src={Congratulations} />
              </GridItem>
            </Grid>
            <Grid {...styles.row} gap="1">
              <GridItem {...styles.sideblank} />
              <GridItem {...styles.main}>
                <Text>Few things you can do on this platform are </Text>
                <Text style={{ marginTop: "20px" }}>
                  <UnorderedList>
                    <Grid {...styles.listrow} gap="1">
                      {isSuccessModalOpen && (
                        <SuccessMessageModal
                          isOpen={isSuccessModalOpen}
                          onClose={() => {
                            setisSuccessModalOpen(false);
                          }}
                        />
                      )}
                    </Grid>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Add Customer Accounts</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        {/* <AddCustomerModal
                          isOpen={isModalOpen}
                          onClose={handleCloseCustomerModal}
                          setIsModalOpen={setIsModalOpen}
                          onSave={onSave}
                          type={"Customer"}
                        /> */}
                        <Box style={{ marginInline: 8 }}>
                          <Button
                            {...styles.button}
                            rightIcon={<AddIcon />}
                            onClick={() =>
                              navigate("/customers", {
                                state: { isOpen: true },
                              })
                            }>
                            Add Customer
                          </Button>
                        </Box>
                      </GridItem>
                    </Grid>
                    <Grid {...styles.listrow} gap="1">
                      <GridItem {...styles.listtext}>
                        <ListItem>Collaborate with your team</ListItem>
                      </GridItem>
                      <GridItem {...styles.listbutton}>
                        {/* <AddTeamMemberModal
                          isOpen={isTeamModalOpen}
                          onClose={handleCloseTeamModal}
                          setIsModalOpen={setIsTeamModalOpen}
                          onSave={onSaveTeam}
                          type={"Team Member"}
                        /> */}
                        <Box style={{ marginInline: 8 }}>
                          <Button
                            {...styles.button}
                            rightIcon={<AddIcon />}
                            onClick={() =>
                              navigate("/team", {
                                state: { isOpen: true },
                              })
                            }>
                            Add Team
                          </Button>
                        </Box>
                      </GridItem>
                    </Grid>
                  </UnorderedList>
                </Text>
              </GridItem>
              <GridItem {...styles.sideblank} />
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"successMessage"}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
};

Onboardingmodal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};
export default Onboardingmodal;
