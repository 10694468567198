/**
 * Notes on how device types, models and devices get added.
 *
 * Roles of Manufactuer
 * - Register Device Type
 * - Register Device Model
 * - Register Data points for a given Device Model
 * - Register Firmware for a Model
 * - Register a Device aka Controller to Seller ( this is now SOLD )
 *
 *
 * Roles of Sellers
 * - Register Device using a Serial No
 * - Register Bulk devices using Serial No.
 * - Register Device with Type, Device Model, and Data points, and firmware information.
 *
 * Role of customer
 */

import { createSlice } from "@reduxjs/toolkit";
import {
  getTypesAPI,
  getModelsAPI,
  getUnitsAPI,
  deviceConfigHistoryAPI,
  deleteDeviceAPI,
  deleteTypesAPI,
  deleteModelsAPI,
  updateTypesAPI,
} from "../helpers/controllerAPI";

import {
  addDeviceAPI,
  addDeviceDataPointsAPI,
  deleteDeviceDataPointsAPI,
  getDeviceAPI,
  getDeviceDataPointsAPI,
  updateDeviceAPI,
  updateDeviceDataPointsAPI,
} from "../helpers/deviceAPI";

const initialState = {
  types: [],
  models: [],
  firmwares: [],
  search_slno: [],
  devices: [],
  units: [],
  activeType: {},
  activeModel: {},
  activeDevice: {},
  activeDevicesForModel: [],
  activeDeviceConfigHistory: [],
  dataPoints: [],

  //UI loader
  loader_visible: false,
  loader_message: "Loading...",
};

export const controllersSlice = createSlice({
  name: "controllers",
  initialState,
  reducers: {
    // helps navigation and breadcrumbs
    setActiveType: (state, action) => {
      state.activeType = action.payload;
    },
    setActiveModel: (state, action) => {
      state.activeModel = action.payload;
    },
    setActiveDevice: (state, action) => {
      state.activeDevice = action.payload;
    },
    setActiveDevicesForModel: (state, action) => {
      state.activeDevicesForModel = action.payload;
    },
    resetStateController: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypesAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(getTypesAPI.fulfilled, (state, action) => {
        console.log("getTypes", action);
        state.types = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(getTypesAPI.rejected, (state, action) => {
        state.types = [];
        console.log("loading types failed", action);
        state.loader_visible = false;
      })
      .addCase(updateTypesAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(updateTypesAPI.fulfilled, (state, action) => {
        state.types = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(updateTypesAPI.rejected, (state, action) => {
        console.log("updating types failed", action);
        state.loader_visible = false;
      })
      .addCase(deleteTypesAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(deleteTypesAPI.fulfilled, (state, action) => {
        console.log("deleteTypesAPI", action);
        state.types = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(deleteTypesAPI.rejected, (state, action) => {
        console.log("loading devices-types failed", action);
        state.loader_visible = false;
      })
      .addCase(getModelsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(getModelsAPI.fulfilled, (state, action) => {
        console.log("getModels", action);
        state.models = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(getModelsAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("loading models failed", action);
        state.loader_visible = false;
      })
      .addCase(deleteModelsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(deleteModelsAPI.fulfilled, (state, action) => {
        console.log("deleteModelsAPI", action);
        state.models = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(deleteModelsAPI.rejected, (state, action) => {
        console.log("loading models failed", action);
        state.loader_visible = false;
      })
      .addCase(getUnitsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(getUnitsAPI.fulfilled, (state, action) => {
        console.log("getUnits", action);
        state.units = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(getUnitsAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("loading units failed", action);
        state.loader_visible = false;
      })
      .addCase(addDeviceAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(addDeviceAPI.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(addDeviceAPI.rejected, (state, action) => {
        state.devices = [];
        console.log("adding devices failed", action);
        state.loader_visible = false;
      })
      .addCase(getDeviceAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(getDeviceAPI.fulfilled, (state, action) => {
        console.log("getDevice", action);
        state.devices = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(getDeviceAPI.rejected, (state, action) => {
        console.log("loading devices failed", action);
        state.loader_visible = false;
      })
      .addCase(updateDeviceAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(updateDeviceAPI.fulfilled, (state, action) => {
        state.devices = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(updateDeviceAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("updating devices failed", action);
        state.loader_visible = false;
      })
      .addCase(deleteDeviceAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(deleteDeviceAPI.fulfilled, (state, action) => {
        console.log("deleteDeviceAPI", action);
        state.devices = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(deleteDeviceAPI.rejected, (state, action) => {
        console.log("loading devices failed", action);
        state.loader_visible = false;
      })
      .addCase(addDeviceDataPointsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(addDeviceDataPointsAPI.fulfilled, (state, action) => {
        state.dataPoints = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(addDeviceDataPointsAPI.rejected, (state, action) => {
        state.dataPoints = [];
        console.log("adding dataPoints failed", action);
        state.loader_visible = false;
      })
      .addCase(getDeviceDataPointsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(getDeviceDataPointsAPI.fulfilled, (state, action) => {
        console.log("getDeviceDataPoints", action);
        state.dataPoints = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(getDeviceDataPointsAPI.rejected, (state, action) => {
        console.log("loading dataPoints failed", action);
        state.loader_visible = false;
      })
      .addCase(updateDeviceDataPointsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(updateDeviceDataPointsAPI.fulfilled, (state, action) => {
        state.dataPoints = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(updateDeviceDataPointsAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("updating dataPoints failed", action);
        state.loader_visible = false;
      })
      .addCase(deleteDeviceDataPointsAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(deleteDeviceDataPointsAPI.fulfilled, (state, action) => {
        console.log("deleteDeviceDataPointsAPI", action);
        // state.dataPoints = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(deleteDeviceDataPointsAPI.rejected, (state, action) => {
        console.log("loading dataPoints failed", action);
        state.loader_visible = false;
      })
      .addCase(deviceConfigHistoryAPI.pending, (state) => {
        state.loader_visible = true;
      })
      .addCase(deviceConfigHistoryAPI.fulfilled, (state, action) => {
        state.activeDeviceConfigHistory = action?.payload?.data;
        state.loader_visible = false;
      })
      .addCase(deviceConfigHistoryAPI.rejected, (state, action) => {
        // state.models = action?.payload;
        console.log("deviceConfigHistoryAPI", action);
        state.loader_visible = false;
      });
  },
});

export const {
  setActiveDevice,
  setActiveType,
  setActiveModel,
  setActiveDevicesForModel,
  resetStateController,
} = controllersSlice.actions;

export default controllersSlice.reducer;
