import { Flex } from "@chakra-ui/layout";
import { Box, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { BiAdjust } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Branding from "../assets/images/wintergardenz-logo.webp";
import Onboardingmodal from "../components/onboardingModal";

export default function Onboarding() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/dashboard");
  };

  // useEffect(() => {
  //   if (is_setup_complete) {
  //     setIsModalOpen(true);
  //   } else {
  //     setIsModalOpen(true);
  //   }
  // }, [is_setup_complete]),
  // console.log("Onboarding", is_setup_complete, isModalOpen);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient: "linear(to-br, #7928CA, #0762ef)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <Flex {...styles.background}>
      <Box
        width={"100%"}
        height="7.5vh"
        bgColor={"white"}
        alignContent={"center"}
        px={10}
        marginTop={0}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <div
            style={{
              mx: "2%",
            }}>
            <Image height={"4vh"} src={Branding} alt="Winter Gardenz"></Image>
          </div>
          <div
            style={{
              mx: "2%",
              display: "flex",
              alignItems: "center",
            }}>
            <BiAdjust size={"16"} color="black" />
            <Text color={"black"} fontWeight={"400"} fontSize={"20"}>
              {"   "}
              WinterGardenz{" "}
            </Text>
          </div>
        </Box>
      </Box>
      <Flex {...styles.topContainer}>
        <Onboardingmodal isOpen={isModalOpen} onclose={handleCloseModal} />
      </Flex>
    </Flex>
  );
}
