import {
  // Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import SuccessMessageModal from "./successMessageModal";

export default function CustomersTable({
  customers,
  handleRowClick,
  // refreshCustomers,
  // handleModifyClick,
  // handleDeleteModalOpen,
}) {
  // const dispatch = useDispatch();
  // const toast = useToast();
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  //   setSelectedCustomer(null);
  // };

  // const handleModifyClick = (teamMember) => {
  //   setSelectedCustomer(teamMember);
  //   setIsModalOpen(true);
  // };

  // const onSave = async (data) => {
  //   const payloadData = {
  //     first_name:
  //       selectedCustomer?.first_name == data?.first_name
  //         ? ""
  //         : data?.first_name,
  //     last_name:
  //       selectedCustomer?.last_name == data?.last_name ? "" : data?.last_name,
  //     email: selectedCustomer?.email == data?.email ? "" : data?.email,
  //     phone_number:
  //       selectedCustomer?.phone_number == data?.phone_number
  //         ? ""
  //         : data?.phone_number,
  //   };

  //   const payload = Object.fromEntries(
  //     Object.entries(payloadData).filter(([, value]) => value !== ""),
  //   );
  //   payload.member_id = selectedCustomer?.id;
  //   const editMemberReq = dispatch(editCustomerAPI(payload));
  //   editMemberReq
  //     .then((result) => {
  //       console.log(result);

  //       if (result?.payload?.success) {
  //         refreshCustomers();
  //         handleCloseModal();
  //         toast({
  //           title: "success",
  //           description: result?.payload?.message,
  //           status: "success",
  //           duration: 6000,
  //           isClosable: true,
  //         });
  //       } else {
  //         toast({
  //           title: "Error",
  //           description: result?.payload?.message,
  //           status: "error",
  //           duration: 6000,
  //           isClosable: true,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    customerName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      width: "70%",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
    },
  };

  return (
    <>
      {customers?.length > 0 ? (
        <TableContainer {...styles.tableContainer}>
          <Table
            size={{ base: "sm", md: "md" }}
            variant="striped"
            colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th>ORGANIZATION</Th>
                <Th>FIRST NAME</Th>
                <Th>LAST NAME</Th>
                <Th>EMAIL</Th>
                <Th>PHONE NO.</Th>
                <Th>Devices Owned </Th>
                {/* <Th>ACTIONS</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {customers?.map((customer, index) => {
                return (
                  <Tr key={customer?.id || index} style={{ cursor: "pointer" }}>
                    <Td
                      {...styles.customerName}
                      onClick={() => handleRowClick(customer)}>
                      {customer?.name}
                    </Td>
                    <Td onClick={() => handleRowClick(customer)}>
                      <Text>{customer?.first_name}</Text>
                    </Td>
                    <Td onClick={() => handleRowClick(customer)}>
                      {customer?.last_name}
                    </Td>
                    <Td onClick={() => handleRowClick(customer)}>
                      {customer?.email}
                    </Td>
                    <Td>{customer?.phone_number}</Td>

                    {/* TODO: Total Owned device Integration */}
                    <Td onClick={() => handleRowClick(customer)}>
                      {customer?.total_owned_devices_count
                        ? customer?.total_owned_devices_count
                        : 0}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text p={4} align={"center"}>
          No customer found
        </Text>
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Customer details updated successfully!"}
          onClose={() => {
            setisSuccessModalOpen(false);
            window.location.reload();
          }}
        />
      )}
    </>
  );
}

CustomersTable.propTypes = {
  customers: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  refreshCustomers: PropTypes.func,
  handleModifyClick: PropTypes.func,
  handleDeleteModalOpen: PropTypes.func,
};
