import React, { useEffect, useState } from "react";
import { Box, Spacer, Flex, useToast } from "@chakra-ui/react";
import Breadcrumbs from "../components/breadcrumbs";
import AddTeamMemberModal from "../components/addTeamMemberModal";
import TeamMembersTable from "../components/teamMembersTable";
import SearchFilter from "../components/searchFilter";
import { useDispatch, useSelector } from "react-redux";
import { addMemberAPI, getMemberAPI } from "../redux/helpers/teamAPI";
import { userRolesAPI } from "../redux/helpers/authAPI";
import SuccessMessageModal from "../components/successMessageModal";
import Loader from "../components/loader";
import { useLocation } from "react-router-dom";

function Team() {
  const dispatch = useDispatch();
  const toast = useToast();
  const location = useLocation();
  const { isOpen } = location.state || false;
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [loading, setLoading] = useState(true);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);

  const teamMemberData = useSelector(
    (state) => state.teamMember.teamMemberData,
  );

  useEffect(() => {
    Promise.all([dispatch(userRolesAPI())])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    refreshTeamMembers();
  }, [teamMemberData?.length]);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [searchParams, setSearchParams] = useState({
    name: "",
  });

  const [filteredteamMemberData, setfilteredteamMemberData] =
    useState(teamMemberData);

  const handleSearch = () => {
    const newfilteredteamMemberData = teamMemberData?.filter((data) => {
      const nameMatch =
        !searchParams.name ||
        data.first_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase()) ||
        data.last_name.toLowerCase().includes(searchParams.name.toLowerCase());

      return nameMatch;
    });

    setfilteredteamMemberData(newfilteredteamMemberData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });
    setfilteredteamMemberData(teamMemberData);
  };

  const refreshTeamMembers = () => {
    const membersRequest = dispatch(getMemberAPI());
    membersRequest.then((membersReq) => {
      setfilteredteamMemberData(membersReq?.payload?.data);
    });
  };

  const onSave = async (payload) => {
    console.log("onSave payload", payload);
    setLoading(true);
    try {
      const addMemberReq = dispatch(addMemberAPI(payload));
      addMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            handleCloseModal();
            refreshTeamMembers();
            setisSuccessModalOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddTeamMemberModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSave}
          type={"Add Team Member"}
          //onSuccess={}
        />
        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={"Successfully added team member to your account"}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Flex>
      <Box {...styles.topBox}>
        <SearchFilter
          type="name"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <TeamMembersTable
          enableActions={true}
          teamMembers={filteredteamMemberData}
          refreshTeamMembers={refreshTeamMembers}
        />
      </Box>
    </Box>
  );
}

export default Team;
