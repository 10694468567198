import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import * as Yup from "yup";
import ModelFooterButtons from "./modelFooterButtons";

const EditDeviceTypeModal = ({ isOpen, onClose, onSave, device }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  // useEffect(() => {
  //   // TODO: check if the name is existing
  // }, [device_type_name]);

  const nameRegex = /^[a-zA-Z0-9\s]+$/;
  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(nameRegex, "Type must contain only letters, numbers and spaces")
      .max(45, "Type must be at most 45 characters.")
      .required("Type is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      {/* <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Type" /> */}
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Product</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                name: device?.name || "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  name: device?.name == values?.name ? "" : values?.name,
                  device_type_id: device?.id,
                };
                console.log("Clicked");
                onSave(payload);
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl isInvalid={errors?.name && touched?.name}>
                      <FormLabel htmlFor="name">Product</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        name="name"
                        defaultValue={device?.name}
                        placeholder="Product Name"
                      />
                      <FormErrorMessage>{errors?.type}</FormErrorMessage>
                    </FormControl>
                    {/* <FormControl isInvalid={errors?.notes && touched?.notes}>
                      <FormLabel htmlFor="notes">Notes</FormLabel>
                      <Field
                        as={Textarea}
                        {...styles.input}
                        id="notes"
                        defaultValue={device?.notes}
                        name="notes"
                        type="textarea"
                        placeholder="Notes for this type"
                      />

                      <FormErrorMessage>{errors?.notes}</FormErrorMessage>
                    </FormControl> */}

                    <Box justifyContent={"flex-end"} width={"100%"}>
                      <ModelFooterButtons
                        onClose={onClose}
                        onSave={handleSubmit}
                      />
                    </Box>
                  </VStack>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditDeviceTypeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
};
export default EditDeviceTypeModal;
