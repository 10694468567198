import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  // Center,
  Flex,
  Heading,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
// import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import SuccessMessageModal from "./successMessageModal";
import { deleteDeviceAPI } from "../redux/helpers/controllerAPI";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
import EditDeviceModal from "./editDeviceModal";

const ControllerModelDevices = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();

  // activeType: {},
  // activeModel: {},
  // activeDevice: {}

  const device_data = useSelector(
    (state) => state?.controllers?.activeDevicesForModel,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleDeviceType, setSingleDeviceType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const deletionType = "device";
  const [singleDevice, setSingleDevice] = useState("");
  // const { device_data } = location.state || {};

  const handleRowClick = (deviceData) => {
    // navigate("/units/device", { state: { deviceData } });
    dispatch(setActiveDevice(deviceData));
    navigate("/products/models/devices/device", { state: { deviceData } });
  };

  const handleDeleteModalOpen = (device) => {
    setIsDeleteModalOpen(true);
    setSingleDeviceType(device);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleModalOpen = (device) => {
    setIsEditModalOpen(true);
    device = { ...device, update: true };
    setSingleDevice(device);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const onSaveDeleteModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDeleteModel payload", payload);

    let deleteTypesRes = dispatch(deleteDeviceAPI(payload));
    deleteTypesRes
      .then((result) => {
        console.log(result);
        if (result?.payload?.success) {
          setSuccessMessage("Device Deleted successfully");
          dispatch(getDeviceAPI());
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const styles = {
    cardTop: {},
    card: {
      w: "30%",
      cursor: "pointer",
    },
    cardHeading: {
      fontSize: 20,
      fontWeight: 600,
    },
    align: {
      alignItems: "center",
      gap: 2,
      p: 2,
    },
    item: {
      p: 2,
      fontSize: 14,
      fontWeight: 400,
    },
    itemValue: {
      p: 2,
      fontSize: 14,
      fontWeight: 600,
    },
  };
  return (
    <>
      <Box>
        {/* <Flex gap={2} pt={2} ml={3}>
          <Flex>
            <Center>
              <GoDotFill color={"green"} />
              Online
            </Center>
          </Flex>
          <Flex>
            <Center>
              <GoDotFill color={"red"} />
              Offline
            </Center>
          </Flex>
        </Flex> */}
        <Flex
          {...styles.cardTop}
          flexWrap="wrap"
          gap={4}
          align-items="flex-start">
          {device_data?.map((val, index) => (
            <Card
              {...styles.card}
              key={index}
              onClick={() => handleRowClick(val)}>
              <CardHeader py={0} bgColor="#EBEBEB" borderTopRadius="md">
                <Flex alignItems="center" justifyContent="space-between">
                  <Heading {...styles.cardHeading}>{val?.name}</Heading>
                  <Flex {...styles.align}>
                    <FaCheckCircle color="green" />
                    <Text>Active</Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody px={4} py={2}>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Serial No</Heading>
                  <Heading {...styles.itemValue}>{val?.serial_number}</Heading>
                </Flex>

                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Organization</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.assigned_to_customer?.organization}
                  </Heading>
                </Flex>

                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Customer</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.assigned_to_customer?.first_name}
                  </Heading>
                </Flex>

                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Manufacturer</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.manufacturer?.organization}
                  </Heading>
                </Flex>
                {/* <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Type</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.device_type?.name}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Model</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.device_model?.name}
                  </Heading>
                </Flex> */}
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Status</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.provision_status}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between">
                  <Button
                    size={"xs"}
                    colorScheme="teal"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModalOpen(val);
                    }}>
                    Modify
                    <SettingsIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                  <Button
                    size={"xs"}
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModalOpen(val);
                    }}>
                    Delete
                    <DeleteIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                </Flex>
              </CardBody>
              <CardFooter pt={0}>
                <Text size={"xs"} fontSize={12}>
                  Updated {new Date(val?.updated_at).toLocaleString()}
                </Text>
              </CardFooter>
            </Card>
          ))}
        </Flex>
        <EditDeviceModal
          isOpen={isEditModalOpen}
          onClose={handleCloseModal}
          device={singleDevice}
        />
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={singleDeviceType}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={`${successMessage}!`}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

ControllerModelDevices.propTypes = {
  devices: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func,
  handleModalOpen: PropTypes.func.isRequired,
};

export default ControllerModelDevices;
