import React from "react";
import {
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";

export default function CustomerTickets() {
  const customerTickets = useSelector(
    (state) => state.customer.customerTickets,
  );
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "50vh",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
    ticketData: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
  };
  return (
    <TableContainer {...styles.tableContainer}>
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width={"30%"}>TICKET ID</Th>
            <Th width={"30%"}>SUBJECT</Th>
            <Th width={"30%"}>LAST UPDATED</Th>
            <Th width={"10%"}>STATUS</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customerTickets.map((ticket, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Text {...styles.ticketData}>{ticket?.ticketId}</Text>
                </Td>
                <Td>{ticket?.subject}</Td>
                <Td>{ticket?.lastUpdated}</Td>
                <Td>
                  <Tag
                    variant="solid"
                    colorScheme={
                      ticket?.status === "Resolved" ? "green" : "orange"
                    }>
                    {ticket?.status}
                  </Tag>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
