import {
  Button,
  FormControl,
  FormErrorMessage,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Select,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignDeviceAPI, getDeviceAPI } from "../redux/helpers/deviceAPI";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
// import { useNavigate } from "react-router-dom";

const AssignDevicePop = ({ deviceData }) => {
  const { onClose } = useDisclosure();
  // isOpen, onToggle, const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const customerList = useSelector((state) => state.customer.organizations);
  const [customer_id, setAssignCustomer] = useState([]);
  const [isCustomerSelected, setIsCustomerSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();

  console.log("devidatatatatat", deviceData);
  const styles = {
    formFlex: {
      alignItems: "center",
      gap: 2,
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    buttonFlex: {
      justifyContent: "end",
    },
    cancelButton: {
      mr: 3,
      size: "sm",
    },
    submitButton: {
      size: "sm",
      colorScheme: "blue",
    },
  };

  const onCustomerSelected = (customer) => {
    setAssignCustomer(customer);
    setIsCustomerSelected(true);
  };

  return (
    <>
      <Loader loading={loading} />
      <Popover
        // isOpen={isOpen}
        style={{ flex: 11 }}
        // closeOnBlur={true}
      >
        <PopoverTrigger>
          <Button size="sm" colorScheme="facebook" width="10rem">
            {" "}
            Assign Device
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>Assign to Organization</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Formik
                initialValues={{
                  customer: "",
                }}
                onSubmit={() => {
                  setLoading(true);
                  try {
                    const payload = {
                      device_id: deviceData?.id,
                      assigned_to_id: customer_id,
                    };

                    console.log(payload);

                    const onAssignedReq = dispatch(assignDeviceAPI(payload));

                    onAssignedReq
                      .then((data) => {
                        console.log("onAssignedReq", data.payload);

                        if (data?.payload?.success) {
                          onClose();
                          console.log(
                            "handleAssignDevicehandleAssignDevicehandleAssignDevicehandleAssignDevice",
                          );
                          setSuccessMessage(data.payload?.message);
                          dispatch(getDashboardDataAPI())
                            .then(() => {
                              dispatch(getDeviceAPI())
                                .then(() => {
                                  setisSuccessModalOpen(true);
                                  setLoading(false);
                                })
                                .catch((err) => {
                                  console.log(err);
                                  setLoading(false);
                                });
                            })
                            .catch((err) => {
                              console.log(err);
                              setLoading(false);
                            });
                        } else {
                          setLoading(false);
                          toast({
                            title: "Error while assigning",
                            description: data?.payload.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((error) => {
                        setLoading(false);
                        console.error("Error fetching api:", error);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <FormControl
                        isInvalid={errors?.customer && touched?.customer}>
                        <Select
                          style={{ marginTop: "10px", ...styles.input }}
                          placeholder="Select Customer"
                          id="customer"
                          name="customer"
                          onChange={(event) =>
                            onCustomerSelected(event.target.value)
                          }>
                          {customerList?.map((val, index) => {
                            return (
                              <option key={index} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })}
                        </Select>
                        <FormErrorMessage>{errors?.customer}</FormErrorMessage>
                      </FormControl>

                      <Button
                        {...styles.loginButton}
                        type="submit"
                        isDisabled={!isCustomerSelected}
                        colorScheme="facebook">
                        Assign
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
};

AssignDevicePop.propTypes = {
  deviceData: PropTypes.object,
  handlePopClose: PropTypes.func,
  // isOpen: PropTypes.bool,
};

export default AssignDevicePop;
