import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import ModelFooterButtons from "./modelFooterButtons";
// import { useSelector } from "react-redux";
import { Field, Formik } from "formik";
import * as Yup from "yup";

export const EditCustomerModal = ({ isOpen, onClose, onSave, customer }) => {
  console.log(customer, "customercustomer");
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  // const roles = useSelector((state) => state?.auth?.roles);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const AddCustomerValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRegex, "First Name must contain only letters and spaces")
      .required("First Name is required")
      .max(36, "First Name must be at most 36 characters long"),
    last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(254, "Email must be at most 254 characters.")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(
        /^\+[0-9]{1,3}-[0-9]{4}-[0-9]{3}$/,
        "Enter valid Phone Number (e.g., +81-1223-333)",
      )
      .required("Phone Number is required"),
    // privilege: Yup.string().required("Privilege is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };
  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                email: customer?.email,
                first_name: customer?.first_name,
                last_name: customer?.last_name,
                phone_number: customer?.phone_number,
                // privilege:
                //   roles.find((role) => role.name === customer.privilege)?.id ||
                //   "",
              }}
              validationSchema={AddCustomerValidationSchema}
              onSubmit={(values) => {
                console.log(values);
                onSave(values);
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={errors.first_name && touched.first_name}>
                    <FormLabel>Contact Person First Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      ref={initialRef}
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="john"
                    />
                    <FormErrorMessage>{errors?.first_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.last_name && touched.last_name}>
                    <FormLabel>Contact Person Last Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Doe"
                    />
                    <FormErrorMessage>{errors?.last_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.phone_number && touched.phone_number}>
                    <FormLabel>Contact Person Phone Number</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="phone_number"
                      name="phone_number"
                      type="tel"
                      placeholder="+81-1223-333"
                    />
                    <FormErrorMessage>{errors?.phone_number}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.email && touched.email}>
                    <FormLabel>Contact Person Email</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="john_doe@example.com"
                    />
                    <FormErrorMessage>{errors?.email}</FormErrorMessage>
                  </FormControl>
                  {/* <FormControl
                    {...styles.formItem}
                    isInvalid={errors.privilege && touched.privilege}>
                    <FormLabel>Privilege</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="privilege"
                      defaultValue={values.privilege}
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.target.value", e.target.value);
                        setFieldValue("privilege", e.target.value);
                      }}>
                      {roles.map((role) => {
                        return (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                  </FormControl> */}

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

EditCustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  customer: PropTypes.object,
};
