import React, { useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import PropTypes from "prop-types";
import { FaUpload } from "react-icons/fa6";
import ModelFooterButtons from "./modelFooterButtons";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { addFirmwareAPI } from "../redux/helpers/firmwareAPI";
import ModelsTriggerButton from "./modelsTriggerButton";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

export const AddFirmwareModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSaveFirmware,
}) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [fileName, setFileName] = useState("");

  const nameRegex = /^[a-zA-Z0-9-.\s]+$/;
  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .max(45, "Firmware name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Firmware name can only contain Letters, Numbers, Full Stop, Hyphen & Spaces",
      )
      .required("Firmware name is required"),
    device_model: Yup.string().required("Firmware model is required"),
    code_name: Yup.string()
      .max(45, "Code name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Code name can only contain Letters, Hyphen, Numbers & Spaces",
      ),
    file: Yup.mixed()
      .test("fileSize", "File size is too large", (value) => {
        if (!value) return true;
        return value.size <= 5 * 1024 * 1024; // 5 MB
      })
      .test("fileType", "Unsupported file type", (value) => {
        if (!value) return true;
        return ["image/jpeg", "image/png", "application/pdf"].includes(
          value.type,
        );
      })
      .required("Firmware file is required"),
  });

  const models = useSelector((state) => state?.controllers?.models);

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <Loader loading={loading} />
      <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Firmware" />
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Firmware</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                name: "",
                device_model: "",
                code_name: "",
                file: null,
                is_prod: false,
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                setLoading(true);
                try {
                  const payload = {
                    name: values?.name,
                    model_id: values?.device_model,
                    code_name: values?.code_name,
                    file: values?.file,
                    is_prod: values?.is_prod === true ? 1 : 0,
                  };
                  const addFirmwareReq = dispatch(addFirmwareAPI(payload));
                  addFirmwareReq
                    .then((data) => {
                      if (data?.payload?.success) {
                        setSuccessMessage(data?.payload?.message);
                        setLoading(false);
                        setisSuccessModalOpen(true);
                        setFileName("");
                        onClose();
                        onSaveFirmware();
                      } else {
                        setLoading(false);
                        toast({
                          title: "Error",
                          description:
                            data?.payload?.message ||
                            "Failed to perform the operation",
                          status: "error",
                          duration: 6000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((error) => {
                      setLoading(false);
                      console.log(error);
                    });
                } catch (err) {
                  setLoading(false);
                  console.log("errror in api", err);
                }
              }}>
              {({ handleSubmit, errors, touched, setFieldValue, values }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_model && touched.device_model}>
                    <FormLabel>Model</FormLabel>
                    <Select
                      {...styles.input}
                      ref={initialRef}
                      placeholder="Select Type"
                      id="device_model"
                      name="device_model"
                      onChange={(event) => {
                        setFieldValue("device_model", event.target.value);
                      }}>
                      {models?.map((val, index) => {
                        return (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.name && touched.name}>
                    <FormLabel> Firmware Name </FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      style={{ marginTop: 10 }}
                      ref={initialRef}
                      placeholder="Firmware Name"
                      id="name"
                      name="name"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.code_name && touched.code_name}>
                    <FormLabel>Code Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      placeholder="Code name"
                      name="code_name"
                      id="code_name"
                    />
                    <FormErrorMessage>{errors.code_name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.file && touched.file}>
                    <FormLabel htmlFor="file">Upload File</FormLabel>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(event) => {
                        setFieldValue("file", event.target.files[0]);
                        setFileName(event.target.files[0].name);
                      }}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file">
                      <Button
                        as="span"
                        leftIcon={<FaUpload />}
                        cursor="pointer"
                        variant="outline"
                        colorScheme="blue">
                        Choose File
                      </Button>
                    </label>
                    {fileName && <div>{fileName}</div>}
                    <FormErrorMessage>{errors.file}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.is_prod && touched.is_prod}>
                    <Checkbox
                      name="is_prod"
                      id="is_prod"
                      colorScheme="green"
                      isChecked={values.is_prod}
                      onChange={(e) => {
                        setFieldValue("is_prod", e.target.checked);
                      }}>
                      Production
                    </Checkbox>

                    <FormErrorMessage>{errors.is_prod}</FormErrorMessage>
                  </FormControl>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
};

AddFirmwareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSaveFirmware: PropTypes.func,
};
