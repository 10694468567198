import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addTypeAPI = createAsyncThunk(
  "addType",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/device-type`,
        {
          name: payload?.type,
          note: payload?.note,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getTypesAPI = createAsyncThunk(
  "getTypes",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/device-type`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

/**
 * updateTypesAPI - Use this for both update and soft-delete
 */
export const updateTypesAPI = createAsyncThunk(
  "updateTypes",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      let response = await API.put(
        `/seller/device-type/${payload?.device_type_id}`,
        payload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteTypesAPI = createAsyncThunk(
  "deleteTypesAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/seller/device-type/${payload.device_type_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getModelsAPI = createAsyncThunk(
  "getModels",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/model`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getUnitsAPI = createAsyncThunk(
  "getUnits",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/user/measure/units`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const addModelAPI = createAsyncThunk(
  "addModel",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(`/seller/model`, payload, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const updateModelsAPI = createAsyncThunk(
  "updateModelsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      let response = await API.put(
        `/seller/model/${payload?.model_id}`,
        payload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteModelsAPI = createAsyncThunk(
  "deleteModelsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/seller/model/${payload.model_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deviceConfigHistoryAPI = createAsyncThunk(
  "deviceConfig",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/seller/device/${payload?.device_id}/configuration-history`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteDeviceAPI = createAsyncThunk(
  "deleteDeviceAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/seller/unassign/device/${payload.device_assignment_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);
