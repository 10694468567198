import { Card, CardBody, CardHeader } from "@chakra-ui/card";
import {
  Box,
  Center,
  Divider,
  Grid,
  GridItem,
  Heading,
} from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
// import RecentAlertList from "../components/recentAlertList";
import { Text } from "@chakra-ui/react";
import ReactECharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../components/breadcrumbs";
import DashboardCustomerTeamMembers from "../components/dashboardCustomerTeamMembers";
import DashboardDeviceList from "../components/dashboardDeviceList";
import DashboardUserAssetCountSection from "../components/dashboardUserAssetCountSection";
import DeviceWatchlist from "../components/deviceWatchlist";
import Loader from "../components/loader";
import { getOrganizationsAPI } from "../redux/helpers/customerAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";

function Dashboard() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const options = {
    title: {
      text: "Device Data Over Time",
      left: "left",
      padding: [20, 0, 0, 20],
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: ["Device 1", "Device 2", "Device 3", "Device 4", "Device 5"],
      top: "12%",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: [
        "02 PM",
        "04 PM",
        "06 PM",
        "08 PM",
        "10 PM",
        "12 PM",
        "02 AM",
        "04 AM",
        "06 AM",
        "08 AM",
        "10 AM",
        "12 AM",
      ],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "Device 1",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Device 2",
        type: "line",
        stack: "Total",
        // data: [20, 34, 39, 48, 59, 60, 70, 75, 65, 69, 70],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Device 3",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Device 4",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Device 5",
        type: "line",
        stack: "Total",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  };
  const styles = {
    topGrid: {
      gap: 4,
      templateColumns: "repeat(2, 1fr)",
    },
    topContainerWatchlist: {
      overflowY: "auto",
      h: { base: "68vh", md: "32vh", lg: "38vh" },
      w: { base: "100%", lg: "50vw" },
      colSpan: { base: "2", md: "2", lg: "1" },
      pr: 2,
    },
    cardHeader: {
      size: "md",
    },
    recentAlertsCard: {
      w: { base: "100%", lg: "31vw" },
      overflowY: "auto",
      h: "37vh",
      size: "sm",
    },
    sideList: {
      colSpan: { base: "2", md: "2", lg: "1" },
    },
    lineGraph: {
      colSpan: { base: "2", md: "2", lg: "1" },
    },
    divider: {
      w: "92%",
      color: "gray.200",
    },
    chartStyles: {
      w: { base: "100vw", lg: "49.5vw" },
      height: "37vh",
      backgroundColor: "white",
      borderRadius: 8,
      p: 10,
    },
  };

  const devices_count = useSelector(
    (state) => state?.summary?.total_devices_count,
  );
  const active_devices_count = useSelector(
    (state) => state?.summary?.active_devices_count,
  );
  const sold_devices_count = useSelector(
    (state) => state?.summary?.total_devices_sold,
  );
  const recently_added_devices = useSelector(
    (state) => state?.summary?.recently_added_devices,
  );
  const recently_updated_devices = useSelector(
    (state) => state?.summary?.recently_updated_devices,
  );

  const customer_team_members_added = useSelector(
    (state) => state?.summary?.recently_added_customers,
  );

  console.log(
    "dvdsvdsvdfvdbd",
    useSelector((state) => state?.summary?.summaryData),
  );

  useEffect(() => {
    setLoading(true);

    Promise.all([
      dispatch(getDashboardDataAPI()),
      dispatch(getOrganizationsAPI()),
      dispatch(getDeviceAPI()),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <Box>
      <Loader loading={loading} />
      <Breadcrumbs />
      <Grid {...styles.topGrid}>
        <GridItem {...styles.topContainerWatchlist}>
          <DashboardUserAssetCountSection
            device_count={devices_count}
            active_devices_count={active_devices_count}
            sold_devices_count={sold_devices_count}
          />

          <Text mt={8}> Device Status Watch </Text>
          <DeviceWatchlist device_list={recently_updated_devices} />
        </GridItem>
        <GridItem {...styles.sideList}>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Recent Devices</Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <DashboardDeviceList
                recent_devices={recently_added_devices}
                is_update={false}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem {...styles.sideList}>
          <ReactECharts option={options} style={styles.chartStyles} />
        </GridItem>
        <GridItem {...styles.sideList}>
          <Card {...styles.recentAlertsCard}>
            <CardHeader>
              <Heading {...styles.cardHeader}>Recent Customers </Heading>
            </CardHeader>
            <Center>
              <Divider {...styles.divider} />
            </Center>
            <CardBody>
              <DashboardCustomerTeamMembers
                customer_team_members_added={customer_team_members_added}
              />
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default Dashboard;
