import React from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import Navigations from "./routes/navigation";
import { useSelector } from "react-redux";

function App() {
  const authState = useSelector((state) => state?.auth?.is_authenticated);
  const authData = useSelector((state) => state?.auth);

  if (
    authState &&
    window.location.pathname === "/" &&
    authData?.is_setup_complete === false
  ) {
    window.location.href = "/onboarding";
  } else if (
    authState &&
    window.location.pathname === "/" &&
    authData?.is_setup_complete
  ) {
    window.location.href = "/dashboard";
  }
  return (
    <RouterProvider
      router={Navigations()}
      fallbackElement={<p>Initial Load...</p>}
    />
  );
}

export default App;
