import { Button, ModalFooter, Spacer, useToast } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteMemberAPI, getMemberAPI } from "../redux/helpers/teamAPI";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

export default function ModelFooterButtons({
  onClose,
  onSave,
  type,
  data = null,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const removeMember = () => {
    setLoading(true);
    try {
      const payload = {
        member_id: data?.id,
      };
      let removeMemberRes = dispatch(deleteMemberAPI(payload));
      removeMemberRes
        .then((data) => {
          console.log("onRemoveMemberResponse", data);
          if (data?.payload?.success) {
            setLoading(false);
            onClose();
            dispatch(getMemberAPI()).then((mem_list) => {
              if (mem_list?.payload?.success) {
                setisSuccessModalOpen(true);
              } else {
                toast({
                  title: "Error",
                  description: "Error while fetching the member",
                  status: "error",
                  duration: 6000,
                  isClosable: true,
                });
              }
            });
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: "Error while deleting the member",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const styles = {
    cancelButton: {
      mr: 3,
      size: "sm",
    },
    submitButton: {
      colorScheme: "facebook",
      size: "sm",
    },
    removeButton: {
      colorScheme: "red",
      size: "sm",
    },
  };
  return (
    <ModalFooter
      style={{
        padding: 0,
        marginTop: 15,
        marginBottom: 15,
      }}>
      <Loader loading={loading} />
      {type?.includes("Edit") ? (
        <Button
          {...styles.removeButton}
          onClick={() => (data ? removeMember() : console.log(""))}>
          Deactivate
        </Button>
      ) : null}
      <Spacer />
      <Button onClick={onClose} {...styles.cancelButton}>
        Cancel
      </Button>
      <Button type="submit" onClick={onSave} {...styles.submitButton}>
        Submit
      </Button>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Successfully deleted the member"}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </ModalFooter>
  );
}

ModelFooterButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  type: PropTypes.string,
  data: PropTypes.object,
};
