import {
  Box,
  Center,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { GoDotFill } from "react-icons/go";
import AssignDevicePop from "./assignDevicePop";

const ControllersTable = ({
  devices,
  handleRowClick,
  // handleModalOpen,
  // handleDeleteModalOpen,
  handleAssignDevice,
}) => {
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "56vh",
    },
    indecatorBox: {
      mb: -1,
      pr: 2,
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    deviceModel: {
      fontSize: 13,
    },
    latestVersionText: {
      fontSize: 12,
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      width: "100%",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      // width: "100%",
    },
  };

  const [isPopOpen, setIsPopOpen] = useState(false);

  const handlePopClose = () => {
    handleAssignDevice();
    setIsPopOpen(false);
  };
  return (
    <>
      {devices?.length > 0 ? (
        <TableContainer {...styles.tableContainer}>
          <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
            <Thead>
              <Tr>
                <Th width="40%">NAME</Th>
                <Th width="12%">Type</Th>
                <Th width="12%">Model</Th>
                <Th width="12%">DATE ACQUIRED</Th>
                <Th width="12%">TESTED BY</Th>
                {/* <Th width="12%">DATE TESTED</Th> */}
                <Th width="12%">FIRMWARE VERSION</Th>
                <Th width="12%">CUSTOMER </Th>
              </Tr>
            </Thead>
            <Tbody>
              {devices?.map((device, index) => {
                return (
                  <Tr key={device?.id || index} style={{ cursor: "pointer" }}>
                    <Td onClick={() => handleRowClick(device)}>
                      <Flex>
                        <Center>
                          <Box {...styles.indecatorBox}>
                            <GoDotFill
                              color={
                                device?.status === "online" ? "green" : "red"
                              }
                            />
                          </Box>
                          <Box>
                            <Text {...styles.deviceName}>
                              {" "}
                              #{device?.serial_number}{" "}
                            </Text>
                            <Text {...styles.deviceModel}>
                              {device?.name == null
                                ? "unassigned"
                                : device?.name}{" "}
                            </Text>
                          </Box>
                        </Center>
                      </Flex>
                    </Td>
                    <Td onClick={() => handleRowClick(device)}>
                      <Text>{device?.device_type?.name}</Text>
                    </Td>
                    <Td onClick={() => handleRowClick(device)}>
                      <Text>{device?.device_model?.name}</Text>
                    </Td>
                    <Td onClick={() => handleRowClick(device)}>
                      {device?.purchase_date
                        ? moment(device?.purchase_date).format("DD-MM-YYYY")
                        : "UNSOLD"}
                    </Td>
                    <Td onClick={() => handleRowClick(device)}>
                      {device?.tested_by?.first_name}{" "}
                      {device?.tested_by?.last_name}
                    </Td>
                    {/* <Td onClick={() => handleRowClick(device)}>
                    {moment(device?.date_tested).format("DD-MM-YYYY")}
                  </Td> */}
                    <Td onClick={() => handleRowClick(device)}>
                      {device?.firmware_version}
                    </Td>
                    <Td>
                      {device?.assigned_to_customer_organization?.name ? (
                        <Text onClick={() => handleRowClick(device)}>
                          {device?.assigned_to_customer_organization?.name}
                        </Text>
                      ) : (
                        <AssignDevicePop
                          isOpen={isPopOpen}
                          handlePopClose={handlePopClose}
                          setIsPopOpen={setIsPopOpen}
                          deviceData={device}
                        />
                      )}
                    </Td>
                    <Td>
                      {/* <Button
                      isDisabled={device?.assigned_to_customer_organization?.id}
                      {...styles.actionButton}
                      onClick={() => {
                        handleModalOpen(device, true);
                      }}>
                      Modify
                    </Button> */}
                      {/* <DeleteIcon
                      w={8}
                      h={8}
                      mx={3}
                      color="red"
                      boxSize={6}
                      onClick={() => {
                        handleDeleteModalOpen(device);
                      }}
                    /> */}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text display="flex" justifyContent="center" p={4}>
          No device Data to Show
        </Text>
      )}
      <Flex gap={2} pt={2}>
        <Flex>
          <Center>
            <GoDotFill color={"green"} />
            Online
          </Center>
        </Flex>
        <Flex>
          <Center>
            <GoDotFill color={"red"} />
            Offline
          </Center>
        </Flex>
      </Flex>
    </>
  );
};

ControllersTable.propTypes = {
  devices: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  // handleDeleteModalOpen: PropTypes.func.isRequired,
  handleAssignDevice: PropTypes.func,
};

export default ControllersTable;
