/**
 * Used to Manage Devices for WG
 */
import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addDeviceAPI = createAsyncThunk(
  "addDevice",
  async (payload, { getState }) => {
    try {
      console.log("addDeviceAPI", getState().auth.token);

      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(`/seller/device`, payload, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getDeviceAPI = createAsyncThunk(
  "getDevice",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/device`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const updateDeviceAPI = createAsyncThunk(
  "updateDevice",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.put(
        `/seller/device/${payload?.device_id}`,
        payload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const assignDeviceAPI = createAsyncThunk(
  "assignDevice",
  async (payload, { getState }) => {
    try {
      console.log("assignDeviceAPI", getState().auth.token);

      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/assign-device`,
        {
          device_id: payload?.device_id,
          assigned_to_id: payload?.assigned_to_id,
        },
        config,
      );
      console.log("response", response?.data);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const addDeviceDataPointsAPI = createAsyncThunk(
  "addDeviceDataPointsAPI",
  async (payload, { getState }) => {
    try {
      console.log("addDeviceDataPointsAPI", getState().auth.token);

      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/device/${payload?.device_id}/data-points`,
        payload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getDeviceDataPointsAPI = createAsyncThunk(
  "getDeviceDataPointsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/seller/device/${payload?.device_id}/data-points`,
        config,
      );
      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const updateDeviceDataPointsAPI = createAsyncThunk(
  "updateDeviceDataPointsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.put(
        `/seller/device/${payload?.device_id}/data-points`,
        payload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteDeviceDataPointsAPI = createAsyncThunk(
  "deleteDeviceDataPointsAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
        data: payload,
      };

      let response = await API.delete(
        `/seller/device/${payload?.device_id}/data-points`,
        config,
      );

      console.log("object", payload);

      return response?.data;
    } catch (error) {
      console.log(error, "mnfbesvbjb");
      return error?.response?.data;
    }
  },
);
