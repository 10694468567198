import {
  Box,
  // Avatar,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  BiAtom,
  BiBuildings,
  BiDevices,
  // BiMedal,
  BiMicrochip,
} from "react-icons/bi";
import { MdBluetoothConnected, MdBuild, MdOutlineWifi } from "react-icons/md";
// import { FaBoxArchive } from "react-icons/fa6";
// import { useLocation } from "react-router-dom";
import { SettingsIcon } from "@chakra-ui/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteDeviceAPI,
  deviceConfigHistoryAPI,
} from "../redux/helpers/controllerAPI";
import {
  deleteDeviceDataPointsAPI,
  getDeviceAPI,
  getDeviceDataPointsAPI,
  updateDeviceDataPointsAPI,
} from "../redux/helpers/deviceAPI";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import AssignDevicePop from "./assignDevicePop";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceAttributesModal from "./editDeviceAttributesModal";
import EditDeviceModal from "./editDeviceModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

function DeviceDetails() {
  const dispatch = useDispatch();
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteDevice, setDeleteDevice] = useState();
  const [isDeleteDeviceModalOpen, setIsDeleteDeviceModalOpen] = useState(false);
  const deviceData = useSelector((state) => state?.controllers?.activeDevice);
  const currentDevice = useSelector((state) =>
    state?.devices?.devices?.filter((val) => val?.id === deviceData?.id),
  );
  const [deleteDataPoints, setDeleteDataPoints] = useState();

  console.log("isModalOpen", isModalOpen);

  const configHistory = useSelector(
    (state) => state?.controllers?.activeDeviceConfigHistory,
  );

  console.log("nnfn skvndkn", currentDevice);
  const navigate = useNavigate();

  useEffect(() => {
    const payload = {
      device_id: currentDevice?.length > 0 && currentDevice[0]?.id,
    };
    Promise.all([dispatch(deviceConfigHistoryAPI(payload))])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  console.log("configHistory", configHistory);
  console.log("activDevices: ", deviceData);

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "end",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
      mt: 1,
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    subheaderTextleft: {
      width: "30%",
      pl: 2,
      fontSize: { base: "10px", md: "14px" },
    },
    firmwareText: {
      fontSize: "10px",
      color: "#30C268",
      mt: "1",
    },
    greenIcon: {
      color: "#30C268",
    },
    readingsBox: {
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    readingsTextContainer: {
      alignItems: "center",
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
    readingsTopic: {
      pt: "2",
      fontSize: "sm",
      color: "#929FB1",
    },
    pressureReadingBox: {
      mt: "-2",
      borderRadius: 6,
      px: "4",
      py: "2",
      border: "1px",
      borderColor: "#E1E5EA",
      borderStyle: "dashed",
    },
    pressureReadingTextContainer: {
      alignItems: "center",
    },
    pressureReadingText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    pressureReadingTopic: {
      // pt: "2",
      fontSize: "16px",
      // color: "#929FB1",
      fontWeight: "medium",
    },
  };
  const [singleDevice, setSingleDevice] = useState(currentDevice[0]);
  console.log("bksdvj", singleDevice);
  const toast = useToast();
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const [dataPoints, setDataPoints] = useState([]);
  // const [singleDeviceModel, setSingleDeviceModel] = useState();
  const handleEditModelCloseModal = () => {
    setIsEditModelOpen(false);
  };

  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCloseDeviceDeleteModal = () => {
    setIsDeleteDeviceModalOpen(false);
  };

  const handleDeleteModalOpen = (val) => {
    val = { ...val, device_id: deviceData?.id };
    setIsDeleteModalOpen(true);
    setDeleteDataPoints(val);
  };

  const handleDeleteDeviceModalOpen = (val) => {
    setIsDeleteDeviceModalOpen(true);
    setDeleteDevice(val);
  };

  // const handleModifyClick = () => {
  //   setIsModalOpen(true);
  //   setSingleDevice({ ...currentDevice[0], update: true });
  // };

  const handleModalOpen = (device) => {
    const payload = {
      device_id: device?.id,
    };
    setLoading(true);
    dispatch(getDeviceDataPointsAPI(payload))
      .then((response) => {
        if (response?.payload?.success) {
          setDataPoints(response?.payload?.data);
          setLoading(false);
          setIsModalOpen(true);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    // setIsOpenedForModify(modify);
    // setIsModalOpen(false);
    device = { ...device, update: true, device_id: deviceData?.id };
    setSingleDevice(device);
  };

  const reloadDataForAccount = () => {
    // dispatch(getTypesAPI());
    // dispatch(getModelsAPI());
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);
      const resDeleteApi = dispatch(deleteDeviceDataPointsAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          handleCloseDeleteModal();
          dispatch(getDashboardDataAPI());
          dispatch(
            deviceConfigHistoryAPI({
              device_id: deviceData?.id,
            }),
          );
          dispatch(getDeviceAPI());
          setisSuccessModalOpen(true);
          // navigate(-1);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Monitoring Value",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSaveDeleteDevice = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteDevice", payload);
      const resDeleteDeviceApi = dispatch(deleteDeviceAPI(payload));
      resDeleteDeviceApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          handleCloseDeviceDeleteModal();
          dispatch(getDashboardDataAPI());
          // dispatch(
          //   deviceConfigHistoryAPI({
          //     device_id: deviceData?.id,
          //   }),
          // );
          dispatch(getDeviceAPI());
          setisSuccessModalOpen(true);
          navigate(-1);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Unit",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSaveDevice = (payload) => {
    setLoading(true);
    payload = { ...payload };
    console.log("onSaveDevModel payload", payload);

    let updateModelres = dispatch(updateDeviceDataPointsAPI(payload));
    updateModelres
      .then((data) => {
        console.log("updateModelres", data, currentDevice[0]?.serial_number);
        if (data?.payload?.success) {
          setSuccessMessage("Successfully updated device information");

          const updatedDeviceres = dispatch(
            getDeviceAPI({
              serial_number: currentDevice[0]?.serial_number,
              id: currentDevice[0]?.id,
            }),
          );
          updatedDeviceres.then((data) => {
            if (data?.payload?.success) {
              console.log("ACTIVE DEVICE", data?.payload?.data[0]);
              dispatch(setActiveDevice(data?.payload?.data[0]));
              dispatch(getDeviceAPI());
            }
          });
          dispatch(
            deviceConfigHistoryAPI({
              device_id: currentDevice?.length > 0 && currentDevice[0]?.id,
            }),
          );
          setisSuccessModalOpen(true);
          handleEditModelCloseModal();
          setLoading(false);
        } else {
          dispatch(getDeviceAPI());
          setLoading(false);
          toast({
            title: "Error",
            description: data?.payload?.message || data?.payload?.error,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  // const handleEditModelOpen = (device) => {
  //   setIsEditModelOpen(true);
  //   setSingleDeviceModel(device);
  // };

  return (
    <>
      <Loader loading={loading} />
      <Card
        size={{ base: "sm", md: "md" }}
        style={{ display: "flex" }}
        key={currentDevice?.length > 0 && currentDevice[0]?.id}>
        <CardBody>
          <Flex justifyContent={"space-between"}>
            <Box width="50%">
              <Flex gap={2}>
                <Heading {...styles.deviceHeader}>
                  {currentDevice?.length > 0 && currentDevice[0]?.name}
                </Heading>
                <MdOutlineWifi
                  color={
                    currentDevice?.length > 0 &&
                    currentDevice[0]?.status === "online"
                      ? "#30C268"
                      : "red"
                  }
                />
                <MdBluetoothConnected
                  color={
                    currentDevice?.length > 0 &&
                    currentDevice[0]?.status === "online"
                      ? "#30C268"
                      : "red"
                  }
                />
              </Flex>
              <Flex {...styles.subheaderItems}>
                <BiDevices boxsize={1} />
                <Text {...styles.subheaderTextleft}>Serial No</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0 && currentDevice[0]?.serial_number}
                </Text>
              </Flex>

              {currentDevice?.length > 0 &&
                currentDevice[0]?.assigned_to_seller && (
                  <Flex {...styles.subheaderItems}>
                    <BiBuildings boxsize={1} />
                    <Text {...styles.subheaderTextleft}>Customer</Text>
                    <Text {...styles.subheaderText}>
                      :{" "}
                      {currentDevice?.length > 0 &&
                      currentDevice[0]?.assigned_to_customer_organization?.name
                        ? currentDevice?.length > 0 &&
                          currentDevice[0]?.assigned_to_customer_organization
                            ?.name
                        : "Unassigned"}
                    </Text>
                  </Flex>
                )}

              <Flex {...styles.subheaderItems}>
                <BiAtom boxsize={1} />
                <Text {...styles.subheaderTextleft}>Type</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0 &&
                    currentDevice[0]?.device_type?.name}
                </Text>
              </Flex>

              <Flex {...styles.subheaderItems}>
                <BiMicrochip boxsize={1} />
                <Text {...styles.subheaderTextleft}>Model</Text>
                <Text {...styles.subheaderText}>
                  :{" "}
                  {currentDevice?.length > 0 &&
                    currentDevice[0]?.device_model?.name}
                </Text>
              </Flex>

              {isMobile && (
                <Flex {...styles.subheaderItems}>
                  <MdBuild />
                  <Text {...styles.subheaderText}>
                    {currentDevice?.length > 0 &&
                      currentDevice[0]?.firmware?.version_number}{" "}
                  </Text>
                  {currentDevice?.length > 0 &&
                  currentDevice[0]?.firmware?.version_number === "4.0" ? (
                    <Text fontSize={10} color={"#30C268"} pl={6}>
                      Latest
                    </Text>
                  ) : (
                    <Text fontSize={10} color={"blue"} pl={6}>
                      Update
                    </Text>
                  )}
                </Flex>
              )}
            </Box>

            <Flex
              flexDirection={"col"}
              fill={"100%"}
              justifyContent={"flex-end"}
              alignSelf={"center"}
              display={"flex"}>
              <Box display="flex" flexDirection="column" gap={2}>
                {currentDevice?.length > 0 &&
                  !currentDevice[0]?.assigned_to_customer_organization
                    ?.name && (
                    <>
                      <AssignDevicePop deviceData={currentDevice[0]} />
                      <Button
                        variant="outline"
                        width="10rem"
                        colorScheme="black"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModalOpen(currentDevice[0], true);
                        }}>
                        Modify
                      </Button>
                      <Button
                        width="10rem"
                        variant="outline"
                        colorScheme="red"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteDeviceModalOpen(currentDevice[0]);
                        }}>
                        Delete
                      </Button>
                    </>
                  )}
              </Box>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
      <Flex gap={4}>
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH={"30vh"}
          overflow={"auto"}
          width={"50%"}>
          <CardHeader>
            <Flex justifyContent={"space-between"}>
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase">
                Current Configuration
              </Text>
              <Button
                size={"xs"}
                colorScheme="teal"
                color="white"
                isDisabled={
                  currentDevice[0]?.assigned_to_customer_organization?.name
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalOpen(currentDevice[0], true);
                  // console.log("modelsData[0]", modelsData[0]);
                  // console.log("deviceData", currentDevice[0]);
                }}>
                Modify
                <SettingsIcon
                  w={4}
                  h={4}
                  color="white"
                  marginLeft={2}
                  boxSize={3}
                />
              </Button>
            </Flex>
          </CardHeader>
          <CardBody mt={0} pt={0}>
            {/* {deviceData?.data_points?.map((dataPt) => {
              return (
                <RangeSlider
                  display={"none"}
                  isReadOnly
                  key={dataPt.minimum}
                  min={-100}
                  max={200}
                  aria-label={["min", "max"]}
                  defaultValue={[dataPt?.minimum, dataPt?.maximum]}
                >
                  <RangeSliderTrack bg="red.100">
                    <RangeSliderFilledTrack bg="tomato" />
                  </RangeSliderTrack>

                  <Tooltip
                    display={"none"}
                    hasArrow
                    bg="teal.500"
                    color="white"
                    placement="top"
                    isOpen={true}
                    //label={dataPt?.minimum}
                    label={`${dataPt?.minimum}` + `${dataPt?.unit?.unit}`}
                  >
                    <RangeSliderThumb boxSize={6} index={0}>
                      <Box color="tomato" as={MdGraphicEq} />
                    </RangeSliderThumb>
                  </Tooltip>

                  <Tooltip
                    display={"none"}
                    hasArrow
                    bg="teal.500"
                    color="white"
                    placement="top"
                    isOpen={true}
                    //label={dataPt?.minimum}
                    label={`${dataPt?.maximum}` + `${dataPt?.unit?.unit}`}
                  >
                    <RangeSliderThumb boxSize={6} index={1}>
                      <Box color="tomato" as={MdGraphicEq} />
                    </RangeSliderThumb>
                  </Tooltip>
                </RangeSlider>
              );
            })} */}

            <SimpleGrid
              minChildWidth="60px"
              mt={2}
              justifyContent="space-between">
              <Text {...styles.pressureReadingTopic} fontSize={"sm"}>
                Monitoring Values
              </Text>
              <Text
                {...styles.pressureReadingText}
                fontWeight={"normal"}
                justifyContent="flex-end"
                display="flex"
                fontSize={"sm"}>
                min
              </Text>
              <Text
                {...styles.pressureReadingText}
                fontWeight={"normal"}
                justifyContent="flex-end"
                display="flex"
                fontSize={"sm"}>
                max
              </Text>
            </SimpleGrid>

            {currentDevice?.length > 0 &&
              currentDevice[0]?.data_points?.map((dataPt, index) => {
                return (
                  <Box key={dataPt?.id || index}>
                    <SimpleGrid
                      minChildWidth="60px"
                      spacing={4}
                      mt={2}
                      justifyItems="space-between">
                      <Text {...styles.pressureReadingTopic} fontSize={"sm"}>
                        {dataPt?.unit?.type}
                      </Text>

                      <Text
                        {...styles.pressureReadingText}
                        justifyContent="flex-end"
                        display="flex">
                        {dataPt?.minimum} {dataPt?.unit?.unit}
                      </Text>
                      <Text
                        {...styles.pressureReadingText}
                        justifyContent="flex-end"
                        display="flex">
                        {dataPt?.maximum} {dataPt?.unit?.unit}
                      </Text>
                    </SimpleGrid>
                  </Box>
                );
              })}
          </CardBody>
        </Card>
        <Card
          borderRadius={3}
          size={{ base: "sm", md: "md" }}
          maxH={"30vh"}
          overflow={"auto"}
          width={"50%"}>
          <CardHeader>
            <Flex justifyContent={"space-between"}>
              <Text
                fontFamily="'Poppins', sans-serif"
                fontWeight={600}
                fontSize={14}
                fontStyle="normal"
                letterSpacing=".05em"
                textTransform="uppercase">
                Threshold Revisions{" "}
                {configHistory?.length ? `(${configHistory?.length})` : null}
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            {configHistory?.map((configData, index) => (
              <Box key={configData?.id || index}>
                <SimpleGrid
                  minChildWidth="60px"
                  // spacing={4}
                  // mt={2}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between">
                  <Flex direction="column">
                    <Text {...styles.pressureReadingTopic} fontSize={"sm"}>
                      {configData?.author?.email}
                    </Text>
                    <Text {...styles.timeStamp} fontSize={"xs"}>
                      {moment(configData?.revision_timestamp).format(
                        "YYYY-MM-DD HH:mm:ss",
                      )}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      {...styles.pressureReadingText}
                      fontWeight={"normal"}
                      justifyContent="flex-end"
                      display="flex"
                      fontSize={"sm"}>
                      min
                    </Text>
                    <Text {...styles.pressureReadingText}>
                      {configData?.minimum} {configData?.unit?.unit}
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text
                      {...styles.pressureReadingText}
                      fontWeight={"normal"}
                      justifyContent="flex-end"
                      display="flex"
                      fontSize={"sm"}>
                      max
                    </Text>
                    <Text {...styles.pressureReadingText}>
                      {configData?.maximum} {configData?.unit?.unit}
                    </Text>
                  </Flex>
                </SimpleGrid>
                <Divider />
              </Box>
            ))}
          </CardBody>
        </Card>
      </Flex>
      {isEditModelOpen && (
        <EditDeviceAttributesModal
          isOpen={isEditModelOpen}
          onClose={handleEditModelCloseModal}
          setIsModalOpen={setIsEditModelOpen}
          onSave={onSaveDevice}
          // device={singleDeviceModel}
        />
      )}
      {isModalOpen && (
        <EditDeviceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          device={singleDevice}
          reloadDataForAccount={reloadDataForAccount}
          setisSuccessModalOpen={setisSuccessModalOpen}
          dataPoints={dataPoints}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={deleteDataPoints}
          onSave={onSaveDeleteModel}
        />
      )}
      {isDeleteDeviceModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteDeviceModalOpen}
          onclose={handleCloseDeviceDeleteModal}
          type="device"
          device={deleteDevice}
          onSave={onSaveDeleteDevice}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`${successMessage}!`}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
}

export default DeviceDetails;
