import React from "react";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import moment from "moment";

export default function FirmwareTable({ data, handleModalOpen }) {
  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    firmwareName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
  };
  return (
    <TableContainer {...styles.tableContainer}>
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width={"20%"}>NAME</Th>
            <Th>VERSION NUMBER</Th>
            <Th>RELEASE DATE</Th>
            <Th>MODEL</Th>
            <Th width={"10%"}>ACTIONs</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((firmware, index) => {
            return (
              <Tr key={index}>
                <Td>
                  <Text {...styles.firmwareName}>{firmware?.name}</Text>
                </Td>
                <Td>{firmware?.version_number}</Td>

                <Td>
                  {moment(firmware?.release_date).format("YYYY-MM-DD HH:mm:ss")}
                </Td>
                <Td>
                  <Text>{firmware?.device_model?.name}</Text>
                </Td>
                <Td>
                  <Button
                    {...styles.actionButton}
                    onClick={() => {
                      handleModalOpen(firmware);
                    }}>
                    Modify
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

FirmwareTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleModalOpen: PropTypes.func,
};
