import { useSelector } from "react-redux";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout";
import Controllers from "../screens/controllers";
import Dashboard from "../screens/dashboard";
import Device from "../screens/device";
import Firmware from "../screens/firmware";
import ForgotPassword from "../screens/forgotPassword";
import Login from "../screens/login";
import Manufacturers from "../screens/manufacturers";
import NotFound from "../screens/notFound";
import Settings from "../screens/settings";
import Signup from "../screens/signup";
import Team from "../screens/team";
// import Vendors from "../screens/vendors";
// import Requests from "../screens/requests";
import Types from "../screens/controllerTypesScreen";
import Models from "../screens/controllerModelsScreen";
import Customer from "../screens/customer";
import CustomersList from "../screens/customersList";
import Onboarding from "../screens/onboarding";
import ControllerDevices from "../screens/controllerDevices";
import ResetPassword from "../screens/resetPassword";
import AssignDeviceToCustomerPop from "../screens/assignDeviceToCustomerPop";
import Profile from "../screens/profile";

export default function Navigations() {
  const authState = useSelector((state) => state?.auth?.is_authenticated);

  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      Component: authState && authState ? Layout : Login,
      children: [
        {
          path: "/onboarding",
          Component: Onboarding,
        },
        {
          path: "/dashboard",
          Component: Dashboard,
        },
        {
          path: "/units",
          Component: Controllers,
        },
        {
          path: "/products",
          Component: Types,
        },
        {
          path: "/products/models",
          Component: Models,
        },
        {
          path: "/products/models/devices",
          Component: ControllerDevices,
        },
        {
          path: "/products/models/devices/device",
          Component: Device,
        },
        {
          path: "/units/device",
          Component: Device,
        },
        {
          path: "/firmware",
          Component: Firmware,
        },
        {
          path: "/customers",
          Component: CustomersList,
        },
        {
          path: "/customers/:organization-name/",
          Component: Customer,
        },
        {
          path: "/customers/:organization-name/assign-device",
          Component: AssignDeviceToCustomerPop,
        },
        {
          path: "/manufacturers",
          Component: Manufacturers,
        },
        // {
        //   path: "/vendors",
        //   Component: Vendors,
        // },
        // {
        //   path: "/requests",
        //   Component: Requests,
        // },
        {
          path: "/team",
          Component: Team,
        },
        {
          path: "/settings",
          Component: Settings,
        },
        {
          path: "/profile",
          Component: Profile,
        },
      ],
    },
    {
      path: "/login",
      Component: Login,
    },
    {
      path: "/signup",
      Component: Signup,
    },
    {
      path: "/reset/password",
      Component: ResetPassword,
    },
    {
      path: "/forgot-password",
      Component: ForgotPassword,
    },
    {
      path: "*",
      Component: NotFound,
    },
  ]);
  return router;
}
