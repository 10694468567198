import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ModelFooterButtons from "./modelFooterButtons";
import ModelsTriggerButton from "./modelsTriggerButton";

import * as Yup from "yup";
import { useSelector } from "react-redux";
import {
  addDeviceAPI,
  assignDeviceAPI,
  getDeviceAPI,
} from "../redux/helpers/deviceAPI";
import { getCustomerAPI } from "../redux/helpers/customerAPI";
import SuccessMessageModal from "./successMessageModal";
import { Field, Formik } from "formik";
import Loader from "./loader";

const AddDeviceModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  device,
  handleDevTypeOpen,
  handleDevModelOpen,
  isModify,
  reloadDataForAccount,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [device_type, setDeviceType] = useState(null);
  const [device_model, setModelSelected] = useState(null);
  const [customer_id, setAssignCustomer] = useState([]);

  const [currentModels, setCurrentModels] = useState([]);

  const [provisioningStatus, setProvisioningStatus] = useState(device?.status);
  const [generateSerialNo, setGenerateSerialNo] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const types = useSelector((state) => state?.controllers?.types);
  const models = useSelector((state) => state?.controllers?.models);
  const customerOrgList = useSelector((state) => state.customer.organizations);
  const [isAssignToCustomer, setIsAssignToCustomer] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    onTypeSelected();
    Promise.all([dispatch(getCustomerAPI())])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (device_type) {
      let currentModelsSelected = models?.filter(
        (model) => model.device_type.id == device_type
      );
      setCurrentModels(currentModelsSelected);
    }
  }, [models]);

  const onTypeSelected = (typeID) => {
    setDeviceType(typeID);
    let currentModelsSelected = models?.filter(
      (model) => model.device_type.id == typeID
    );
    setCurrentModels(currentModelsSelected);
  };

  const onModelSelected = (model) => {
    setModelSelected(model);
  };

  const onCustomerSelected = (customer) => {
    setAssignCustomer(customer);
  };

  const assignDeviceToCustomer = () => {
    const deviceReq = dispatch(getDeviceAPI());
    deviceReq.then((result) => {
      const device = result.payload.data;
      const device_data_id = device?.filter((val) => {
        return val?.serial_number == serialNo;
      });
      const payload = {
        device_id: device_data_id[0]?.id,
        assigned_to_id: customer_id,
      };
      dispatch(assignDeviceAPI(payload));
    });
  };

  // Reload Data for account

  /**
   * 1. reload devices types
   * 2. reload device models
   * 3. reload devices
   */

  // const reloadDataForAccount = () => {
  //   dispatch(getTypesAPI(token));
  //   dispatch(getDeviceAPI(token));
  //   dispatch(getModelsAPI(token));
  // }

  // End Reload Data for account
  const nameRegex = /^[a-zA-Z0-9\s]+$/;
  const formValidationSchema = Yup.object().shape({
    device_name: Yup.string()
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Device name can only contain Letters, Numbers & Spaces"
      )
      .required("Device name is required"),
    device_type: Yup.string().required("Device type is required"),
    device_model: Yup.string().required("Device model is required"),
    serial_no: Yup.string().required("Serial number is required"),
    provisioning_status: Yup.boolean(),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <Loader loading={loading} />
      <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Unit" />
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {isModify ? "Modify" : "Add "} Unit</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                device_name: "",
                device_type: "",
                device_model: "",
                serial_no: "",
                provisioning_status: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => {
                try {
                  const data = {
                    name: values?.device_name,
                    type_id: device_type,
                    model_id: device_model,
                    provisioningStatus: values?.provisioning_status,
                    serial_number: values?.serial_no,
                  };

                  const addDeviceReq = dispatch(addDeviceAPI(data));
                  addDeviceReq.then((data) => {
                    if (data?.payload?.success) {
                      if (isAssignToCustomer) {
                        assignDeviceToCustomer();
                      }
                      setSuccessMessage("added");
                      setIsAssignToCustomer(false);
                      setGenerateSerialNo(null);
                      onClose();
                      setisSuccessModalOpen(true);
                    } else {
                      toast({
                        title: "Error while adding Controller",
                        description: data?.payload?.message,
                        status: "error",
                        duration: 6000,
                        isClosable: true,
                      });
                    }
                  });
                } catch (err) {
                  console.log("errror");
                }
              }}
            >
              {({ handleSubmit, errors, touched, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.serial_no && touched.serial_no}
                  >
                    <FormLabel> Device Serial No </FormLabel>
                    <Checkbox
                      name="generateSerialNo"
                      colorScheme="green"
                      isChecked={generateSerialNo}
                      onChange={(e) => {
                        let slNo = Date.now();
                        setFieldValue("serial_no", slNo);
                        setSerialNo(slNo);
                        setGenerateSerialNo(e.target.checked);
                      }}
                    >
                      Auto Generate Unique Serial No
                    </Checkbox>
                    <Field
                      as={Input}
                      {...styles.input}
                      style={{ marginTop: 10 }}
                      ref={initialRef}
                      placeholder="Serial No"
                      disabled={generateSerialNo}
                      id="serial_no"
                      name="serial_no"
                    />
                    <FormErrorMessage>{errors.serial_no}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_name && touched.device_name}
                  >
                    <FormLabel>Name</FormLabel>
                    <Field
                      as={Input}
                      {...styles.input}
                      placeholder="Device name"
                      name="device_name"
                      id="device_name"
                    />
                    <FormErrorMessage>{errors.device_name}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_type && touched.device_type}
                  >
                    <FormLabel>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        Product
                        <Button
                          size={"xs"}
                          type="primary"
                          onClick={() => handleDevTypeOpen()}
                        >
                          <Text
                            textAlign={"end"}
                            fontSize={12}
                            color={"#0071FF"}
                          >
                            New Product +
                          </Text>
                        </Button>
                      </Box>
                    </FormLabel>
                    <Select
                      {...styles.input}
                      id="device_type"
                      name="device_type"
                      placeholder="Select Type"
                      onChange={(event) => {
                        setFieldValue("device_type", event.target.value);
                        onTypeSelected(event.target.value);
                      }}
                    >
                      {types?.map((val, index) => {
                        return (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.device_model && touched.device_model}
                  >
                    <FormLabel>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        Model
                        <Button
                          size={"xs"}
                          type="primary"
                          onClick={() => {
                            handleDevModelOpen();
                          }}
                        >
                          <Text
                            textAlign={"end"}
                            fontSize={12}
                            color={"#0071FF"}
                          >
                            New Model +
                          </Text>
                        </Button>
                      </Box>
                    </FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select Type"
                      id="device_model"
                      name="device_model"
                      onChange={(event) => {
                        setFieldValue("device_model", event.target.value);
                        onModelSelected(event.target.value);
                      }}
                    >
                      {currentModels?.map((val, index) => {
                        return (
                          <option key={index} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>{errors.device_model}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={
                      errors.assign_Customer && touched.assign_Customer
                    }
                  >
                    <Checkbox
                      name="assign_Customer"
                      colorScheme="green"
                      isChecked={isAssignToCustomer}
                      onChange={(e) => setIsAssignToCustomer(e.target.checked)}
                    >
                      Assign to Customers?
                    </Checkbox>
                    {isAssignToCustomer && (
                      <Select
                        marginTop={"10px"}
                        {...styles.input}
                        placeholder="Select Customer"
                        id="assign_Customer"
                        name="assign_Customer"
                        onChange={(event) =>
                          onCustomerSelected(event.target.value)
                        }
                      >
                        {customerOrgList?.map((val, index) => {
                          return (
                            <option key={index} value={val.id}>
                              {val.name}
                            </option>
                          );
                        })}
                      </Select>
                    )}
                    <FormErrorMessage>
                      {errors.assign_Customer}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    {...styles.formItem}
                    isInvalid={
                      errors.provisioning_status && touched.provisioning_status
                    }
                  >
                    <FormLabel>Provision Status </FormLabel>
                    <Checkbox
                      name="provisioning_status"
                      id="provisioning_status"
                      colorScheme="green"
                      isChecked={provisioningStatus}
                      onChange={(e) => setProvisioningStatus(e.target.checked)}
                    >
                      Provisioned
                    </Checkbox>
                    <FormErrorMessage>
                      {errors.provisioning_status}
                    </FormErrorMessage>
                  </FormControl>
                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={`Device ${successMessage} successfully!`}
          onClose={() => {
            setisSuccessModalOpen(false);
            reloadDataForAccount();
          }}
        />
      )}
    </>
  );
};

AddDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  handleDevTypeOpen: PropTypes.func.isRequired,
  handleDevModelOpen: PropTypes.func.isRequired,
  isModify: PropTypes.bool.isRequired,
  reloadDataForAccount: PropTypes.func,
};
export default AddDeviceModal;
