import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
} from "@chakra-ui/react";
import React from "react";
import PropTypes from "prop-types";
import animationData from "../assets/animations/welcome-message.json";
import Lottie from "react-lottie";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const FirstTimeUserOnboarding = ({
  isOpen,
  onClose,
  activeStep,
  steps,
  onNext,
  onPrevious,
}) => {
  const roles = useSelector((state) => state?.auth?.roles);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const AddTeamValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRegex, "First Name must contain only letters and spaces")
      .required("First Name is required")
      .max(36, "First Name must be at most 36 characters long"),
    last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    phone_number: Yup.string()
      .matches(/^[0-9]{6,14}$/, "Enter valid Phone Number (e.g., +81-1223-333)")
      .required("Phone Number is required"),
    privilege: Yup.string().required("Privilege is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stepper index={activeStep}>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{step.title}</StepTitle>
                  </Box>

                  <StepSeparator />
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <Lottie options={defaultOptions} height={200} width={200} />
            )}
            {activeStep === 1 && <Text>Step 2</Text>}
            {activeStep === 2 && (
              <Formik
                initialValues={{
                  email: "",
                  first_name: "",
                  last_name: "",
                  phone_number: "",
                  privilege: "",
                }}
                validationSchema={AddTeamValidationSchema}
                onSubmit={(values) => {
                  console.log(values);
                  // onSave(values);
                }}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  errors,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormControl
                      isInvalid={errors.first_name && touched.first_name}>
                      <FormLabel>First Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="first_name"
                        name="first_name"
                        type="text"
                        placeholder="john"
                      />
                      <FormErrorMessage>{errors?.first_name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.last_name && touched.last_name}>
                      <FormLabel>Last Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="last_name"
                        name="last_name"
                        type="text"
                        placeholder="Doe"
                      />
                      <FormErrorMessage>{errors?.last_name}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.phone_number && touched.phone_number}>
                      <FormLabel>Phone Number</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="phone_number"
                        name="phone_number"
                        type="tel"
                        placeholder="+81-1223-333"
                      />
                      <FormErrorMessage>
                        {errors?.phone_number}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.email && touched.email}>
                      <FormLabel>Email</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="john_doe@example.com"
                      />
                      <FormErrorMessage>{errors?.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.privilege && touched.privilege}>
                      <FormLabel>Privilege</FormLabel>
                      <Select
                        {...styles.input}
                        placeholder="Select option"
                        name="privilege"
                        defaultValue={values.privilege}
                        onChange={(e) => {
                          handleChange(e.target.value);
                          console.log("e.target.value", e.target.value);
                          setFieldValue("privilege", e.target.value);
                        }}>
                        {roles.map((role) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                    </FormControl>
                  </form>
                )}
              </Formik>
            )}
          </ModalBody>
          <ModalFooter>
            {activeStep !== 0 && (
              <Button
                onClick={onPrevious}
                disabled={activeStep === 0}
                size={"sm"}
                mr={2}>
                Previous
              </Button>
            )}

            <Button
              onClick={onNext}
              disabled={activeStep === steps.length - 1}
              size={"sm"}>
              Next
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
FirstTimeUserOnboarding.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};
export default FirstTimeUserOnboarding;
