import React, { useState } from "react";
import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import AddTeamMemberModal from "./addTeamMemberModal";
import { useDispatch } from "react-redux";
import { editMemberAPI } from "../redux/helpers/teamAPI";
import SuccessMessageModal from "./successMessageModal";
import Loader from "./loader";

export default function TeamMembersTable({
  enableActions,
  teamMembers,
  refreshTeamMembers,
}) {
  const dispatch = useDispatch();
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedTeamMember(null);
  };

  const handleModifyClick = (teamMember) => {
    console.log("teamMember", teamMember, enableActions);

    if (enableActions === false) {
      alert("Owner of the account can modify team members");
    } else {
      // alert ('not handled ')
      setSelectedTeamMember(teamMember);
      setIsModalOpen(true);
    }
  };

  const onSave = async (payload) => {
    console.log("onSave payload", payload);
    payload.member_id = selectedTeamMember?.id;
    setLoading(true);
    try {
      const editMemberReq = dispatch(editMemberAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success === true) {
            refreshTeamMembers();
            handleCloseModal();
            setisSuccessModalOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const styles = {
    tableContainer: {
      overflowY: "auto",
      h: "60vh",
    },
    teamMemberName: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    actionButton: {
      colorScheme: "gray",
      variant: "outline",
      size: "sm",
      border: "1px",
      px: 6,
      borderColor: "#2b3642",
      width: "100%",
    },
  };
  return (
    <TableContainer {...styles.tableContainer}>
      <Loader loading={loading} />
      <Table size={"sm"} variant="striped" colorScheme="blackAlpha">
        <Thead>
          <Tr>
            <Th width="30%">FIRST NAME</Th>
            <Th width="30%">LAST NAME </Th>
            <Th width="20%">EMAIL</Th>
            <Th width="20%">PHONE</Th>
            <Th width="15%">PRIVILEGE</Th>
            <Th width="15%">STATUS</Th>
            <Th width="15%" visibility={enableActions}>
              ACTIONS
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {teamMembers?.map((teamMember, index) => {
            return (
              <Tr key={index}>
                <Td {...styles.teamMemberName}>{teamMember.first_name}</Td>
                <Td {...styles.teamMemberName}>{teamMember.last_name}</Td>
                <Td>{teamMember.email}</Td>
                <Td>{teamMember.phone_number}</Td>
                <Td>{teamMember.privilege} </Td>
                <Td>
                  {" "}
                  {teamMember.is_active == true ? "Active" : "Deactivated"}{" "}
                </Td>
                <Td>
                  <Button
                    {...styles.actionButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleModifyClick(teamMember);
                    }}
                    disabled={enableActions}
                    // display={enableActions ? 'flex': 'none'}
                  >
                    Modify
                  </Button>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {isModalOpen && (
        <AddTeamMemberModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSave}
          teamMember={selectedTeamMember}
          type={`Edit "${selectedTeamMember?.first_name} ${selectedTeamMember?.last_name}"`}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Team member details updated successfully!"}
          onClose={() => {
            setisSuccessModalOpen(false);
            window.location.reload();
          }}
        />
      )}
    </TableContainer>
  );
}

TeamMembersTable.propTypes = {
  teamMembers: PropTypes.array.isRequired,
  enableActions: PropTypes.array.isRequired,
  refreshTeamMembers: PropTypes.func,
};
