import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestsData: [
    {
      time: "10:00 Am 26 Jan 2023",
      teamMember: "Team Member 2",
      email: "example@gmail.com",
      phone: "8985597889",
      request: "New Device Type",
      deviceType: "Type 11",
      status: "",
      statusUpdated: "",
    },
    {
      time: "10:00 Am 26 Jan 2023",
      teamMember: "Team Member 1",
      email: "example@gmail.com",
      phone: "8985597889",
      request: "New Device Type",
      deviceType: "Type 10",
      status: "Approved",
      statusUpdated: "11:45 AM 26 JAN 2023",
    },
    {
      time: "10:00 Am 26 Jan 2023",
      teamMember: "Team Member 1",
      email: "example@gmail.com",
      phone: "8985597889",
      request: "New Device Model",
      deviceType: "Type 10",
      status: "Declined",
      statusUpdated: "11:45 AM 26 JAN 2023",
    },
    {
      time: "10:00 Am 26 Jan 2023",
      teamMember: "Team Member 1",
      email: "example@gmail.com",
      phone: "8985597889",
      request: "New Manufacturer",
      manufacturer: "Manufacturer 11",
      manufacturerEmail: "email@gmail.com",
      manufacturerPhone: "8985594495",
      status: "Declined",
      statusUpdated: "11:45 AM 26 JAN 2023",
    },
  ],
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default requestsSlice.reducer;
