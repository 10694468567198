import { createSlice } from "@reduxjs/toolkit";
import { getDashboardDataAPI } from "../helpers/summaryAPI";

const initialState = {
  total_devices_count: 0,
  active_devices_count: 0,
  alarms_detected_count: 0,
  recently_added_devices: [],
  recently_updated_devices: [],
  recently_triggered_alarms: [],
  recently_added_customers: [],
  recently_updated_customers: [],
  total_devices_sold: 0,
};

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    resetStateSummary: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardDataAPI.fulfilled, (state, action) => {
        state.total_devices_count = action.payload?.data?.total_devices_added;
        state.active_devices_count =
          action.payload?.data?.total_active_devices == 0
            ? 0
            : action?.payload?.data?.total_active_devices;
        state.recently_added_devices =
          action.payload?.data?.recent_devices_added;
        state.recently_updated_devices =
          action.payload?.data?.recent_devices_updated;
        state.team_members = action.payload?.data?.recent_customers_added;
        state.total_devices_sold = action.payload?.data?.total_devices_sold;
        state.recently_added_customers =
          action.payload?.data?.recent_customers_added;
        state.recently_updated_customers =
          action.payload?.data?.recent_customers_updated;

        state.error = null;
      })
      .addCase(getDashboardDataAPI.rejected, (state, action) => {
        state.error = action?.payload?.error;
      });
  },
});

export const {resetStateSummary} = summarySlice.actions

export default summarySlice.reducer;
