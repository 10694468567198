import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import { useDispatch } from "react-redux";
import { sendPassResetLinkAPI } from "../redux/helpers/authAPI";
import SuccessMessageModal from "../components/successMessageModal";
import Loader from "../components/loader";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  // const secureCode = useSelector((state) => state?.auth?.secureCode);

  // const [codeTriggered, setCodeTriggered] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);

  const [currentEmailValue, setCurrentEmailValue] = useState("");

  // const handleClick = () => {
  //   if (!codeTriggered) {
  //     setTimeout(() => {
  //       dispatch(sendSecureCode(currentEmailValue));
  //     }, 2000);

  //     if (error === "No account found with the provided email address.") {
  //       toast({
  //         title: "User does't exist",
  //         description: "No account found with the provided email address.",
  //         status: "error",
  //         duration: 6000,
  //         isClosable: true,
  //       });
  //       dispatch(clearMessage());
  //     } else setCodeTriggered(true);
  //   }
  // };

  const [showPassword, setShowPassword] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient: "linear(to-br, #7928CA, #0762ef)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
      textAlign: "center",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    input: {
      size: { base: "sm", md: "md" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      marginTop: 2,
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };

  const resetValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(254, "Email must be at most 254 characters.")
      .required("Email is required"),
  });

  return (
    <Flex {...styles.background}>
      <Loader loading={loading} />
      <Flex {...styles.topContainer}>
        {codeVerified ? (
          <Stack {...styles.stackItems}>
            <Heading {...styles.header}>Set New Password</Heading>

            <Box {...styles.formContainer}>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                  rememberMe: false,
                }}
                validationSchema={resetValidationSchema}
                onSubmit={(values) => {
                  const payload = {
                    password: values?.password,
                    email: currentEmailValue,
                  };
                  console.log(payload);
                  setCodeVerified(false);
                }}>
                {({ handleSubmit, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <FormControl
                        isInvalid={errors?.password && touched?.password}>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                          />

                          <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>{errors?.password}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={
                          errors?.confirmPassword && touched?.confirmPassword
                        }>
                        <FormLabel htmlFor="confirmPassword">
                          Confirm Password
                        </FormLabel>
                        <InputGroup>
                          <Field
                            as={Input}
                            {...styles.input}
                            id="confirmPassword"
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirm your password"
                          />

                          <InputRightElement onClick={handleShowClick}>
                            <ViewIcon />
                          </InputRightElement>
                        </InputGroup>

                        <FormErrorMessage>
                          {errors?.confirmPassword}
                        </FormErrorMessage>
                      </FormControl>

                      <Button {...styles.loginButton} type="submit">
                        Reset
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </Box>
          </Stack>
        ) : (
          <Stack {...styles.stackItems}>
            <Heading {...styles.header}>Forgot Password</Heading>
            <Text {...styles.description}>
              Please enter your registered email to recive password reset link
              to mail
            </Text>
            <Box {...styles.formContainer}>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={() => {
                  const payload = {
                    email: currentEmailValue,
                  };
                  setLoading(true);
                  try {
                    let sendPassResetLinkRes = dispatch(
                      sendPassResetLinkAPI(payload),
                    );
                    sendPassResetLinkRes
                      .then((data) => {
                        if (data.payload?.success) {
                          setLoading(false);
                          setSuccessMessage(data?.payload?.message);
                          setisSuccessModalOpen(true);
                        } else {
                          setLoading(false);
                          toast({
                            title: "Error",
                            description: data.payload.message,
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((err) => {
                        setLoading(false);
                        console.log(err);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log(err);
                  }
                }}>
                {({ handleSubmit, handleChange, errors, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <VStack {...styles.formStack}>
                      <FormControl isInvalid={errors?.email && touched?.email}>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="email"
                          name="email"
                          type="email"
                          // disabled={codeTriggered}
                          onChange={(e) => {
                            handleChange(e);
                            setCurrentEmailValue(e.target.value);
                          }}
                          placeholder="Enter your email"
                        />

                        <FormErrorMessage>{errors?.email}</FormErrorMessage>
                      </FormControl>

                      <Button {...styles.loginButton} type="submit">
                        Send Password Reset Link
                      </Button>
                    </VStack>
                  </form>
                )}
              </Formik>
            </Box>
          </Stack>
        )}

        <Box {...styles.footerText}>
          Already have an account?{" "}
          <Link {...styles.linkColor} onClick={() => navigate("/login")}>
            Login
          </Link>
        </Box>
      </Flex>
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </Flex>
  );
}
