import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Congratulations from "../assets/images/congratulations.jpg";
import ModalBackground from "../assets/images/modalBackground.jpg";

const DeleteDeviceModal = ({ isOpen, onclose, onSave, device, type }) => {
  const styles = {
    sideimage: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgImage: Congratulations,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    sideblank: {
      justifyContent: "center",
      alignItems: "center",
      w: "10%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    main: {
      justifyContent: "center",
      alignItems: "center",
      w: "80%",
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    row: {
      marginY: "5%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    listrow: {
      marginY: "2%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    listtext: {
      w: "60%",
    },
    button: {
      size: "sm",
      mb: 4,
      colorScheme: "facebook",
    },
    listbutton: {
      w: "40%",
    },
    mainrow: {
      background: ModalBackground,
    },
    modalBody: {
      justifyContent: "start",
      display: "flex",
      alignItems: "start",
    },
  };

  console.log("devicesllnkndkjn", device);

  const onSaveDeleteDevice = async () => {
    let payload;
    {
      type === "device-type"
        ? (payload = { device_type_id: device?.id })
        : type === "model"
          ? (payload = { model_id: device?.id })
          : type === "customer"
            ? (payload = { organization_id: device?.id })
            : type === "device"
              ? (payload = {
                  device_assignment_id: device?.device_assignment_id,
                })
              : (payload = {
                  device_id: device?.device_id,
                  data_points_id: device?.data_point_id,
                });
    }
    onSave(payload);
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onclose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader {...styles.modalBody}>Delete {device?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Do you want to delete{" "}
              {type === "device-type"
                ? `device-type - ${device?.name}`
                : type === "model"
                  ? `model - ${device?.name}`
                  : type === "customer"
                    ? `customer - ${device?.name}`
                    : type === "device"
                      ? `device - ${device?.name}`
                      : `monitoring value - ${device?.monitoring_value}`}{" "}
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={2}
              size={"sm"}
              onClick={() => {
                onclose();
              }}>
              Cancel
            </Button>
            <Button
              size={"sm"}
              colorScheme="red"
              onClick={() => {
                onSaveDeleteDevice();
              }}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

DeleteDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};
export default DeleteDeviceModal;
