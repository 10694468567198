import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addCustomerAPI = createAsyncThunk(
  "addCustomer",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/customer`,
        {
          first_name: payload.org_contact_person_first_name,
          last_name: payload.org_contact_person_last_name,
          role_id: payload.role_id,
          email: payload.org_contact_person_email,
          phone_number: payload.org_contact_person_phone_number,
          organization_name: payload.organization_name,
          org_contact_person_first_name: payload.org_contact_person_first_name,
          org_contact_person_last_name: payload.org_contact_person_last_name,
          org_contact_person_email: payload.org_contact_person_email,
          org_contact_person_phone_number:
            payload.org_contact_person_phone_number,
          city: payload.city,
          region: payload.region,
          country: payload.country,
          postalcode: payload.postalcode,
          full_address: `${payload.city}, ${payload.region}, ${payload.country}, ${payload.postalcode}`,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const editCustomerAPI = createAsyncThunk(
  "editCustomer",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      const duplicatePayload = { ...payload };

      delete duplicatePayload.member_id;

      let response = await API.put(
        `/seller/customer/${payload?.member_id}`,
        duplicatePayload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getCustomerAPI = createAsyncThunk(
  "getCustomer",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/customer`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const addOrganizationAPI = createAsyncThunk(
  "addOrganization",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.post(
        `/seller/customers/organization`,
        {
          first_name: payload.org_contact_person_first_name,
          last_name: payload.org_contact_person_last_name,
          role_id: payload.role_id,
          email: payload.org_contact_person_email,
          phone_number: payload.org_contact_person_phone_number,
          organization_name: payload.organization_name,
          org_contact_person_first_name: payload.org_contact_person_first_name,
          org_contact_person_last_name: payload.org_contact_person_last_name,
          org_contact_person_email: payload.org_contact_person_email,
          org_contact_person_phone_number:
            payload.org_contact_person_phone_number,
          city: payload.city,
          region: payload.region,
          country: payload.country,
          postalcode: payload.postalcode,
          full_address: `${payload.city}, ${payload.region}, ${payload.country}, ${payload.postalcode}`,
        },
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getOrganizationsAPI = createAsyncThunk(
  "getOrganizations",
  async (__, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(`/seller/customers/organization`, config);
      console.log(response, "organizations?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const getCustomerTeamAPI = createAsyncThunk(
  "getCustomerTeam",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      const response = await API.get(
        `/seller/customer/${getState().customer?.activeCustomerOrg?.id}/team`,
        config,
      );
      console.log(response, "getCustomerTeamAPI?");
      return response?.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const editOrganizationAPI = createAsyncThunk(
  "editOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };
      const duplicatePayload = { ...payload };

      delete duplicatePayload.member_id;

      let response = await API.put(
        `/seller/customers/organization/${payload.organization_id}`,
        duplicatePayload,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const deleteOrganizationAPI = createAsyncThunk(
  "deleteOrganizationAPI",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState()?.auth?.token,
        },
      };

      let response = await API.delete(
        `/seller/customers/organization/${payload.organization_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);
