import { createSlice } from "@reduxjs/toolkit";
import { addMemberAPI, editMemberAPI, getMemberAPI } from "../helpers/teamAPI";

const initialState = {
  teamMemberData: [],
};

export const teamMemberSlice = createSlice({
  name: "teamMember",
  initialState,
  reducers: {
    resetStateTeam: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMemberAPI.fulfilled, (state, action) => {
        console.log("addMemberAPI", action);
        // state.types = action?.payload?.data;
      })
      .addCase(addMemberAPI.rejected, (state, action) => {
        console.log("addMemberAPI", action);
        // state.types = action?.payload?.data;
      })
      .addCase(editMemberAPI.fulfilled, (state, action) => {
        console.log("editMemberAPI", action);
      })
      .addCase(editMemberAPI.rejected, (state, action) => {
        console.log("editMemberAPI", action);
      })
      .addCase(getMemberAPI.fulfilled, (state, action) => {
        console.log("getMemberAPI", action);
        state.teamMemberData = action?.payload?.data;
      })
      .addCase(getMemberAPI.rejected, (state, action) => {
        console.log("getMemberAPI", action);
        state.teamMemberData = [];
      });
  },
});

export const { resetStateTeam } = teamMemberSlice.actions;

export default teamMemberSlice.reducer;
