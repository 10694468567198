import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteOrganizationAPI,
  editOrganizationAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import DeleteDeviceModal from "./deleteDeviceModal";
import { EditCustomerModal } from "./editCustomerModal";
import Loader from "./loader";
import SuccessMessageModal from "./successMessageModal";

function CustomerDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [loading, setLoading] = useState(false);
  const activeCustomerOrg = useSelector(
    (state) => state?.customer?.activeCustomerOrg,
  );
  const [filteredCustomerData, setFilteredCustomerData] =
    useState(activeCustomerOrg);
  const { customer } = location.state || {};
  const toast = useToast();
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleModifyClick = () => {
    setIsModalOpen(true);
  };

  const onSave = (data) => {
    setLoading(true);
    try {
      const payloadData = {
        organization_name:
          filteredCustomerData?.name === data?.organization_name
            ? ""
            : data?.organization_name,
        org_contact_person_first_name:
          filteredCustomerData?.first_name === data?.first_name
            ? ""
            : data?.first_name,
        first_name:
          filteredCustomerData?.last_name === data?.last_name
            ? ""
            : data?.last_name,
        // role_id:
        //   selectedCustomer?.role_id == data?.role_id ? "" : data?.role_id,
        org_contact_person_email:
          filteredCustomerData?.email === data?.email ? "" : data?.email,
        org_contact_person_phone_number:
          filteredCustomerData?.phone_number === data?.phone_number
            ? ""
            : data?.phone_number,
      };

      const payload = Object.fromEntries(
        Object.entries(payloadData)?.filter(([, value]) => value !== ""),
      );

      console.log("kdnvksnvsnvk", filteredCustomerData, data);
      payload.organization_id = customer?.id;
      const editMemberReq = dispatch(editOrganizationAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            // refreshCustomers();
            handleCloseModal();
            const organizaationRes = dispatch(getOrganizationsAPI());
            organizaationRes.then((result) => {
              const orgadetails = result?.payload?.data?.filter(
                (data) => data?.id == activeCustomerOrg?.id,
              );
              setFilteredCustomerData(orgadetails[0]);
            });
            setLoading(false);
            setSuccessMessage(result?.payload?.message);
            setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteOrganizationAPI(payload));
      resDeleteApi.then(async (res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage(res?.payload?.message);
          await dispatch(getOrganizationsAPI());
          handleCloseDeleteModal();
          navigate(-1);
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const styles = {
    flexContainer: {
      gap: 4,
      alignItems: "center",
      justifyContent: "space-between",
    },
    avatar: {
      size: { base: "md", md: "lg" },
    },
    deviceHeader: {
      size: { base: "sm", md: "md" },
      textTransform: "uppercase",
    },
    subheaderItems: {
      alignItems: "center",
    },
    subheaderText: {
      pl: 2,
      fontSize: { base: "12px", md: "16px" },
    },
    readingsText: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  };

  const handleClick = () => {
    navigate(`${location?.pathname}/assign-device`, { state: { customer } });
  };

  return (
    <>
      <Loader loading={loading} />
      <Card size={{ base: "sm", md: "md" }}>
        <CardBody>
          <Flex {...styles.flexContainer}>
            <Flex
              direction={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}>
              <Avatar {...styles.avatar} name={filteredCustomerData?.name} />
              <Box ml={4}>
                <Flex gap={2}>
                  <Heading {...styles.deviceHeader}>
                    {filteredCustomerData?.name}
                  </Heading>
                </Flex>
                <Flex {...styles.subheaderItems} mt={1}>
                  {filteredCustomerData?.first_name}{" "}
                  {filteredCustomerData?.last_name}
                </Flex>

                <Flex {...styles.subheaderItems} mt={1}>
                  <Flex {...styles.subheaderItems}>
                    <PhoneIcon />
                    <Text {...styles.subheaderText}>
                      {filteredCustomerData?.phone_number}
                    </Text>
                  </Flex>
                  <Flex {...styles.subheaderItems} style={{ marginLeft: 16 }}>
                    <EmailIcon />
                    <Text {...styles.subheaderText}>
                      {filteredCustomerData?.email}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
            <Box display="flex" flexDirection="column" gap={2}>
              <Button
                size={"sm"}
                width="10rem"
                colorScheme="facebook"
                onClick={handleClick}>
                Assign Device
              </Button>
              <Button
                variant="outline"
                width="10rem"
                colorScheme="black"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleModifyClick();
                }}>
                Modify
              </Button>
              <Button
                width="10rem"
                variant="outline"
                colorScheme="red"
                size="sm"
                isDisabled={filteredCustomerData?.total_owned_devices_count > 0}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteModalOpen();
                }}>
                Delete
              </Button>
            </Box>
          </Flex>
        </CardBody>
      </Card>
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={filteredCustomerData}
          onSave={onSaveDeleteModel}
          type={"customer"}
        />
      )}
      {isModalOpen && (
        <EditCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={onSave}
          customer={filteredCustomerData}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            // window.location.reload();
          }}
        />
      )}
    </>
  );
}

export default CustomerDetails;
