// import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  // Button,
  Card,
  CardBody,
  CardHeader,
  // Center,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
// import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import {
  deleteModelsAPI,
  getModelsAPI,
  updateModelsAPI,
} from "../redux/helpers/controllerAPI";
import {
  // setActiveDevice,
  setActiveDevicesForModel,
  // setActiveModel,
} from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceModelModal from "./editDeviceModelModal";
import SuccessMessageModal from "./successMessageModal";

const ControllerModels = () => {
  let url_params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const models = useSelector((state) => state?.controllers?.models);
  const devices = useSelector((state) => state?.controllers?.devices);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [singleDeviceModel, setSingleDeviceModel] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isEditModelOpen, setIsEditModelOpen] = useState(false);
  const deletionType = "model";
  const activeType = useSelector((state) => state?.controllers?.activeType);
  const toast = useToast();
  // const location = useLocation();

  // const { typeData } = location.state || {};

  // const [count, setCount] = useState();
  const [devicesData, setDevicesData] = useState();

  const modelsData = models?.filter(
    (val) => val?.device_type?.id == activeType?.id,
  );

  console.info("ControllerModels", models);
  console.info("activeTypes", activeType);

  useEffect(() => {
    let deviceCount = [];
    let device = [];
    for (var k = 0; k < modelsData?.length; k++) {
      deviceCount.push({
        id: modelsData[k]?.device_type.id,
        count: 0,
      });
    }
    for (var l = 0; l < modelsData?.length; l++) {
      device.push({
        id: modelsData[l]?.device_type.id,
        deviceData: [],
      });
    }

    for (var i = 0; i < modelsData?.length; i++) {
      for (var j = 0; j < devices?.length; j++) {
        if (modelsData[i]?.device_type.id === devices[j]?.device_type.id) {
          var index = deviceCount.findIndex(
            (obj) => obj.id === modelsData[i]?.device_type?.id,
          );
          deviceCount[index].count++;
          device[index].deviceData.push({ ...devices[j] });
        }
      }
    }
    // setCount(deviceCount);
    setDevicesData(device);
  }, []);

  const handleTypeClick = (device_data) => {
    // navigate(
    //   `/products/${url_params?.deviceTypeName?.replace(/ /g, "-")}/models/${val?.name?.replace(/ /g, "-")}/devices`,
    //   {
    //     state: { device_data },
    //   },
    // );

    console.log(url_params);
    Promise.all([
      // dispatch(setActiveModel(device_data)),
      dispatch(setActiveDevicesForModel(device_data)),
      // dispatch(setActiveDevice(val)),
    ])
      .then(() => {
        navigate(`/products/models/devices`, {
          state: { device_data },
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  // const handleEditModelOpen = (device) => {
  //   setIsEditModelOpen(true);
  //   setSingleDeviceModel(device);
  // };

  const handleEditModelCloseModal = () => {
    setIsEditModelOpen(false);
  };

  // const handleDeleteModalOpen = (device) => {
  //   setIsDeleteModalOpen(true);
  //   setSingleDeviceModel(device);
  // };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveEditModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevModel payload", payload);

    let updateModelres = dispatch(updateModelsAPI(payload));
    updateModelres.then((data) => {
      console.log("updateModelres", data);
      if (data?.payload?.success) {
        setSuccessMessage("Model Updated successfully");
        dispatch(getModelsAPI());
        setisSuccessModalOpen(true);
        handleEditModelCloseModal();
      } else {
        dispatch(getModelsAPI());
        toast({
          title: "Error",
          description: data?.payload?.message || data?.payload?.error,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    });
  };

  const onSaveDeleteModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDeleteModel payload", payload);

    let deleteTypesRes = dispatch(deleteModelsAPI(payload));
    deleteTypesRes
      .then((result) => {
        console.log(result);
        if (result?.payload?.success) {
          setSuccessMessage("Model Deleted successfully");
          dispatch(getModelsAPI());
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          dispatch(getModelsAPI());
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const styles = {
    cardTop: {},
    card: {
      w: "30%",
      cursor: "pointer",
    },
    cardHeading: {
      fontSize: 20,
      fontWeight: 600,
    },
    align: {
      alignItems: "center",
      gap: 2,
      p: 2,
    },
    item: {
      p: 2,
      fontSize: 14,
      fontWeight: 400,
    },
    itemValue: {
      p: 2,
      fontSize: 14,
      fontWeight: 600,
    },
  };
  return (
    <>
      <Box>
        {/* <Flex gap={2} pt={2} ml={3}>
          <Flex>
            <Center>
              <GoDotFill color={"green"} />
              Online
            </Center>
          </Flex>
          <Flex>
            <Center>
              <GoDotFill color={"red"} />
              Offline
            </Center>
          </Flex>
        </Flex> */}
        <Flex
          {...styles.cardTop}
          flexWrap="wrap"
          gap={4}
          align-items="flex-start">
          {models
            ?.filter((val) => val?.device_type?.id == activeType?.id)
            .map((val, index) => (
              <Card
                {...styles.card}
                key={index}
                onClick={() =>
                  handleTypeClick(devicesData[index]?.deviceData, val)
                }>
                <CardHeader py={0} bgColor="#EBEBEB" borderTopRadius="md">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Heading {...styles.cardHeading}>{val?.name}</Heading>
                    <Flex {...styles.align}>
                      <FaCheckCircle color="green" />
                      <Text>Active</Text>
                    </Flex>
                  </Flex>
                </CardHeader>
                <CardBody px={4} py={2}>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Product</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.device_type?.name}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Devices</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.number_of_devices}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Data Points</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.model_data_points?.length}
                    </Heading>
                  </Flex>
                  <Flex justifyContent="space-between">
                    <Heading {...styles.item}>Firmware</Heading>
                    <Heading {...styles.itemValue}>
                      {val?.firmware_version ? val?.firmware_version : "N/A"}
                    </Heading>
                  </Flex>
                  {/* <Flex justifyContent="space-between">
                    <Button
                      size={"xs"}
                      colorScheme="teal"
                      color="white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditModelOpen(val);
                        // console.log(customer, "customer");
                      }}>
                      Modify
                      <SettingsIcon
                        w={4}
                        h={4}
                        color="white"
                        marginLeft={2}
                        boxSize={3}
                      />
                    </Button>
                    <Button
                      size={"xs"}
                      colorScheme="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteModalOpen(val);
                      }}>
                      Delete
                      <DeleteIcon
                        w={4}
                        h={4}
                        color="white"
                        marginLeft={2}
                        boxSize={3}
                      />
                    </Button>
                  </Flex> */}
                </CardBody>
              </Card>
            ))}
        </Flex>
        {isDeleteModalOpen && (
          <DeleteDeviceModal
            isOpen={isDeleteModalOpen}
            onclose={handleCloseDeleteModal}
            // device={singleDeviceModel}
            onSave={onSaveDeleteModel}
            type={deletionType}
          />
        )}

        {isEditModelOpen && (
          <EditDeviceModelModal
            isOpen={isEditModelOpen}
            onClose={handleEditModelCloseModal}
            setIsModalOpen={setIsEditModelOpen}
            onSave={onSaveEditModel}
            // device={singleDeviceModel}
          />
        )}

        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={`${successMessage}!`}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

ControllerModels.propTypes = {
  devices: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func,
  handleModalOpen: PropTypes.func.isRequired,
};

export default ControllerModels;
