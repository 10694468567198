import React, { useEffect, useState } from "react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import Breadcrumbs from "../components/breadcrumbs";
import { AddFirmwareModal } from "../components/addFirmwareModal";
import SearchFilter from "../components/searchFilter";
import FirmwareTable from "../components/firmwareTable";
import { useDispatch, useSelector } from "react-redux";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";
import { EditFirmwareModal } from "../components/editFirmwareModal";
import Loader from "../components/loader";

export default function Firmware() {
  const dispatch = useDispatch();
  const firmwareData = useSelector((state) => state.firmware.firmwareData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState(null);
  const [loading, setLoading] = useState(true);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const handleModalOpen = (firmware) => {
    setIsEditModalOpen(true);
    setSelectedFirmware(firmware);
  };

  useEffect(() => {
    let getFirmwareRes = dispatch(getFirmwareListAPI());
    getFirmwareRes.then(() => {
      setLoading(false);
      setfilteredFirmwareData(firmwareData);
    });
  }, []);

  const [searchParams, setSearchParams] = useState({
    name: "",
  });
  const [filteredFirmwareData, setfilteredFirmwareData] =
    useState(firmwareData);

  const handleSearch = () => {
    const newfilteredFirmwareData = firmwareData?.filter((firmware) => {
      const modelMatch =
        !searchParams.name ||
        firmware?.device_model?.name
          ?.toLowerCase()
          .includes(searchParams.name.toLowerCase());

      return modelMatch;
    });

    setfilteredFirmwareData(newfilteredFirmwareData);
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredFirmwareData(firmwareData);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditCloseModal = () => {
    setIsEditModalOpen(false);
  };

  const onSaveFirmware = () => {
    let getFirmwaresRes = dispatch(getFirmwareListAPI());
    getFirmwaresRes.then(() => handleReset());
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddFirmwareModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSaveFirmware={onSaveFirmware}
        />
      </Flex>

      <Box {...styles.topBox}>
        <SearchFilter
          type="model"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <FirmwareTable
          data={filteredFirmwareData}
          handleModalOpen={handleModalOpen}
        />

        <EditFirmwareModal
          isOpen={isEditModalOpen}
          onClose={handleEditCloseModal}
          setIsModalOpen={setIsEditModalOpen}
          selectedFirmware={selectedFirmware}
          onSaveFirmware={onSaveFirmware}
        />
      </Box>
    </Box>
  );
}
