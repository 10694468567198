import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AddCustomerModal } from "../components/addCustomerModal";
import Breadcrumbs from "../components/breadcrumbs";
import CustomersTable from "../components/customersTable";
import DeleteDeviceModal from "../components/deleteDeviceModal";
import { EditCustomerModal } from "../components/editCustomerModal";
import Loader from "../components/loader";
import SearchFilter from "../components/searchFilter";
import SuccessMessageModal from "../components/successMessageModal";
import {
  addOrganizationAPI,
  deleteOrganizationAPI,
  editOrganizationAPI,
  getCustomerAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import { setActiveCustomerOrg } from "../redux/reducers/customerReducer";

export default function CustomersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen } = location.state || false;
  const [loading, setLoading] = useState(true);
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const toast = useToast();
  const organizations = useSelector((state) => state.customer.organizations);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isModifyModalOpen, setIsModifyModalOpen] = useState(false);

  useEffect(() => {
    Promise.all([dispatch(getCustomerAPI()), dispatch(getOrganizationsAPI())])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  }, []);

  const styles = {
    topBox: {
      bg: "white",
      p: 4,
      borderRadius: 8,
    },
  };

  const deletionType = "customer";
  const [searchParams, setSearchParams] = useState({
    name: "",
  });
  const [filteredCustomerData, setfilteredCustomerData] =
    useState(organizations);

  /**
   * Searching only based on Org, contact person firstname and last name
   */
  const handleSearch = () => {
    const newfilteredCustomerData = organizations?.filter((customer) => {
      const nameMatch =
        !searchParams.name ||
        customer.name.toLowerCase().includes(searchParams.name.toLowerCase()) ||
        customer.first_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase()) ||
        customer.last_name
          .toLowerCase()
          .includes(searchParams.name.toLowerCase());

      return nameMatch;
    });

    setfilteredCustomerData(newfilteredCustomerData);
  };

  const handleModifyClick = (teamMember) => {
    setSelectedCustomer(teamMember);
    setIsModifyModalOpen(true);
  };

  const handleDeleteModalOpen = (teamMember) => {
    setIsDeleteModalOpen(true);
    setSelectedCustomer(teamMember);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    dispatch(getOrganizationsAPI())
      .then((response) => {
        setfilteredCustomerData(response?.payload?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleInputChange = (field, value) => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setSearchParams({
      name: "",
    });

    setfilteredCustomerData(organizations);
  };

  const handleRowClick = (customer) => {
    dispatch(setActiveCustomerOrg(customer));
    navigate("/customers/" + customer?.name?.replace(/ /g, "-"), {
      state: { customer },
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleModifyClose = () => {
    setIsModifyModalOpen(false);
  };

  const refreshCustomers = () => {
    dispatch(getOrganizationsAPI()).then((response) => {
      setfilteredCustomerData(response?.payload?.data);
    });
    // handleReset();

    setSearchParams({
      name: "",
    });
  };

  const onSaveDeleteModel = (payload) => {
    setLoading(true);
    try {
      payload = { ...payload };
      console.log("onSaveDeleteModel payload", payload);

      const resDeleteApi = dispatch(deleteOrganizationAPI(payload));
      resDeleteApi.then((res) => {
        if (res?.payload?.success) {
          setLoading(false);
          setSuccessMessage("Customer Deleted successfully");
          dispatch(getOrganizationsAPI());
          // .then((devices) => {
          //   setFilteredDevices(devices?.payload?.data);
          // });
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          setLoading(false);
          handleCloseDeleteModal();
          toast({
            title: "Error while deleting Customer",
            description: res?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      });
    } catch (err) {
      setLoading(false);
      console.log("Error in DeleteApi", err);
    }
  };

  const onSave = async (data) => {
    setLoading(true);
    try {
      const payloadData = {
        first_name:
          selectedCustomer?.first_name == data?.first_name
            ? ""
            : data?.first_name,
        last_name:
          selectedCustomer?.last_name == data?.last_name ? "" : data?.last_name,
        // role_id:
        //   selectedCustomer?.role_id == data?.role_id ? "" : data?.role_id,
        email: selectedCustomer?.email == data?.email ? "" : data?.email,
        phone_number:
          selectedCustomer?.phone_number == data?.phone_number
            ? ""
            : data?.phone_number,
      };

      const payload = Object.fromEntries(
        Object.entries(payloadData)?.filter(([, value]) => value !== ""),
      );
      payload.organization_id = selectedCustomer?.id;
      const editMemberReq = dispatch(editOrganizationAPI(payload));
      editMemberReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success) {
            refreshCustomers();
            handleModifyClose();
            setLoading(false);
            setSuccessMessage("Organization updated successfully");
            setisSuccessModalOpen(true);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onSaveCustomer = async (payload) => {
    console.log("onSave payload", payload);
    payload.role_id = payload?.privilege;
    setLoading(true);
    try {
      const addCustomerReq = dispatch(addOrganizationAPI(payload));
      addCustomerReq
        .then((result) => {
          console.log(result);

          if (result?.payload?.success === true) {
            setSuccessMessage("Successfully added customer to your account");
            refreshCustomers();
            handleCloseModal();
            dispatch(getOrganizationsAPI()).then((result) => {
              setfilteredCustomerData(result?.payload?.data);
            });
            // toast({
            //   title: "Success",
            //   description: result?.payload?.message,
            //   status: "success",
            //   duration: 6000,
            //   isClosable: true,
            // });
            setisSuccessModalOpen(true);
            setLoading(false);
          } else {
            setLoading(false);
            toast({
              title: "Error",
              description: result?.payload?.message,
              status: "error",
              duration: 6000,
              isClosable: true,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Box>
      <Loader loading={loading} />
      <Flex>
        <Box>
          <Breadcrumbs />
        </Box>
        <Spacer />
        <AddCustomerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          setIsModalOpen={setIsModalOpen}
          onSave={onSaveCustomer}
          type={"Add Customer"}
        />
      </Flex>

      <Box {...styles.topBox}>
        <SearchFilter
          type="name"
          searchParams={searchParams}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
          handleReset={handleReset}
        />
        <CustomersTable
          customers={filteredCustomerData}
          handleRowClick={handleRowClick}
          refreshCustomers={refreshCustomers}
          handleModifyClick={handleModifyClick}
          handleDeleteModalOpen={handleDeleteModalOpen}
        />
      </Box>
      {isDeleteModalOpen && (
        <DeleteDeviceModal
          isOpen={isDeleteModalOpen}
          onclose={handleCloseDeleteModal}
          device={selectedCustomer}
          onSave={onSaveDeleteModel}
          type={deletionType}
        />
      )}

      {isModifyModalOpen && (
        <EditCustomerModal
          isOpen={isModifyModalOpen}
          onClose={handleModifyClose}
          onSave={onSave}
          customer={selectedCustomer}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={successMessage}
          onClose={() => {
            setisSuccessModalOpen(false);
            dispatch(getOrganizationsAPI());
          }}
        />
      )}
    </Box>
  );
}
