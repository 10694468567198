import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import ModelFooterButtons from "./modelFooterButtons";
import ModelsTriggerButton from "./modelsTriggerButton";

export const AddCustomerModal = ({
  isOpen,
  onClose,
  setIsModalOpen,
  onSave,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const roles = useSelector((state) => state?.auth?.roles);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z0-9\s]+$/;
  const AddCustomerValidationSchema = Yup.object().shape({
    // first_name: Yup.string()
    //   .matches(
    //     nameRegex,
    //     "First Name must contain only letters and spaces",
    //   )
    //   .required("First Name is required")
    //   .max(36, "First Name must be at most 36 characters long"),
    // last_name: Yup.string()
    //   .matches(
    //     nameRegex,
    //     "Last Name must contain only letters and spaces",
    //   )
    //   .required("Last Name is required")
    //   .max(36, "Last Name must be at most 36 characters long"),
    // email: Yup.string()
    //   .matches(emailRegex, "Enter a valid email address")
    //   .max(254, "Email must be at most 254 characters.")
    //   .required("Email is required"),
    // phone_number: Yup.string()
    //   .matches(
    //     /^[0-9]{6,14}$/,
    //     "Enter valid Phone Number (e.g., +81-1223-333)",
    //   )
    //   .required("Phone Number is required"),
    privilege: Yup.string().required("Privilege is required"),
    organization_name: Yup.string()
      .matches(
        nameRegex,
        "Organization Name must contain only letters, numbers and spaces",
      )
      .required("Organization Name is required")
      .max(36, "Organization Name must be at most 36 characters long"),
    org_contact_person_first_name: Yup.string()
      .matches(nameRegex, "First Name must contain only letters and spaces")
      .required("First Name is required")
      .max(
        36,
        "Organization Contact Person First Name must be at most 36 characters long",
      ),
    org_contact_person_last_name: Yup.string()
      .matches(nameRegex, "Last Name must contain only letters and spaces")
      .required("Last Name is required")
      .max(36, "Last Name must be at most 36 characters long"),
    org_contact_person_email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Organization Contact Person Email is required"),
    org_contact_person_phone_number: Yup.string()
      .matches(
        /^\+[0-9]{1,3}-[0-9]{4}-[0-9]{3}$/,
        "Enter valid Phone Number (e.g., +81-1223-333)",
      )
      .required("Organization Contact Person Phone Number is required"),
    city: Yup.string().required("City type is required"),
    region: Yup.string().required("Region type is required"),
    country: Yup.string().required("Country type is required"),
    postalcode: Yup.string().required("Postal Code type is required"),
  });

  const styles = {
    formItem: {
      mt: 4,
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };
  return (
    <>
      <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Customer" />

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Customer</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                email: "",
                first_name: "",
                last_name: "",
                phone_number: "",
                privilege: "1",
                organization_name: "",
                org_contact_person_first_name: "",
                org_contact_person_last_name: "",
                org_contact_person_email: "",
                org_contact_person_phone_number: "",
                full_address: "",
                city: "",
                region: "",
                country: "",
                postalcode: "",
              }}
              validationSchema={AddCustomerValidationSchema}
              onSubmit={(values) => {
                console.log(values);
                onSave(values);
              }}>
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {/* <Heading size={"md"} p={4} pl={1} textAlign={"left"}>
                    Organization Details
                  </Heading> */}
                  <Box
                    style={{
                      marginBottom: 22,
                      padding: 4,
                      marginTop: 4,
                      border: "1px dotted lightgrey",
                      borderRadius: 8,
                    }}>
                    <FormControl
                      isInvalid={
                        errors.organization_name && touched.organization_name
                      }>
                      <FormLabel>Organization Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="organization_name"
                        name="organization_name"
                        type="text"
                        placeholder="XYZ Company"
                      />
                      <FormErrorMessage>
                        {errors?.organization_name}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        errors.org_contact_person_first_name &&
                        touched.org_contact_person_first_name
                      }>
                      <FormLabel mt={4}>Contact Person First Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="org_contact_person_first_name"
                        name="org_contact_person_first_name"
                        type="text"
                        placeholder="michael"
                      />
                      <FormErrorMessage>
                        {errors?.org_contact_person_first_name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.org_contact_person_last_name &&
                        touched.org_contact_person_last_name
                      }>
                      <FormLabel mt={4}>Contact Person Last Name</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="org_contact_person_last_name"
                        name="org_contact_person_last_name"
                        type="text"
                        placeholder="jordan"
                      />
                      <FormErrorMessage>
                        {errors?.org_contact_person_last_name}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.org_contact_person_phone_number &&
                        touched.org_contact_person_phone_number
                      }>
                      <FormLabel mt={4}>Contact Person Phone Number</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="org_contact_person_phone_number"
                        name="org_contact_person_phone_number"
                        type="tel"
                        placeholder="+81-1223-333"
                      />
                      <FormErrorMessage>
                        {errors?.org_contact_person_phone_number}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      {...styles.formItem}
                      isInvalid={
                        errors.org_contact_person_email &&
                        touched.org_contact_person_email
                      }>
                      <FormLabel mt={4}>Contact Person Email</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="org_contact_person_email"
                        name="org_contact_person_email"
                        type="email"
                        placeholder="michel@example.com"
                      />
                      <FormErrorMessage>
                        {errors?.org_contact_person_email}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.city && touched.city}>
                      <FormLabel>City</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="city"
                        name="city"
                        type="text"
                        placeholder="city"
                      />
                      <FormErrorMessage>{errors?.city}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.region && touched.region}>
                      <FormLabel>Region</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="region"
                        name="region"
                        type="text"
                        placeholder="region"
                      />
                      <FormErrorMessage>{errors?.region}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.country && touched.country}>
                      <FormLabel>Country</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="country"
                        name="country"
                        type="text"
                        placeholder="country"
                      />
                      <FormErrorMessage>{errors?.country}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      {...styles.formItem}
                      isInvalid={errors.postalcode && touched.postalcode}>
                      <FormLabel>Postal Code</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="postalcode"
                        name="postalcode"
                        type="text"
                        placeholder="postal code"
                      />
                      <FormErrorMessage>{errors?.postalcode}</FormErrorMessage>
                    </FormControl>
                  </Box>

                  <FormControl
                    {...styles.formItem}
                    isInvalid={errors.privilege && touched.privilege}>
                    <FormLabel>Privilege</FormLabel>
                    <Select
                      {...styles.input}
                      placeholder="Select option"
                      name="privilege"
                      value={values.privilege}
                      defaultValue="Admin"
                      onChange={(e) => {
                        handleChange(e.target.value);
                        console.log("e.target.value", e.target.value);
                        setFieldValue("privilege", e.target.value);
                      }}>
                      {roles
                        ?.filter((role) => role?.name === "Admin")
                        ?.map((role) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                    </Select>
                    <FormErrorMessage>{errors?.privilege}</FormErrorMessage>
                  </FormControl>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

AddCustomerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};
