import { DeleteIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Field, FieldArray, Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { deviceConfigHistoryAPI } from "../redux/helpers/controllerAPI";
import {
  getDeviceAPI,
  updateDeviceDataPointsAPI,
} from "../redux/helpers/deviceAPI";
import Loader from "./loader";
import ModelFooterButtons from "./modelFooterButtons";
import SuccessMessageModal from "./successMessageModal";

const EditDeviceModal = ({
  isOpen,
  onClose,
  device,
  dataPoints,
  handleDeleteModalOpen,
}) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [customer_id, setAssignCustomer] = useState([]);
  // const customerOrgList = useSelector((state) => state.customer.organizations);
  // const [isAssignToCustomer, setIsAssignToCustomer] = useState(
  //   device?.assigned_to_customer_organization?.id,
  // );
  const dispatch = useDispatch();
  const toast = useToast();
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);

  console.log("jhbcjsbsmb", device);
  const handleSuccccesModalOpen = () => {
    setisSuccessModalOpen(true);
  };
  // useEffect(() => {
  //   dispatch(getCustomerAPI());
  // }, []);
  // const formValidationSchema = Yup.object().shape({
  //   max: Yup.number().required("Device maximum is required"),
  //   min: Yup.number().required("Device minimum is required"),
  // });

  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    gdata_point: Yup.array().of(
      Yup.object().shape({
        monitoring_value: Yup.string()
          .max(45, "Monitoring Value must be at most 45 characters.")
          .matches(
            nameRegex,
            "Monitoring Value can only contain Letters, Numbers & Spaces",
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number().required("Maximum value is required"),
      }),
    ),
  });

  console.log("svsveff", dataPoints);

  const styles = {
    formItem: {
      mt: 4,
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <Loader loading={loading} />
      {dataPoints && (
        <Modal
          blockScrollOnMount={false}
          initialFocusRef={initialRef}
          finalFocusRef={finalRef}
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior={"inside"}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> Modify Unit</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={2}>
              <Formik
                initialValues={{
                  data_point: [],
                  gdata_point:
                    (dataPoints &&
                      dataPoints?.map((dataPoint) => ({
                        monitoring_value: dataPoint?.unit?.type || "",
                        unit_id: dataPoint?.unit?.id || "",
                        min: dataPoint?.minimum || "",
                        max: dataPoint?.maximum || "",
                        data_point_id: dataPoint?.id || "",
                      }))) ||
                    [],
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  setLoading(true);
                  try {
                    console.log("values", values);
                    let data_point = [];
                    values.gdata_point.forEach((element) => {
                      data_point.push({
                        data_point_id: element.data_point_id,
                        max: element.max,
                        min: element.min,
                        monitoring_value: element.monitoring_value,
                        unit_id: element.unit_id,
                      });
                    });

                    const payload = {
                      device_name: values?.device_name,
                      device_id: device?.id,
                      data_points: data_point,
                    };

                    const updateDeviceReq = dispatch(
                      updateDeviceDataPointsAPI(payload),
                    );
                    updateDeviceReq
                      .then((data) => {
                        if (data?.payload?.success) {
                          dispatch(getDeviceAPI());
                          dispatch(
                            deviceConfigHistoryAPI({
                              device_id: device?.id,
                            }),
                          );
                          handleSuccccesModalOpen();
                          onClose();
                          setLoading(false);
                        } else {
                          setLoading(false);
                          toast({
                            title: "Error while updating Unit",
                            description:
                              data?.payload?.message || data?.payload?.error,
                            status: "error",
                            duration: 6000,
                            isClosable: true,
                          });
                        }
                      })
                      .catch((error) => {
                        setLoading(false);
                        console.error("Error fetching data:", error);
                      });
                  } catch (err) {
                    setLoading(false);
                    console.log("errror");
                  }
                }}>
                {({
                  values,
                  handleSubmit,
                  handleChange,
                  errors,
                  touched,
                  handleBlur,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {dataPoints && (
                      <FieldArray
                        name="gdata_point"
                        style={{
                          marginBottom: 22,
                          padding: 4,
                          marginTop: 6,
                          borderRadius: 8,
                        }}>
                        {(arrayHelpers) => (
                          <>
                            {values?.gdata_point?.map((ldata_point, index) => (
                              <Box
                                key={index}
                                style={{
                                  marginBottom: 22,
                                  padding: 6,
                                  marginTop: 4,
                                  borderRadius: 8,
                                }}>
                                <FormControl
                                  isInvalid={
                                    errors.gdata_point &&
                                    errors.gdata_point[index] &&
                                    touched.gdata_point &&
                                    touched.gdata_point[index]
                                  }>
                                  <FormLabel>
                                    <Flex
                                      direction={"row"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}>
                                      <div>
                                        <Text>
                                          {`Monitoring Value ${index + 1}`}
                                        </Text>
                                      </div>
                                      <div>
                                        <DeleteIcon
                                          cursor="pointer"
                                          w={8}
                                          h={8}
                                          color="red"
                                          boxSize={4}
                                          onClick={() => {
                                            handleDeleteModalOpen(ldata_point);
                                            arrayHelpers?.remove(index);
                                          }}
                                        />
                                      </div>
                                    </Flex>
                                  </FormLabel>
                                  <Input
                                    {...styles.input}
                                    mt={2}
                                    placeholder={`Monitoring Value`}
                                    name={`gdata_point.${index}.monitoring_value`}
                                    defaultValue={ldata_point?.monitoring_value}
                                    onChange={handleChange}
                                  />
                                  <FormErrorMessage>
                                    {errors.gdata_point &&
                                      errors.gdata_point[index] &&
                                      errors.gdata_point[index].name}
                                  </FormErrorMessage>
                                </FormControl>
                                <Flex
                                  {...styles.formItem}
                                  alignItems={"center"}
                                  gap={2}>
                                  <FormControl
                                    isInvalid={errors.min && touched.min}>
                                    <Field
                                      as={Input}
                                      {...styles.input}
                                      placeholder="Min pressure in Pa"
                                      type={"number"}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue={ldata_point.minimum}
                                      name={`gdata_point.${index}.min`}
                                    />
                                    <FormErrorMessage>
                                      {errors?.min_pressure}
                                    </FormErrorMessage>
                                  </FormControl>

                                  <MinusIcon />

                                  <FormControl
                                    isInvalid={
                                      errors.max_pressure &&
                                      touched.max_pressure
                                    }>
                                    <Field
                                      as={Input}
                                      {...styles.input}
                                      placeholder="Max Pressure in Pa"
                                      type="number"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue={ldata_point.maximum}
                                      name={`gdata_point.${index}.max`}
                                    />
                                  </FormControl>
                                </Flex>
                              </Box>
                            ))}
                            {/* <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  monitoring_value: "",
                                  min: "",
                                  max: "",
                                  unit_id: dataPoints?.unit?.id,
                                  data_point_id: "",
                                })
                              }
                              size={"xs"}
                              colorScheme="teal">
                              Add Attribute
                            </Button> */}
                          </>
                        )}
                      </FieldArray>
                    )}
                    {/* {dataPoints?.map((data_point) => {
                    return (
                      <Box
                        display={"none"}
                        key={data_point?.id}
                        {...styles.formItem}>
                        <FormLabel>
                          {data_point?.unit?.type}
                          <Text fontSize={10}>
                            (in {data_point?.unit?.unit} )
                          </Text>
                        </FormLabel>
                        <Center gap={2}>
                          <FormControl
                            isInvalid={
                              errors.min_pressure && touched.min_pressure
                            }>
                            <Field
                              as={Input}
                              {...styles.input}
                              placeholder="Min pressure in Pa"
                              type={"number"}
                              min={-2000}
                              defaultValue={data_point?.minimum}
                              id={data_point?.id + data_point?.minimum}
                              name={data_point?.id + data_point?.minimum}
                            />
                            <FormErrorMessage>
                              {errors?.min_pressure}
                            </FormErrorMessage>
                          </FormControl>
                          <MinusIcon />
                          <FormControl
                            isInvalid={
                              errors.max_pressure && touched.max_pressure
                            }>
                            <Field
                              as={Input}
                              {...styles.input}
                              placeholder="Max Pressure in Pa"
                              type="number"
                              max={2000}
                              defaultValue={data_point?.maximum}
                              id="max_pressure"
                              name="max_pressure"
                              // onChange={(e) =>
                              //   setFieldValue(
                              //     "max_pressure",
                              //     parseInt(e.target.value, 10),
                              //   )
                              // }
                            />
                            <FormErrorMessage>
                              {errors?.max_pressure}
                            </FormErrorMessage>
                          </FormControl>
                        </Center>
                      </Box>
                    );
                  })} */}
                    <ModelFooterButtons
                      onClose={onClose}
                      onSave={handleSubmit}
                    />
                  </form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      {isSuccessModalOpen && (
        <SuccessMessageModal
          isOpen={isSuccessModalOpen}
          message={"Device updated successfully"}
          onClose={() => {
            setisSuccessModalOpen(false);
          }}
        />
      )}
    </>
  );
};

EditDeviceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  device: PropTypes.object.isRequired,
  reloadDataForAccount: PropTypes.func,
  setisSuccessModalOpen: PropTypes.func,
  dataPoints: PropTypes.object,
  handleDeleteModalOpen: PropTypes.func,
};
export default EditDeviceModal;
