import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Branding from "../assets/images/wintergardenz-logo.webp";
import Loader from "../components/loader";
import { loginAPI, userRolesAPI } from "../redux/helpers/authAPI";
import {
  changeAuthState,
  resetState,
  setNewUserTour,
} from "../redux/reducers/authReducer";
import { getDashboardDataAPI } from "../redux/helpers/summaryAPI";
import {
  getCustomerAPI,
  getOrganizationsAPI,
} from "../redux/helpers/customerAPI";
import { getDeviceAPI } from "../redux/helpers/deviceAPI";
import { getFirmwareListAPI } from "../redux/helpers/firmwareAPI";
import { resetStateController } from "../redux/reducers/controllersReducer";
import { resetStateCustomer } from "../redux/reducers/customerReducer";
import { resetStateDevice } from "../redux/reducers/devicesReducer";
import { resetStateFirmware } from "../redux/reducers/firmwareReducer";
import { resetStateSummary } from "../redux/reducers/summaryReducer";
import { resetStateTeam } from "../redux/reducers/teamMembersReducer";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const spinner_status = useSelector((state) => state?.auth?.loader_visible);
  const loader_message = useSelector((state) => state?.auth?.loader_message);

  const resetReducers = async () => {
    return new Promise((resolve, reject) => {
      dispatch(resetState());
      dispatch(resetStateController());
      dispatch(resetStateCustomer());
      dispatch(resetStateDevice());
      dispatch(resetStateFirmware());
      dispatch(resetStateSummary());
      dispatch(resetStateTeam());
      resolve({ success: true });
      reject({ success: false });
    });
  };
  const styles = {
    background: {
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      bgGradient:
        "linear-gradient(135deg, rgb(121, 241, 164) 10%, rgb(14, 92, 173) 100%)",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 2, md: 10 },
      marginTop: "10vh",
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDirection: "column",
      mb: "2",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    input: {
      borderRadius: 6,
      size: { base: "sm", md: "md" },
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
  });
  return (
    <Flex {...styles.background}>
      <Loader loading={loading} />
      <Box
        display={"flex"}
        flexDirection={"row"}
        pt={10}
        justifyContent={"space-between"}
        alignItems={"center"}>
        <Image height={"6vh"} src={Branding} alt="Winter Gardenz"></Image>
      </Box>
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          <Heading {...styles.header}>Login</Heading>
          <Text {...styles.description}>
            Login to manage your devices, customers and team
          </Text>

          {/* Todo: Convert this to modal  */}
          <Box display={spinner_status == true ? "flex" : "none"} width={100}>
            {/* <div className="login-loader-overlay">
              <div style={{ overflow: "-moz-hidden-unscrollable" }}>
                <div className="login-loader"></div>
              </div>
            </div> */}
            <Text> {loader_message} </Text>
          </Box>

          <Box {...styles.formContainer}>
            <Formik
              initialValues={{
                email: "",
                rememberMe: false,
              }}
              validationSchema={loginValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  email: values?.email,
                  password: values?.password,
                };

                setLoading(true);
                try {
                  // dispatch(loginUser(payload));
                  resetReducers().then((res) => {
                    if (res.success) {
                      const onloginRequest = dispatch(loginAPI(payload));

                      onloginRequest
                        .then((data) => {
                          console.log("onloginRequest", data.payload);

                          if (data.payload?.success == false) {
                            setLoading(false);
                            toast({
                              title: "Incorrect credentials",
                              description: data.payload.message,
                              status: "error",
                              duration: 6000,
                              isClosable: true,
                            });
                          } else if (
                            data?.payload?.is_setup_complete === false
                          ) {
                            dispatch(changeAuthState(true));
                            dispatch(setNewUserTour(true));
                            dispatch(getDashboardDataAPI())
                              .then(() => {
                                dispatch(getOrganizationsAPI())
                                  .then(() => {
                                    dispatch(getDeviceAPI())
                                      .then(() => {
                                        dispatch(userRolesAPI())
                                          .then(() => {
                                            dispatch(getCustomerAPI())
                                              .then(() => {
                                                dispatch(getFirmwareListAPI())
                                                  .then(() => {
                                                    navigate("/onboarding");
                                                    setLoading(false);
                                                  })
                                                  .catch((err) => {
                                                    console.log(err);
                                                  });
                                              })
                                              .catch((err) => {
                                                console.log(err);
                                              });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          } else {
                            dispatch(changeAuthState(true));
                            dispatch(getDashboardDataAPI())
                              .then(() => {
                                dispatch(getOrganizationsAPI())
                                  .then(() => {
                                    dispatch(getDeviceAPI())
                                      .then(() => {
                                        dispatch(userRolesAPI())
                                          .then(() => {
                                            dispatch(getCustomerAPI())
                                              .then(() => {
                                                dispatch(getFirmwareListAPI())
                                                  .then(() => {
                                                    navigate("/dashboard");
                                                    setLoading(false);
                                                  })
                                                  .catch((err) => {
                                                    console.log(err);
                                                  });
                                              })
                                              .catch((err) => {
                                                console.log(err);
                                              });
                                          })
                                          .catch((err) => {
                                            console.log(err);
                                          });
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                  });
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                        })
                        .catch((error) => {
                          setLoading(false);
                          console.log(error);
                        });
                    }
                  });
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl isInvalid={errors?.email && touched?.email}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <FormErrorMessage>{errors?.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors?.password && touched?.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          {...styles.input}
                          id="password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{errors?.password}</FormErrorMessage>
                    </FormControl>

                    <Button {...styles.loginButton} type="submit">
                      Login
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </Box>
        </Stack>
        <Box {...styles.footerText}>
          <Link onClick={() => navigate("/forgot-password")}>
            <Text {...styles.linkColor}>Forgot Password?</Text>
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}
