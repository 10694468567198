import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../assets/images/backgroundImage.png";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAuthState,
  checkExistingUser,
  clearMessage,
  creatUser,
} from "../redux/reducers/authReducer";

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const error = useSelector((state) => state?.auth?.error);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowClick = () => setShowPassword(!showPassword);

  const styles = {
    background: {
      justifyContent: "center",
      alignItems: "center",
      h: "100vh",
      w: "100vw",
      bgImage: BackgroundImage,
      bgSize: "cover",
      bgRepeat: "no-repeat",
    },
    topContainer: {
      flexDirection: "column",
      p: { base: 4, md: 10 },
      borderRadius: 10,
      backgroundColor: "whitesmoke",
      justifyContent: "center",
      alignItems: "center",
    },
    stackItems: {
      flexDir: "column",
      mb: "2",
      width: { base: "70vw", md: "100%" },
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      size: "lg",
      fontWeight: "semibold",
    },
    description: {
      fontSize: 14,
      color: "gray",
    },
    formContainer: {
      minW: { base: "100%", md: "468px" },
    },
    formStack: {
      spacing: 4,
      align: "flex-start",
    },
    input: {
      borderRadius: 6,
      size: { base: "sm", md: "md" },
    },
    loginButton: {
      colorScheme: "twitter",
      size: { base: "sm", md: "md" },
      width: "full",
    },
    linkColor: {
      color: "#1da1f2",
    },
    footerText: {
      flexDirection: "column",
      fontSize: { base: 14, md: 16 },
      textAlign: "center",
    },
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter a valid email address")
      .max(255, "Email must be at most 255 characters.")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long")
      .max(36, "Password must be at most 36 characters long")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase letter",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase letter",
      )
      .matches(/(?=.*\d)/, "Password must contain at least one digit")
      .matches(
        /(?=.*\W)/,
        "Password must contain at least one special character",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  return (
    <Flex {...styles.background}>
      <Flex {...styles.topContainer}>
        <Stack {...styles.stackItems}>
          <Heading {...styles.header}>Sign Up</Heading>
          <Text {...styles.description}>
            Enter details to create an account
          </Text>
          <Box {...styles.formContainer}>
            <Formik
              initialValues={{
                email: "",
                rememberMe: false,
              }}
              validationSchema={loginValidationSchema}
              onSubmit={(values) => {
                const payload = {
                  email: values?.email,
                  password: values?.password,
                };
                setTimeout(() => {
                  dispatch(checkExistingUser(payload));
                }, 2000);

                if (
                  error ===
                  "An account with the provided email address already exists."
                ) {
                  toast({
                    title: "User exists.",
                    description:
                      "An account with the provided email address already exists.",
                    status: "error",
                    duration: 6000,
                    isClosable: true,
                  });
                  dispatch(clearMessage());
                } else {
                  dispatch(creatUser(payload));
                  dispatch(changeAuthState(true));
                  navigate("/dashboard");
                }
              }}>
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack {...styles.formStack}>
                    <FormControl isInvalid={errors?.email && touched?.email}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Field
                        as={Input}
                        {...styles.input}
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                      />
                      <FormErrorMessage>{errors?.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={errors?.password && touched?.password}>
                      <FormLabel htmlFor="password">Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          id="password"
                          name="password"
                          {...styles.input}
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>{errors?.password}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        errors?.confirmPassword && touched?.confirmPassword
                      }>
                      <FormLabel htmlFor="confirmPassword">
                        Confirm Password
                      </FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          id="confirmPassword"
                          name="confirmPassword"
                          {...styles.input}
                          type={showPassword ? "text" : "password"}
                          placeholder="Confirm your password"
                        />

                        <InputRightElement onClick={handleShowClick}>
                          <ViewIcon />
                        </InputRightElement>
                      </InputGroup>

                      <FormErrorMessage>
                        {errors?.confirmPassword}
                      </FormErrorMessage>
                    </FormControl>

                    <Button {...styles.loginButton} type="submit">
                      Sign Up
                    </Button>
                  </VStack>
                </form>
              )}
            </Formik>
          </Box>
        </Stack>
        <Box {...styles.footerText}>
          Already have an account?{" "}
          <Link {...styles.linkColor} onClick={() => navigate("/login")}>
            Login
          </Link>
        </Box>
      </Flex>
    </Flex>
  );
}
