import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manufacturerData: [
    {
      manufacturer: "HALTECH",
      email: "example@gmail.com",
      deviceType: "Pressure",
      phoneNumber: "8985598886",
      devieModel: "Model 1",
    },
    {
      manufacturer: "GOLBY",
      email: "example@gmail.com",
      deviceType: "Temperature",
      phoneNumber: "8985598886",
      devieModel: "Model 2",
    },
    {
      manufacturer: "GOLBY",
      email: "example@gmail.com",
      deviceType: "Temperature",
      phoneNumber: "8985598886",
      devieModel: "Model 3",
    },
    {
      manufacturer: "GOLBY",
      email: "example@gmail.com",
      deviceType: "Pressure",
      phoneNumber: "8985598886",
      devieModel: "Model 4",
    },
    {
      manufacturer: "HALTECH",
      email: "example@gmail.com",
      deviceType: "Temperature",
      phoneNumber: "8985598886",
      devieModel: "Model 5",
    },
    {
      manufacturer: "GOLBY",
      email: "example@gmail.com",
      deviceType: "Pressure",
      phoneNumber: "8985598886",
      devieModel: "Model 6",
    },
    {
      manufacturer: "HALTECH",
      email: "example@gmail.com",
      deviceType: "Temperature",
      phoneNumber: "8985598886",
      devieModel: "Model 7",
    },
    {
      manufacturer: "GOLBY",
      email: "example@gmail.com",
      deviceType: "Pressure",
      phoneNumber: "8985598886",
      devieModel: "Model 8",
    },
  ],
};

export const manufacturerSlice = createSlice({
  name: "manufacturer",
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export default manufacturerSlice.reducer;
