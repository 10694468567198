import { Box, Flex, Heading, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import SidebarMenu from "./sidebarMenu";
import { userProfileAPI, userRolesAPI } from "../redux/helpers/authAPI";
import { useDispatch, useSelector } from "react-redux";
import {
  // getModelsAPI,
  // getTypesAPI,
  getUnitsAPI,
} from "../redux/helpers/controllerAPI";
import FirstTimeUserOnboarding from "./firstTimeUserOnboarding";
import Loader from "./loader";
import Tour from "reactour";
import _debounce from "lodash/debounce";
import { setNewUserTour } from "../redux/reducers/authReducer";

export default function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const pathSegments = pathname.split("/").filter(Boolean);
  const firstTimeUser = useSelector((state) => state?.auth?.is_firstTime_user);
  const is_new_user = useSelector((state) => state?.auth?.is_new_user);
  const [isTourOpen, setIsTourOpen] = useState(is_new_user);
  const debouncedNavigate = _debounce((path) => navigate(path), 200);

  const closeTour = () => {
    setIsTourOpen(false);
    dispatch(setNewUserTour(false));
  };

  useEffect(() => {
    Promise.all([
      dispatch(userProfileAPI()),
      // dispatch(getTypesAPI()),
      // dispatch(getModelsAPI()),
      dispatch(getUnitsAPI()),
      dispatch(userRolesAPI()),
    ])
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
    // onOpen();
  }, []);

  const toursteps = [
    {
      selector: '[tour-guide="step-1"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Overview of key metrics and recent activities.
          </Heading>
          <Text {...styles.cardSubtext}>
            Summary of the product inventory and important data.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/dashboard"),
    },
    {
      selector: '[tour-guide="step-2"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Manage and view customer information.
          </Heading>
          <Text {...styles.cardSubtext}>
            Add customers, manage their accounts and assign devices.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/customers"),
    },
    {
      selector: '[tour-guide="step-3"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            View and edit product details and inventory.
          </Heading>
          <Text {...styles.cardSubtext}>
            View inventory, manage production of devices.
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/units"),
    },
    {
      selector: '[tour-guide="step-4"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>
            Collaborate with team members and assign roles.
          </Heading>
          <Text {...styles.cardSubtext}>
            Users can manage team members, assign roles and collaboration
          </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/team"),
    },
    {
      selector: '[tour-guide="step-5"]',
      content: () => (
        <Box {...styles.card}>
          <Heading {...styles.cardHeading}>User Profile.</Heading>
          <Text {...styles.cardSubtext}>This is your profile. </Text>
        </Box>
      ),
      action: () => debouncedNavigate("/profile"),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep < steps?.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const steps = [
    { title: "Welcome" },
    { title: "Add Model" },
    { title: "Invite Team" },
  ];

  const styles = {
    contentArea: {
      backgroundColor: "#ebebeb",
      width: "100%",
      height: "90vh",
      overflowY: "auto",
      padding: { base: "12px", md: "26px" },
    },

    card: {
      bg: "balck",
      borderRadius: "md",
      // boxShadow: "md",
      p: "2",
      // m: "4",
      maxW: "300px",
      textAlign: "center",
    },

    cardHeading: {
      fontFamily: "'Poppins', sans-serif",
      marginbottom: "10px",
      as: "h2",
      size: ".4em",
      mb: "2",
      color: "gray.700",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: ".05em",
      textTransform: "uppercase",
    },

    cardSubtext: {
      fontsize: "1em",
      fontFamily: "'Poppins', sans-serif",
      color: "gray.600",
    },
  };
  return (
    <div>
      <Loader loading={loading} />
      {["login", "signup", "forgot-password", "onboarding"].includes(
        pathSegments[0],
      ) ? (
        <Outlet />
      ) : (
        <Box>
          <Header />
          <Flex>
            {"onboarding".includes(pathSegments[0]) ? null : <SidebarMenu />}
            <Box {...styles.contentArea}>
              <Outlet />
              {!loading && (
                <Tour
                  steps={toursteps}
                  isOpen={isTourOpen}
                  onRequestClose={closeTour}
                />
              )}
            </Box>
          </Flex>
          {firstTimeUser && (
            <FirstTimeUserOnboarding
              isOpen={isOpen}
              onClose={onClose}
              onOpen={onOpen}
              activeStep={activeStep}
              steps={steps}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </Box>
      )}
    </div>
  );
}
