import {
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Button,
  Box,
  IconButton,
  FormErrorMessage,
  Center,
  Text,
  Flex,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { Formik, FieldArray } from "formik";
import { CloseIcon } from "@chakra-ui/icons";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ModelsTriggerButton from "./modelsTriggerButton";
import ModelFooterButtons from "./modelFooterButtons";

const AddDeviceModelModal = ({ isOpen, onClose, setIsModalOpen, onSave }) => {
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const types = useSelector((state) => state?.controllers?.types);
  const units = useSelector((state) => state?.controllers?.units);
  const nameRegex = /^[a-zA-Z0-9-\s]+$/;
  const validationSchema = Yup.object().shape({
    device_type: Yup.string().required("Type is required"),
    device_model_name: Yup.string()
      .required("Model name is required")
      .max(45, "Model name must be at most 45 characters.")
      .matches(
        nameRegex,
        "Model name can only contain Letters, Numbers, Hyphen & Spaces"
      ),
    device_attributes: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Attribute label is required")
          .max(45, "Attribute label must be at most 45 characters.")
          .matches(
            nameRegex,
            "Attribute label can only contain Letters, Numbers & Spaces"
          ),
        min: Yup.number().required("Minimum value is required"),
        max: Yup.number().required("Maximum value is required"),
        unit_id: Yup.string().required("Unit is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    onSave({
      type_id: parseInt(values.device_type),
      name: values.device_model_name,
      data_points: values.device_attributes.map((attribute) => ({
        ...attribute,
        unit_id: parseInt(attribute.unit_id),
      })),
    });
  };

  const styles = {
    input: {
      size: "sm",
      borderRadius: 6,
    },
  };

  return (
    <>
      <ModelsTriggerButton setIsModalOpen={setIsModalOpen} type="Model" />
      <Modal
        blockScrollOnMount={false}
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Model</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={2}>
            <Formik
              initialValues={{
                device_type: "",
                device_model_name: "",
                device_attributes: [{}],
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, handleChange, touched, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl
                    isInvalid={errors?.device_type && touched?.device_type}
                  >
                    <FormLabel>Product</FormLabel>
                    <Select
                      {...styles.input}
                      id="device_type"
                      ref={initialRef}
                      name="device_type"
                      placeholder="Select Product"
                      value={values.device_type}
                      onChange={handleChange}
                    >
                      {types?.map((val) => (
                        <option key={uuidv4()} value={val.id}>
                          {val.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.device_type}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={
                      errors?.device_model_name && touched?.device_model_name
                    }
                  >
                    <FormLabel>Model</FormLabel>
                    <Input
                      {...styles.input}
                      placeholder="Ex: RX-100"
                      name="device_model_name"
                      value={values.device_model_name}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>
                      {errors.device_model_name}
                    </FormErrorMessage>
                  </FormControl>
                  <Text style={{ marginTop: 10 }}>Device Data Attributes</Text>
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 12,
                      fontStyle: "normal",
                      fontWeight: 300,
                    }}
                  >
                    This encompasses both the collection of data points through
                    the device and the ability for the customer to adjust alert
                    thresholds.
                  </Text>

                  <FieldArray
                    name="device_attributes"
                    style={{
                      marginBottom: 22,
                      padding: 4,
                      marginTop: 4,
                      border: "1px dotted lightgrey",
                      borderRadius: 8,
                    }}
                  >
                    {(arrayHelpers) => (
                      <>
                        {values.device_attributes.map((_, index) => (
                          <Box
                            key={index}
                            style={{
                              marginBottom: 22,
                              padding: 4,
                              marginTop: 6,
                              border: "1px dotted lightgrey",
                              borderRadius: 8,
                            }}
                          >
                            <Flex justifyContent={"end"}>
                              <IconButton
                                size={"xs"}
                                fontSize={"10px"}
                                color={"red"}
                                icon={<CloseIcon />}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </Flex>

                            <FormControl
                              marginTop={-4}
                              isInvalid={
                                errors.device_attributes &&
                                errors.device_attributes[index] &&
                                touched.device_attributes &&
                                touched.device_attributes[index]
                              }
                            >
                              <FormLabel fontSize={"13px"}>
                                {`Attribute label ${index + 1}`}
                              </FormLabel>
                              <Input
                                {...styles.input}
                                placeholder={`Attribute label`}
                                name={`device_attributes.${index}.name`}
                                value={values.device_attributes[index].name}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  errors.device_attributes[index].name}
                              </FormErrorMessage>
                              <FormControl
                                pt={2}
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }
                              >
                                <FormLabel fontSize={"13px"}>Unit</FormLabel>
                                <Select
                                  {...styles.input}
                                  placeholder="Select Unit"
                                  name={`device_attributes.${index}.unit_id`}
                                  value={
                                    values.device_attributes[index].unit_id
                                  }
                                  onChange={handleChange}
                                >
                                  {units?.map((val) => (
                                    <option key={uuidv4()} value={val.id}>
                                      {val.name}
                                    </option>
                                  ))}
                                </Select>
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].unit_id}
                                </FormErrorMessage>
                              </FormControl>
                            </FormControl>
                            <Center pt={2}>
                              <FormControl
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }
                              >
                                <FormLabel fontSize={"13px"}>Minimum</FormLabel>
                                <Input
                                  {...styles.input}
                                  type="number"
                                  placeholder="Minimum"
                                  name={`device_attributes.${index}.min`}
                                  value={values.device_attributes[index].min}
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].min}
                                </FormErrorMessage>
                              </FormControl>
                              <Box px={2}></Box>
                              <FormControl
                                isInvalid={
                                  errors.device_attributes &&
                                  errors.device_attributes[index] &&
                                  touched.device_attributes &&
                                  touched.device_attributes[index]
                                }
                              >
                                <FormLabel fontSize={"13px"}>Maximum</FormLabel>
                                <Input
                                  {...styles.input}
                                  type="number"
                                  placeholder="Maximum"
                                  name={`device_attributes.${index}.max`}
                                  value={values.device_attributes[index].max}
                                  onChange={handleChange}
                                />
                                <FormErrorMessage>
                                  {errors.device_attributes &&
                                    errors.device_attributes[index] &&
                                    errors.device_attributes[index].max}
                                </FormErrorMessage>
                              </FormControl>
                            </Center>
                          </Box>
                        ))}
                        <Button
                          onClick={() =>
                            arrayHelpers.push({
                              name: "",
                              min: "",
                              max: "",
                              unit_id: "",
                            })
                          }
                          size={"xs"}
                          colorScheme="teal"
                        >
                          Add Attribute
                        </Button>
                      </>
                    )}
                  </FieldArray>

                  <ModelFooterButtons onClose={onClose} onSave={handleSubmit} />
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

AddDeviceModelModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default AddDeviceModelModal;
