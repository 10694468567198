import { DeleteIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  useToast,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
// import { GoDotFill } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteTypesAPI,
  getTypesAPI,
  updateTypesAPI,
} from "../redux/helpers/controllerAPI";
import { setActiveType } from "../redux/reducers/controllersReducer";
import DeleteDeviceModal from "./deleteDeviceModal";
import EditDeviceTypeModal from "./editDeviceTypeModal";
import SuccessMessageModal from "./successMessageModal";

const ControllerTypes = () => {
  const navigate = useNavigate();
  const types = useSelector((state) => state?.controllers?.types);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [singleDeviceType, setSingleDeviceType] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);
  const [isEditTypeModalOpen, setIsEditTypeModalOpen] = useState(false);
  const deletionType = "device-type";
  const dispatch = useDispatch();
  const toast = useToast();

  const handleTypeClick = (typeData) => {
    console.log("handleTypeClick", typeData);
    dispatch(setActiveType(typeData));

    // navigate(`/products/${typeData?.name}/models`, {
    //   state: { typeData },
    // });
    navigate(`/products/models`, {
      state: { typeData },
    });
  };

  const handleEditTypeCloseModal = () => {
    setIsEditTypeModalOpen(false);
  };

  const handleEditTypeModalOpen = (device) => {
    setIsEditTypeModalOpen(true);
    setSingleDeviceType(device);
  };

  const handleDeleteModalOpen = (device) => {
    setIsDeleteModalOpen(true);
    setSingleDeviceType(device);
  };

  console.log("typestypestypes", types);

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onSaveEditTypeModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDevType payload", payload);

    let editTypeRes = dispatch(updateTypesAPI(payload));
    editTypeRes.then((data) => {
      if (data.payload.success) {
        handleEditTypeCloseModal();
        setSuccessMessage("Device-Type Updated successfully");
        dispatch(getTypesAPI());
        setisSuccessModalOpen(true);
        // reloadDataForAccount();
      } else {
        dispatch(getTypesAPI());
        toast({
          title: "Error",
          description: data?.payload?.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }
    });
  };

  const onSaveDeleteModel = (payload) => {
    payload = { ...payload };
    console.log("onSaveDeleteModel payload", payload);

    let deleteTypesRes = dispatch(deleteTypesAPI(payload));
    deleteTypesRes
      .then((result) => {
        console.log(result);
        if (result?.payload?.success) {
          setSuccessMessage("Device-Type Deleted successfully");
          dispatch(getTypesAPI());
          handleCloseDeleteModal();
          setisSuccessModalOpen(true);
        } else {
          dispatch(getTypesAPI());
          toast({
            title: "Error",
            description: result?.payload?.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   dispatch(getTypesAPI());
  // }, []);

  const styles = {
    cardTop: {
      // m: ,
    },
    card: {
      w: "30%",
      cursor: "pointer",
    },
    cardHeading: {
      fontSize: 20,
      fontWeight: 600,
    },
    align: {
      alignItems: "center",
      gap: 2,
      p: 2,
    },
    item: {
      p: 2,
      fontSize: 14,
      fontWeight: 400,
    },
    itemValue: {
      p: 2,
      fontSize: 14,
      fontWeight: 600,
    },
  };
  return (
    <>
      <Box>
        {/* <Flex gap={2} pt={2} ml={3}>
          <Flex>
            <Center>
              <GoDotFill color={"green"} />
              Online
            </Center>
          </Flex>
          <Flex>
            <Center>
              <GoDotFill color={"red"} />
              Offline
            </Center>
          </Flex>
        </Flex> */}
        <Flex
          {...styles.cardTop}
          flexWrap="wrap"
          gap={4}
          align-items="flex-start">
          {types?.map((val, index) => (
            <Card
              {...styles.card}
              key={index}
              onClick={() => handleTypeClick(val)}>
              <CardHeader py={0} bgColor="#EBEBEB" borderTopRadius="md">
                <Flex alignItems="center" justifyContent="space-between">
                  <Heading {...styles.cardHeading}>{val?.name}</Heading>
                  <Flex {...styles.align}>
                    <FaCheckCircle color="green" />
                    <Text>Active</Text>
                  </Flex>
                </Flex>
              </CardHeader>
              <CardBody px={4} py={2}>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Devices</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.number_of_devices}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between">
                  <Heading {...styles.item}>Models</Heading>
                  <Heading {...styles.itemValue}>
                    {val?.number_of_models}
                  </Heading>
                </Flex>
                <Flex justifyContent="space-between">
                  <Button
                    size={"xs"}
                    colorScheme="teal"
                    color="white"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditTypeModalOpen(val);
                    }}>
                    Modify
                    <SettingsIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                  <Button
                    size={"xs"}
                    colorScheme="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteModalOpen(val);
                    }}>
                    Delete
                    <DeleteIcon
                      w={4}
                      h={4}
                      color="white"
                      marginLeft={2}
                      boxSize={3}
                    />
                  </Button>
                </Flex>
              </CardBody>
            </Card>
          ))}
        </Flex>
        {isDeleteModalOpen && (
          <DeleteDeviceModal
            isOpen={isDeleteModalOpen}
            onclose={handleCloseDeleteModal}
            device={singleDeviceType}
            onSave={onSaveDeleteModel}
            type={deletionType}
          />
        )}

        {isEditTypeModalOpen && (
          <EditDeviceTypeModal
            isOpen={isEditTypeModalOpen}
            onClose={handleEditTypeCloseModal}
            setIsModalOpen={setIsEditTypeModalOpen}
            onSave={onSaveEditTypeModel}
            device={singleDeviceType}
          />
        )}

        {isSuccessModalOpen && (
          <SuccessMessageModal
            isOpen={isSuccessModalOpen}
            message={`${successMessage}!`}
            onClose={() => {
              setisSuccessModalOpen(false);
            }}
          />
        )}
      </Box>
    </>
  );
};

ControllerTypes.propTypes = {
  devices: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func,
  handleModalOpen: PropTypes.func.isRequired,
};

export default ControllerTypes;
