import API from "../apiEndPoint";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addFirmwareAPI = createAsyncThunk(
  "addFirmware",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: getState().auth.token,
        },
      };

      const formData = new FormData();
      formData.append("name", payload.name);
      formData.append("model_id", payload.model_id);
      formData.append("code_name", payload.code_name);
      formData.append("file", payload.file);
      formData.append("is_prod", payload.is_prod);

      let response = await API.post(`/seller/firmware`, formData, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const editFirmwareAPI = createAsyncThunk(
  "editFirmware",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: getState().auth.token,
        },
      };

      const formData = new FormData();

      Object.entries(payload).forEach(([key, value]) => {
        if (value !== "" && key !== "firmware_id") {
          formData.append(key, value);
        }
      });

      let response = await API.put(
        `/seller/firmware/${payload?.firmware_id}`,
        formData,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getFirmwareOfModelAPI = createAsyncThunk(
  "getFirmwareOfModel",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(
        `/seller/firmware/by/model/${payload.model_id}`,
        config,
      );

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);

export const getFirmwareListAPI = createAsyncThunk(
  "getFirmwareList",
  async (payload, { getState }) => {
    try {
      const config = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().auth.token,
        },
      };

      let response = await API.get(`/seller/firmware`, config);

      return response?.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  },
);
