// import { Button } from "@chakra-ui/button";
import {
  Box,
  Flex,
  // Heading,
  Spacer,
  Text,
  VStack,
  StackDivider,
} from "@chakra-ui/layout";
import React from "react";
import PropTypes from "prop-types";
import { Tag } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveCustomerOrg } from "../redux/reducers/customerReducer";

const DashboardCustomerTeamMembers = ({ customer_team_members_added }) => {
  const styles = {
    alertListStack: {
      divider: <StackDivider borderColor="gray.200" />,
      spacing: 2,
      align: "stretch",
    },
    mainText: {
      size: "sm",
      textTransform: "capital",
    },
    flexContainer: {
      py: 1,
    },
    timestamp: {
      color: "#929FB1",
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickCustomer = (customer) => {
    dispatch(setActiveCustomerOrg(customer));
    navigate("/customers/" + customer?.name?.replace(/ /g, "-"), {
      state: { customer },
    });
  };

  return (
    <VStack {...styles.alertListStack}>
      {customer_team_members_added?.length > 0 ? (
        customer_team_members_added?.map((member, index) => {
          return (
            <Box
              key={index}
              onClick={() => onClickCustomer(member)}
              style={{ cursor: "pointer" }}>
              <Flex {...styles.flexContainer}>
                <Box>
                  <Text size={"md"} {...styles.mainText}>
                    {member?.first_name} {member?.last_name}
                  </Text>
                </Box>
                <Spacer />

                <Box>
                  <Tag
                    variant="outline"
                    colorSchemes={member?.role != "Admin" ? "red" : "green"}>
                    {member?.role}
                  </Tag>
                </Box>
              </Flex>
              <Box>
                <Text {...styles.timestamp} key={index}>
                  {member?.email} - {member?.phone_number}
                </Text>
              </Box>
            </Box>
          );
        })
      ) : (
        <Text {...styles.timestamp} align={"center"}>
          No Team member found
        </Text>
      )}
    </VStack>
  );
};

DashboardCustomerTeamMembers.propTypes = {
  customer_team_members_added: PropTypes.array.isRequired,
};

export default DashboardCustomerTeamMembers;
