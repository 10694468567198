import { Button } from "@chakra-ui/button";
import {
  Box,
  Flex,
  // Heading,
  Spacer,
  Text,
  VStack,
  StackDivider,
} from "@chakra-ui/layout";
import React from "react";
import { Tag, TagLabel } from "@chakra-ui/react";
import moment from "moment/moment";
import Tags from "./tags";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDevice } from "../redux/reducers/controllersReducer";
import { useNavigate } from "react-router-dom";

const DashboardDeviceList = ({ recent_devices, is_update }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const devices = useSelector((state) => state?.controllers?.devices);

  const styles = {
    alertListStack: {
      divider: <StackDivider borderColor="gray.200" />,
      spacing: 2,
      align: "stretch",
    },
    mainText: {
      size: "sm",
      textTransform: "capital",
    },
    flexContainer: {
      py: 1,
    },
    timestamp: {
      color: "#929FB1",
    },
  };

  const onClickDevice = (deviceData) => {
    const selectedDevice = devices.filter(
      (device) => device.serial_number == deviceData.serial_number,
    );
    dispatch(setActiveDevice(selectedDevice[0]));
    navigate("/units/device", { state: { selectedDevice } });
  };

  return (
    <VStack {...styles.alertListStack}>
      {recent_devices?.length > 0 ? (
        recent_devices?.map((device, index) => {
          return (
            <Box
              key={index}
              onClick={() => onClickDevice(device)}
              style={{ cursor: "pointer" }}>
              <Flex {...styles.flexContainer}>
                <Box>
                  <Text size={"sm"} {...styles.mainText}>
                    {device?.name}
                  </Text>
                </Box>
                <Spacer />
                {!is_update ? (
                  <Box>
                    {device?.is_configured ? (
                      <Tags success={true} type={"Configured"}></Tags>
                    ) : (
                      <Button size={"sm"} h={6}>
                        Configure Now
                      </Button>
                    )}
                  </Box>
                ) : (
                  <Box>
                    <Tag variant="outlined" rounded="full">
                      <TagLabel>
                        {moment(device?.modified_at).format(
                          "YYYY-MM-DD HH:mm:ss",
                        )}
                      </TagLabel>
                    </Tag>
                  </Box>
                )}
              </Flex>
              <Box>
                {device?.data_points?.map((data_point, index) => {
                  return (
                    <Text {...styles.timestamp} key={index}>
                      {data_point?.unit?.type} {data_point?.minimum}{" "}
                      {data_point?.unit?.unit} - {data_point?.maximum}
                      {data_point?.unit?.unit}
                    </Text>
                  );
                })}
              </Box>
            </Box>
          );
        })
      ) : (
        <Text {...styles.timestamp} align={"center"}>
          No device data
        </Text>
      )}
    </VStack>
  );
};

DashboardDeviceList.propTypes = {
  recent_devices: PropTypes.array.isRequired,
  is_update: PropTypes.bool.isRequired,
};

export default DashboardDeviceList;
