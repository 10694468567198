import { Box, Button, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
// import { IoMdBriefcase } from "react-icons/io";
import {
  BiLogOut,
  BiBuildings,
  BiDevices,
  BiCog,
  BiSolidUserDetail,
  BiSolidDashboard,
  // BiChip,
} from "react-icons/bi";
// import { TbRadar2 } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import { resetState } from "../redux/reducers/authReducer";

export default function SidebarMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathSegments = location?.pathname?.split("/").filter(Boolean);

  const styles = {
    topContainer: {
      height: { base: "90vh", lg: "90vh" },
      width: { base: "14vw", md: "8vw", lg: "16vw" },
      backgroundColor: " #2b3642",
      padding: { base: "12px", lg: "30px" },

      flexDirection: "column",
    },
    logoutButton: {
      size: "sm",
      variant: "outline",
      colorScheme: "whiteAlpha",
      alignItems: "center",
    },
    stack: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
      alignItems: "flex-start",
    },
    menuItem: {
      alignItems: "center",
      py: 1,
      px: 2,
      w: { base: "8vw", lg: "12vw" },
    },
    highlightedMenuItem: {
      alignItems: "center",
      borderRadius: 6,
      py: 1,
      px: 2,
      backgroundColor: "#314E89",
      w: { base: "8vw", lg: "12vw" },
    },
    sidebarIcon: {
      color: "white",
    },
    menuText: {
      color: "white",
      justifyContent: "flex-start",
      paddingLeft: "8px",
      display: { base: "none", lg: "block" },
    },
    buttonText: {
      color: "white",
      justifyContent: "flex-start",
      display: { base: "none", lg: "block" },
    },
  };

  const menuItems = [
    {
      path: "/dashboard",
      label: "Dashboard",
      icon: <BiSolidDashboard {...styles.sidebarIcon} />,
    },
    {
      path: "/customers",
      label: "Customers",
      icon: <BiBuildings {...styles.sidebarIcon} />,
    },
    {
      path: "/units",
      label: "Units",
      icon: <BiDevices {...styles.sidebarIcon} />,
    },
    // {
    //   path: "/firmware",
    //   label: "Firmware",
    //   icon: <BiChip {...styles.sidebarIcon} />,
    // },
    {
      path: "/team",
      label: "Team",
      icon: <BiSolidUserDetail {...styles.sidebarIcon} />,
    },
    {
      path: "/profile",
      label: "Profile",
      icon: <BiCog {...styles.sidebarIcon} />,
    },

    // {
    //   path: "/vendors",
    //   label: "Vendors*",
    //   icon: <MdStoreMallDirectory {...styles.sidebarIcon} />,
    // },
    // {
    //   path: "/requests",
    //   label: "Requests*",
    //   icon: <MdThumbsUpDown {...styles.sidebarIcon} />,
    // },
  ];

  return (
    <Flex {...styles.topContainer}>
      <Box {...styles.stack}>
        {menuItems.map((item, index) => (
          <Flex
            key={item.path || index}
            tour-guide={`step-${index + 1}`}
            {...(item.path === `/${pathSegments[0]}`
              ? styles.highlightedMenuItem
              : styles.menuItem)}
            onClick={() => navigate(item.path)}>
            {item.icon}
            <Box as="button" {...styles.menuText}>
              {item.label}
            </Box>
          </Flex>
        ))}
      </Box>
      <Spacer />
      <Button
        {...styles.logoutButton}
        rightIcon={<BiLogOut color="white" aria-label="Logout" />}
        onClick={() => {
          navigate("/");
          dispatch(resetState());
        }}>
        <Box {...styles.buttonText}>Logout</Box>
      </Button>
      <Box mt={4}>
        <Text fontSize={10} colorScheme="white" color={"white"}>
          {" "}
          DevCord 1.0{" "}
        </Text>
        <Text fontSize={8} colorScheme="white" color={"white"}>
          {" "}
          Last Updated : 24-03-2024
        </Text>
      </Box>
    </Flex>
  );
}
